/**
 *
 * The Routing of whole App will manage from here.
 *
 */

import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import LayoutContainer from '../Containers/LayoutContainer';
import Dashboard from '../Views/Dashboard';
import Overview from '../Views/Overview';
import WebsiteOverview from '../Views/WebsiteOverview';
import Login from '../Views/Login';
import NotFound from '../Views/NotFound';
import Report from '../Views/Report';
import Setting from '../Views/Setting';
import Register from '../Views/Register';
import Security from '../Views/Security';
import ForgetPassword from '../Views/ForgetPassword';
import AddWebsite from '../Views/AddWebsite';

const AppRoutes: FC = () => {
  const Routes = useRoutes([
    {
      path: '/login/',
      element: <Login />,
    },
    {
      path: '/register/',
      element: <Register />,
    },
    {
      path: '/forgot-password/',
      element: <ForgetPassword />,
    },
    {
      path: '/',
      element: <LayoutContainer />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
      ],
    },
    {
      path: '/sites/',
      element: <LayoutContainer />,
      children: [
        {
          index: true,
          element: <Overview />,
        },
        {
          path: '/sites/:website_url/',
          // element: <LayoutContainer />,
          children: [
            {
              index: true,
              element: <WebsiteOverview />,
            },
          ],
        },
      ],
    },
    {
      path: '/reports/',
      element: <LayoutContainer />,
      children: [
        {
          index: true,
          element: <Report />,
        }
      ],
    },
    {
      path: '/settings/',
      element: <LayoutContainer />,
      children: [
        {
          index: true,
          element: <Setting />,
        }
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: '/add/',
      element: <LayoutContainer />,
      children: [
        {
          index: true,
          element: <AddWebsite />,
        }
      ],
    },
    {
      path: '/security/',
      element: <LayoutContainer />,
      children: [
        {
          index: true,
          element: <Security />,
        }
      ],
    },
  ]);
  return Routes;
};

export default AppRoutes;
