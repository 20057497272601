/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box } from "@mui/material";
import "./style.css";
import React from "react";
import loader from "../../../Assets/Images/loaderImage.png";
import Logo from "../../../Assets/Images/logo.svg";
import { LoaderWrapper } from "./styled";

const Loader = () => {
  return (
    <Box>
      <LoaderWrapper>
        <img alt="" src={Logo} />
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle
              className="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#70c542"
              strokeWidth="2"
            />
          </svg>
        </Box>
      </LoaderWrapper>
    </Box>
  );
};

export default Loader;
