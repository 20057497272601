/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box } from '@mui/material';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PackageSettingsTab, { SaveGeneralSettingsHandler } from '../PackageSettingsTab';
import DebugSiteTab from '../DebugSite';
import { ISite } from '../../../Views/WebsiteOverview';
import SiteConnection from '../SiteConnection';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface PropTypes {
  handleSaveGeneralSettings: SaveGeneralSettingsHandler;
  site: ISite;
}

const SettingsTab = ({ handleSaveGeneralSettings, site }: PropTypes) => {
  const [value1, setValue1] = React.useState(0);

  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };

  return (
    <Box sx={{
        display: 'flex',
        '@media (max-width: 1199px)': {
            display: 'block'
          },
      }}>
        <Box sx={{
          minWidth: '240px',
          minHeight: 'calc(100vh - 262px)',
          borderRight: '1px solid #AFBDFA',
          paddingLeft: '40px',
          paddingTop: '20px',
        //   position: 'sticky',
        //   top: '0px',
          '@media (max-width: 1199px)': {
            minHeight: 'auto',
            paddingLeft: '20px',
            paddingBottom: '20px',
            borderBottom: '1px solid #AFBDFA',
          },
        }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value1}
            onChange={handleChange1}
            aria-label="Vertical tabs example"
            sx={{
              position: 'sticky',
              top: '181px',
              '& .MuiTabs-indicator': {
                right: 'auto',
                left: 0,
              },
              '& button': {
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'capitalize',
                minHeight: 'auto',
                textAlign: 'left !important',
                minWidth: 'auto',
                justifyContent: 'flex-end !important',
                alignItems: 'flex-start !important',
                borderLeft: '2px solid #fff',
                padding: '6px 0 6px 20px',
              },
            }}
          >
            <Tab label="General settings" {...a11yProps(0)} />
            <Tab label="Site connection" {...a11yProps(1)} />
            {/* <Tab label="Debug Connection" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <Box sx={{
          width: '100%',
        }}>
          <TabPanel value={value1} index={0}>
            <PackageSettingsTab handleSaveGeneralSettings={handleSaveGeneralSettings} site={site} />
          </TabPanel>
          <TabPanel value={value1} index={1}>
            <SiteConnection site={site}/>
          </TabPanel>
          {/* <TabPanel value={value1} index={2}>
            <DebugSiteTab site={site}/>
          </TabPanel> */}
        </Box>
      </Box>
  );
};

export default SettingsTab;
