/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, MenuItem, Select, Typography } from '@mui/material';
import * as React from 'react';
import Wpicon from '../../../Assets/Images/wp-icon.svg'
import { UpdatePluginsHandler } from '../OverviewTab';
import { OpenUpdate } from '../OverviewTab';
import Info from '../../../Assets/Images/info-icon.svg'

import { useDeletePluginMutation, useGetPluginsMutation, useScheduleWPUpdateMutation, useTogglePluginMutation, useUpdatePluginsMutation, useUpdateWordpressMutation } from '../../../Features/siteSlice';
import Swal from 'sweetalert2';
import { WordpressUpdateAvailableFn } from '../WordpressTab';
import SyncIcon from '@mui/icons-material/Sync';
import Tooltip from '@mui/material/Tooltip';

import { AntSwitch } from './styles';
import InstallPlugin from './InstallPlugin';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export interface PluginData {
    active: boolean;
    exclude: boolean;
    last_check: string;
    last_update: null | string;
    name: string;
    path: string;
    site_base_url: string;
    status: string;
    update_available: boolean;
    version: string;
}

export type TogglePluginHandler = (plugin_name: string, activation: boolean) => Promise<void>;
export type DeletePluginHandler = (plugin_path: string, plugin_name: string) => Promise<void>;

interface PropTypes {
    open_updates: OpenUpdate[];
    plugins: PluginData[];
    handleTogglePlugin: TogglePluginHandler;
    handleUpdatePlugins: UpdatePluginsHandler;
    handleDeletePlugin: DeletePluginHandler;
    wordpressVersion: string;
    wordpressUpdateAvailable: WordpressUpdateAvailableFn;
    website_url: string;
}

const getActionType = (action: string): string => {
    switch (action) {
        case 'updateAll':
            return 'Update';
        case 'deactivateAll':
            return 'Deactivate';
        case 'deleteAll':
            return 'Delete';
        default:
            return 'Update'; // Default to 'Update' in case of any unexpected action
    }
};

const isMajorUpdate = (oldVersion: string, newVersion: string): boolean => {
    let oldVersionParts = oldVersion.split('.').map(Number);
    let newVersionParts = newVersion.split('.').map(Number);

    return newVersionParts[0] > oldVersionParts[0] ||  // Major version change
        newVersionParts[1] > oldVersionParts[1];    // Minor version change
}

const CoreTab = ({
    open_updates,
    plugins,
    handleTogglePlugin,
    handleUpdatePlugins,
    handleDeletePlugin,
    wordpressVersion,
    wordpressUpdateAvailable,
    website_url
}: PropTypes) => {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const [selectedSchedule, setSelectedSchedule] = React.useState(0);
    const [checkedPlugins, setCheckedPlugins] = React.useState<PluginData[]>([]);
    const [selectedAction, setSelectedAction] = React.useState('updateAll');

    const [updateWordpress] = useUpdateWordpressMutation();
    const [scheduleWPUpdate] = useScheduleWPUpdateMutation();
    const [updatePlugins] = useUpdatePluginsMutation();
    const [togglePlugin] = useTogglePluginMutation();
    const [deletePlugin] = useDeletePluginMutation();
    const [getPlugins] = useGetPluginsMutation();

    let wpUpdateAvailable = open_updates.some(update => update.name === "WordPress");
    let wpMajorUpdateAvailable = open_updates.some(update =>
        update.name === "WordPress" &&
        isMajorUpdate(wordpressVersion, update.new_version)
    );

    const handleCheckboxChange = (plugin: PluginData) => {
        if (checkedPlugins.some((p) => p.name === plugin.name)) {
            setCheckedPlugins((prevCheckedPlugins) =>
                prevCheckedPlugins.filter((p) => p.name !== plugin.name)
            );
        } else {
            setCheckedPlugins((prevCheckedPlugins) => [...prevCheckedPlugins, plugin]);
        }
    };

    const handleGetPlugins = async (website_url: string) => {
        try {
            await getPlugins({ website_url });
            Swal.fire({
                icon: 'success',
                confirmButtonColor: '#325AE7',
                title: 'Plugins Retrieved Successfully',
                text: `It might take 5 minutes for the plugins to be retrieved. Please revisit this page later.`
            })
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Plugins Retrieval Failed.',
                text: `We could not retrieve the plugins at this moment. Please try again later.`
            })
        }
    }

    const handleUpdateWordpress = async (website_url: string) => {
        try {
            await updateWordpress(website_url);
            Swal.fire({
                icon: 'success',
                confirmButtonColor: '#325AE7',
                title: 'Wordpress Updated Successfully',
                text: `Wordpress on this site is up-to-date with the latest version.`
            })
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Wordpress Updated Failed.',
                text: `Wordpress update on this site was not updated. Please try again later.`
            })
        }
    }

    const handleScheduleWordpressUpdate = async (website_url: string, delay: number) => {
        try {
            await scheduleWPUpdate({ website_url, delay });
            Swal.fire({
                icon: 'success',
                confirmButtonColor: '#325AE7',
                title: 'Wordpress Update Scheduled Successfully',
                text: `Wordpress on this site has been scheduled for update in the next ${delay} days.`
            })
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Wordpress Scheduled Update Failed.',
                text: `Scheduling an update for Wordpress on this site was not successful. Please try again later.`
            })
        }
    }

    // Function to handle the click of the "Apply" button
    const handleApplyClick = () => {
        const action = getActionType(selectedAction);
        Swal.fire({
            title: `${action} Selected Plugins?`,
            showCancelButton: true,
            confirmButtonText: `Yes, ${action} Now!`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    switch (selectedAction) {
                        case 'updateAll':
                            for (const plugin of checkedPlugins) {
                                const { site_base_url, name } = plugin;
                                await updatePlugins({ website_url: site_base_url, plugins: name })
                            }
                            break;
                        case 'deactivateAll':
                            for (const plugin of checkedPlugins) {
                                const { site_base_url, name, active } = plugin;
                                const pluginToggleData = {
                                    plugin_name: name,
                                    activation: !active
                                }
                                await togglePlugin({ website_url: site_base_url, pluginToggleData })
                            }
                            break;
                        case 'deleteAll':
                            for (const plugin of checkedPlugins) {
                                const { site_base_url, name } = plugin;
                                await deletePlugin({ website_url: site_base_url, plugin_name: name })
                            }
                            break;
                        default:
                            for (const plugin of checkedPlugins) {
                                const { site_base_url, name } = plugin;
                                await updatePlugins({ website_url: site_base_url, plugins: name })
                            }
                            break;
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: `Unable to ${action}`,
                        text: `Failed to ${action} selected plugins. Please try again later.`
                    })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    confirmButtonColor: '#325AE7',
                    title: `${action} Successful`,
                    text: `${action} of selected plugins completed successfully!`
                })
            }
        })
    };

    return (
        <Box className='core-tab' sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Box sx={{
                width: '100%',
            }}>
                <Typography variant='h6' sx={{
                    fontSize: '18px',
                    marginBottom: '20px',
                }}>
                    Core
                </Typography>
                <Box sx={{
                    borderRadius: '10px',
                    border: '1px solid #AFBDFA',
                    background: '#fff',
                    '& > div + div': {
                        borderTop: '1px solid #AFBDFA',
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '16px 18px',
                        justifyContent: 'space-between',
                        '@media (max-width: 767px)': {
                            display: 'block',
                        }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: '400px',
                            maxWidth: '400px',
                            '& img': {
                                width: '40px',
                            }
                        }}>
                            <img src={Wpicon} />
                            <Box sx={{
                                marginLeft: '12px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Typography variant='h5'
                                    sx={{
                                        fontSize: '16px',
                                    }}
                                >
                                    WordPress Core
                                </Typography>
                                <Typography sx={{
                                    marginLeft: '10px',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'normal',
                                }}>
                                    {wordpressVersion}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography className="green-status" sx={{
                            position: 'relative',
                            padding: '0 0px 0 20px',
                            margin: '0 30px 0 30px',
                            maxWidth: '135px',
                            minWidth: '135px',
                            '@media (max-width: 767px)': {
                                margin: '15px 0px',
                            },
                        }}>
                            {wpUpdateAvailable ? 'Update available' : 'Latest Version'}
                        </Typography>
                        <Typography sx={{
                            marginRight: '10px',
                            maxWidth: '370px',
                            minWidth: '370px',
                            marginLeft: 'auto',
                            '@media (max-width: 767px)': {
                                margin: '0 0 15px 0px',
                            },
                        }}>
                            {
                                wpUpdateAvailable
                                    ? <span>A <b>{wpMajorUpdateAvailable ? 'major' : 'minor'}</b> update available for WordPress.</span>
                                    : <span>WordPress is up-to-date with the <b>latest</b> version.</span>
                            }

                        </Typography>
                        {
                            wpUpdateAvailable &&
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    defaultValue={selectedSchedule}
                                    label="Schedule Update"
                                    onChange={(event) => setSelectedSchedule(Number(event.target.value))}
                                >
                                    <MenuItem value={0}>Schedule Update</MenuItem>
                                    <MenuItem value={7}>Schedule for 1 week</MenuItem>
                                    <MenuItem value={14}>Schedule for 2 weeks</MenuItem>
                                    <MenuItem value={21}>Schedule for 3 weeks</MenuItem>
                                </Select>
                                <Button
                                    sx={{
                                        backgroundColor: '#325AE7',
                                        borderRadius: '7px',
                                        border: '1px solid #325AE7',
                                        boxShadow: 'none',
                                        padding: '5px 14px',
                                        marginLeft: '12px',
                                        fontSize: '14px',
                                        textTransform: 'inherit',
                                        whiteSpace: 'nowrap',
                                        minWidth: '98px',
                                        minHeight: 'inherit',
                                        '&:hover': {
                                            boxShadow: 'none',
                                            backgroundColor: '#325AE7',
                                        },
                                    }}
                                    onClick={() => {
                                        if (selectedSchedule === 0) {
                                            handleUpdateWordpress(website_url);
                                        } else {
                                            handleScheduleWordpressUpdate(website_url, selectedSchedule);
                                        }
                                    }}
                                >
                                    {selectedSchedule === 0 ? 'Update Now' : 'Schedule Update'}
                                </Button>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                marginTop: '40px',
                width: '100%',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                    justifyContent: 'space-between',
                    '@media (max-width: 767px)': {
                        display: 'block',
                    },
                }}>
                    <Typography variant='h6' sx={{
                        fontSize: '18px',
                        marginBottom: '0',
                        '@media (max-width: 767px)': {
                            marginBottom: '10px',
                        },
                    }}>
                        Plugins
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            '& > button': {
                                padding: '10px 16px',
                            }
                        }}>
                            <Select
                                defaultValue={selectedAction}
                                label="Plugin Action"
                                onChange={(event) => setSelectedAction(event.target.value)}
                            >
                                <MenuItem value={'updateAll'}>Update All</MenuItem>
                                <MenuItem value={'deactivateAll'}>Deactivate All</MenuItem>
                                <MenuItem value={'deleteAll'}>Delete All</MenuItem>
                            </Select>
                        </Box>
                        <Button
                            sx={{
                                backgroundColor: '#ffffff',
                                borderRadius: '7px',
                                border: '1px solid #AFBDFA',
                                boxShadow: 'none',
                                padding: '7px 19px',
                                color: '#1B1D29',
                                marginLeft: '15px',
                                textTransform: 'inherit',
                                '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: '#325AE7',
                                    color: '#ffffff',
                                },
                            }}
                            onClick={handleApplyClick}
                        >
                            Apply
                        </Button>
                        <Tooltip title="Refresh Plugins">

                            <Button
                                sx={{
                                    backgroundColor: '#ffffff',
                                    borderRadius: '7px',
                                    border: '1px solid #AFBDFA',
                                    marginLeft: '15px',
                                    boxShadow: 'none',
                                    padding: '7px 19px',
                                    color: '#1B1D29',
                                    textTransform: 'inherit',
                                    '&:hover': {
                                        boxShadow: 'none',
                                        backgroundColor: '#325AE7',
                                        color: '#ffffff',
                                    },
                                }}
                                onClick={() => handleGetPlugins(website_url)}

                            >
                                <SyncIcon />
                            </Button>
                        </Tooltip>

                        <Button onClick={handleOpen} sx={{
                            backgroundColor: '#325AE7',
                            borderRadius: '7px',
                            border: '1px solid #325AE7',
                            boxShadow: 'none',
                            padding: '7px 19px',
                            marginLeft: '15px',
                            textTransform: 'inherit',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#325AE7',
                            },
                        }}>
                            Add new plugin
                        </Button>
                        <InstallPlugin open={open} handleClose={handleClose} website_url={website_url} />
                    </Box>
                </Box>
                <Box sx={{
                    borderRadius: '10px',
                    border: '1px solid #AFBDFA',
                    background: '#fff',
                    '& > div + div': {
                        borderTop: '1px solid #AFBDFA',
                    }
                }}>
                    {
                        plugins?.length > 0
                            ? plugins.map((plugin, index) => (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '16px 18px',
                                    justifyContent: 'space-between',
                                    '@media (max-width: 767px)': {
                                        display: 'block',
                                    }
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: '400px',
                                        maxWidth: '400px',
                                    }}>
                                        <Checkbox
                                            checked={checkedPlugins.some((p) => p.name === plugin.name)}
                                            onChange={() => handleCheckboxChange(plugin)}
                                            {...label}
                                            sx={{ padding: 0, marginRight: '3px' }}
                                        />
                                        <Box sx={{
                                            marginLeft: '12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            <Typography variant='h5'
                                                sx={{
                                                    fontSize: '16px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                {plugin.name}
                                            </Typography>
                                            <Typography sx={{
                                                fontWeight: 'normal',
                                            }}>
                                                {plugin.version}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography
                                        className={plugin.update_available ? 'blue-status' : 'green-status'}
                                        sx={{
                                            position: 'relative',
                                            padding: '0 0px 0 20px',
                                            margin: '0 30px 0 30px',
                                            maxWidth: '135px',
                                            minWidth: '135px',
                                            '@media (max-width: 767px)': {
                                                margin: '15px 0px',
                                            },
                                        }}
                                    >
                                        {plugin.update_available ? 'Update available' : 'Up to date'}
                                    </Typography>

                                    <Box sx={{
                                        marginRight: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: 'auto',
                                        '@media (max-width: 767px)': {
                                            margin: '15px 0px',
                                        },
                                    }}>
                                        <Typography variant='h6' sx={{ marginRight: '10px', }}>Status
                                            <Tooltip title="Activate or deactivate plugin">
                                                <img src={Info} alt="Info" style={{ marginLeft: '5px' }} />
                                            </Tooltip>
                                        </Typography>
                                        <AntSwitch
                                            defaultChecked={plugin.active}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                            onChange={(event) => handleTogglePlugin(plugin.name, event.target.checked)}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '210px', justifyContent: 'flex-end', }}>
                                        <Button
                                            disabled={!plugin.update_available}
                                            sx={{
                                                backgroundColor: '#ffffff',
                                                borderRadius: '7px',
                                                border: '1px solid #F4F5FE',
                                                boxShadow: 'none',
                                                padding: '3px 14px',
                                                fontSize: '14px',
                                                textTransform: 'inherit',
                                                whiteSpace: 'nowrap',
                                                minWidth: 'auto',
                                                color: '#1B1D29',
                                                minHeight: 'inherit',
                                                '&:hover': {
                                                    boxShadow: 'none',
                                                    borderColor: '#325AE7',
                                                    color: '#fff',
                                                    backgroundColor: '#325AE7',
                                                },
                                            }}
                                            onClick={() => handleUpdatePlugins(plugin.name)}
                                        >
                                            {plugin.update_available ? 'Update now' : 'No update'}
                                        </Button>
                                        <Button
                                            sx={{
                                                backgroundColor: '#FFBEC5',
                                                borderRadius: '7px',
                                                border: '1px solid #FFBEC5',
                                                boxShadow: 'none',
                                                padding: '3px 14px',
                                                marginLeft: '12px',
                                                fontSize: '14px',
                                                textTransform: 'inherit',
                                                whiteSpace: 'nowrap',
                                                minWidth: 'auto',
                                                color: '#E23A45',
                                                minHeight: 'inherit',
                                                '&:hover': {
                                                    boxShadow: 'none',
                                                    borderColor: '#E23A45',
                                                    color: '#fff',
                                                    backgroundColor: '#E23A45',
                                                },
                                            }}
                                            onClick={() => {
                                                console.log(`Deleting plugin with path: ${plugin.path} and name: ${plugin.name}`);
                                                handleDeletePlugin(plugin.path, plugin.name);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </Box>
                            ))
                            : <Box>
                                <Typography variant="h6">No plugins available</Typography>
                            </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default CoreTab;