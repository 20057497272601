import React from 'react';
import { Box, Button, Toolbar, Typography } from '@mui/material';
// import DashBoardIcon from '@mui/icons-material/HomeRounded';
import Overview from '../../Assets/Icons/Overview.js';
import Report from '../../Assets/Icons/Report.js';
import Security from '../../Assets/Icons/Security.js';
import Analytics from '../../Assets/Icons/Analytics.js';
import Add from '../../Assets/Icons/Add.js';
import Setting from '../../Assets/Icons/Setting.js';
import Websites from '../../Assets/Icons/Websites.js';
import Profile from '../../Assets/Images/Profile.png'
import LogoutIcon from '@mui/icons-material/Logout';
import { MenuItemWrapper, MenuWrapper } from './styled';
import { NavLink, useNavigate } from 'react-router-dom';
interface ISidePanelProps {
  handleClick?: Function;
}

const SidePanelComponent = ({ handleClick }: ISidePanelProps) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("wpAuthenticatedUser");
    navigate('/login');
  }
  /*----------------Initializations-----------------------*/

  // menus array

  const menus = [
    {
      path: '/',
      name: 'Dashboard',
      icon: <Overview />,
    },
    {
      path: '/sites/',
      name: 'Overview',
      icon: <Websites />,
    },
    {
      path: '/reports/',
      name: 'Report',
      icon: <Report />,
    },
    {
      path: '/security/',
      name: 'Security',
      icon: <Security />,
    },
    // {
    //   path: '/analytics',
    //   name: 'Analytics',
    //   icon: <Analytics />,
    // },
    {
      path: '/settings/',
      name: 'Settings',
      icon: <Setting />,
    },
  ];
  const menus2 = [
    {
      path: '/add/',
      name: 'Add a Website',
      icon: <Add />,
    },
  ];

  /*----------------Initializations-----------------------*/

  return (
    <MenuWrapper className='menu-close' sx={{
      // '&.menu-close': {
      //   '& .menu-box a': {
      //     width: 'auto',
      //   }
      // },
      paddingTop: '125px',
      backgroundColor: '#1B1D29',
      height: 'auto',
      minHeight: '100vh',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <Box className='menu-box' sx={{
        '& a': {
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '5px',
          '& div': {
            lineHeight: 0
          }
        },
        '& a.active, a:hover': {
          backgroundColor: '#325AE7'
        },
        '& a + a': {
          marginTop: '10px'
        }
      }}>
        {menus.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            onClick={() => handleClick && handleClick()}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <MenuItemWrapper sx={{
              // display: 'inline-block',
              // height: '100%',
              // width: '100%',
            }}>
              <Box sx={{
                // display: 'inline-block',
                // height: '100%',
                // width: '100%',
              }}>
                {item.icon}
                {/* {item.name} */}
              </Box>
              {/* <Typography fontWeight={700}>{item.name}</Typography> */}
            </MenuItemWrapper>
          </NavLink>
        ))}
      </Box>
      <Box sx={{
        paddingBottom: '40px'
      }}>
        <Box sx={{
          '& a': {
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '5px',
            '& div': {
              lineHeight: 0
            }
          },
          '& a.active, a:hover': {
            backgroundColor: '#325AE7'
          },
          '& a + a': {
            marginTop: '10px'
          }
        }}>
          {menus2.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              onClick={() => handleClick && handleClick()}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <MenuItemWrapper>
                <Box>{item.icon}</Box>
                {/* <Typography fontWeight={700}>{item.name}</Typography> */}
              </MenuItemWrapper>
            </NavLink>
          ))}
        </Box>
        <Box sx={{
          marginTop: '20px',
          '& a': {
            display: 'inline-block',
            height: '46px',
            width: '46px',
            borderRadius: '100%',
            border: '2px solid #325AE7',
            padding: '2px',
            '& img': {
              width: '100%',
              height: '100%',
              border: '1px solid #fff',
              borderRadius: '100%',
            },
          },
          '& a.active': {
            '& img': {
              border: '1px solid #325AE7',
            },
          }
        }}>
          {/* <NavLink
            to='/profile'
            onClick={() => handleClick && handleClick()}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
              <img src={Profile} />
          </NavLink> */}
          <Button 
            sx={{
              background: 'transparent',
              padding: 0,
              minWidth: 'auto',
              '&:hover': {
                background: 'transparent',
              }
            }}
            onClick={() => handleLogout()}
          >
            <LogoutIcon />
          </Button>
        </Box>
      </Box>
    </MenuWrapper>
  );
};

export default SidePanelComponent;
