/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LatestScanTab, { LatestScan } from '../LatestScanTab';
import BlockIpsTab, { HandleWhitelistIpHandler, WhitelistedIPs } from '../BlockIpsTab';
import AuditTrailsTab from '../AuditTrailsTab';
import { useAddWhitelistIpMutation } from '../../../Features/siteSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface PropTypes {
  base_url: string;
  whitelisted_ips: WhitelistedIPs[];
  handleWhitelistIp: HandleWhitelistIpHandler;
  latest_scan: LatestScan;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const SecurityAndActivityTab = ({base_url, whitelisted_ips, handleWhitelistIp, latest_scan}: PropTypes) => {
  const [value1, setValue1] = React.useState(0);

  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };

  return (
    <Box sx={{
      display: 'flex',
      '@media (max-width: 1199px)': {
        display: 'block'
      },
    }}>
      <Box sx={{
        minWidth: '240px',
        minHeight: 'calc(100vh - 262px)',
        borderRight: '1px solid #CCD6FF',
        paddingLeft: '40px',
        paddingTop: '20px',
        //   position: 'sticky',
        //   top: '0px',
        '@media (max-width: 1199px)': {
          minHeight: 'auto',
          paddingLeft: '20px',
          paddingBottom: '20px',
          borderBottom: '1px solid #CCD6FF',
        },
      }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value1}
          onChange={handleChange1}
          aria-label="Vertical tabs example"
          sx={{
            position: 'sticky',
              top: '181px',
            '& .MuiTabs-indicator': {
              right: 'auto',
              left: 0,
            },
            '& button': {
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'capitalize',
              minHeight: 'auto',
              textAlign: 'left !important',
              minWidth: 'auto',
              justifyContent: 'flex-end !important',
              alignItems: 'flex-start !important',
              borderLeft: '2px solid #fff',
              padding: '6px 0 6px 20px',
            },
          }}
        >
          <Tab label="Latest Scan" {...a11yProps(0)} />
          <Tab label="Audit Trails" {...a11yProps(1)} />
          {/* <Tab label="Block IPs" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <Box sx={{
        width: '100%',
      }}>
        <TabPanel value={value1} index={0}>
          <LatestScanTab base_url={base_url} latest_scan={latest_scan} />
        </TabPanel>
        <TabPanel value={value1} index={1}>
          <AuditTrailsTab base_url={base_url} />
        </TabPanel>
        <TabPanel value={value1} index={2}>
          <BlockIpsTab whitelisted_ips={whitelisted_ips} handleWhitelistIp={handleWhitelistIp} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default SecurityAndActivityTab;
