/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { RecentUpdate } from '../OverviewTab';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface PropTypes {
  recent_updates: RecentUpdate[]
}

const RecentUpdates = ({ recent_updates }: PropTypes) => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{
          borderRadius: '10px',
          border: '1px solid #AFBDFA',
          background: '#fff',
          overflow: 'hidden',
          maxHeight: '394px',
          minHeight: '394px',
          overflowY: 'scroll',
          '& > div + div': {
            borderTop: '1px solid #AFBDFA',
          }
        }}>
          {
            recent_updates?.length > 0
              ? recent_updates.map((update, index) => (
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '17px 18px 16px 18px',
                }}>
                  <Typography variant='h6'>{update?.name}</Typography>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    paddingLeft: '15px',
                    '& p + p': {
                      marginLeft: '15px',
                    }
                  }}>
                    <Typography>
                      {new Date(update?.date).toLocaleDateString('en-GB')}
                    </Typography>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    paddingLeft: '15px',
                    '& p + p': {
                      marginLeft: '15px',
                    }
                  }}>
                    <Typography>
                      From: {update?.old_version}
                    </Typography>
                    <Typography>
                      To: {update?.new_version}
                    </Typography>
                  </Box>
                  {/* <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                }}>
                  <Button sx={{
                    backgroundColor: '#325AE7',
                    borderRadius: '7px',
                    border: '1px solid #325AE7',
                    boxShadow: 'none',
                    padding: '3px 14px',
                    marginLeft: '12px',
                    fontSize: '14px',
                    textTransform: 'inherit',
                    minHeight: 'inherit',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#325AE7',
                    },
                  }}>
                    Restore
                  </Button>
                </Box> */}
                </Box>
              ))
              : <Box sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                maxHeight: '374px',
                minHeight: '374px',
                justifyContent: 'center',
              }}>
                <Typography>No Recent Updates Found</Typography>
              </Box>
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecentUpdates;
