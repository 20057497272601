/**
 *
 * This component will display the site base url and post revisions
 * from the optimization object.
 *
 */

import { Box, Checkbox, Typography } from "@mui/material";
import { useState } from "react";
import { useOptimizationTrackMutation } from "../../../Features/siteSlice";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Optimization = ({
  optimizations,
  onCheckboxChange,
  selectedUrls,
}: {
  optimizations: any[];
  onCheckboxChange: (checked: boolean, baseUrl: string) => void;
  selectedUrls: string[];
}) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: "10px",
          border: "1px solid #AFBDFA",
          background: "#fff",
          overflow: "hidden",
          minHeight: "325px",
          maxHeight: "325px",
          overflowY: "scroll",
          "& > div + div": {
            borderTop: "1px solid #AFBDFA",
          },
        }}
      >
        {optimizations.map((optimization, index) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "17px 18px 16px 18px",
              minHeight: "60px",
              "@media (max-width: 1540px)": {
                flexWrap: "wrap",
              },
            }}
            key={optimization.site_base_url + index}
          >
            <Typography
              sx={{
                width: "auto",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                wordBreak: "break-all",
                padding: "0 10px",
              }}
            >
              <Checkbox
                {...label}
                checked={selectedUrls.includes(optimization.site_base_url)}
                onChange={(event) =>
                  onCheckboxChange(
                    event.target.checked,
                    optimization.site_base_url
                  )
                }
              />
              {optimization.site_base_url}
            </Typography>
            <Typography
              sx={{
                width: "33.33%",
                textAlign: "right",
              }}
            >
              {optimization.post_revisions} page revisions
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Optimization;
