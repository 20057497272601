import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from './apiSlice';

const SLICE_URL = 'settings';

interface Team {
    name: string;
    phone_number: string;
    address: null | string;
    city: null | string;
    postal_code: null | string;
    country: null | string;
    logo: string;
    state: null | string;
    tax_id: null | string;
    timezone: string;
}

interface Settings {
    logging: boolean;
    notification_email: string;
    package: string;
    report_updates: boolean;
    report_backup: boolean;
    report_security: boolean;
    report_downtime: boolean;
    notification_updates: boolean;
    notifications_backups: boolean;
    notifications_security: boolean;
    notifications_downtime: boolean;
}

export interface IAccount {
    two_factor: boolean;
}

interface Package {
    name: string;
    backup_count: number;
    update_count: number;
    image_optimization: null | boolean;
    security: boolean;
    price: number;
    storage_days: number;
}

interface PaymentDetails {
    [key: string]: any; // You can replace 'any' with the appropriate type for payment details
}

export interface ISettingsData {
    team: Team;
    settings: Settings;
    account: IAccount;
    packages: Package[];
    payment_details: PaymentDetails;
}

export const defaultInitialData: ISettingsData = {
    team: {
        name: "",
        phone_number: "",
        address: null,
        city: null,
        postal_code: null,
        country: null,
        logo: "",
        state: null,
        tax_id: null,
        timezone: "",
    },
    settings: {
        logging: false,
        notification_email: "",
        package: "",
        report_updates: false,
        report_backup: false,
        report_security: false,
        report_downtime: false,
        notification_updates: false,
        notifications_backups: false,
        notifications_security: false,
        notifications_downtime: false,
    },
    account: {
        two_factor: false,
    },
    packages: [],
    payment_details: {},
};

interface TwoFAToken {
    mfa_setup: string;
}

export const settingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        updatePassword: builder.mutation({
            query: (passwordData) => ({
                url: `${SLICE_URL}/password/`,
                method: 'POST',
                body: {
                    ...passwordData
                }
            }),
            invalidatesTags: [
                { type: 'Setting', id: "LIST" }
            ]
        }),
        setupTwoFactor: builder.mutation({
            query: (twoFactorData) => ({
                url: `${SLICE_URL}/two_factor/`,
                method: 'POST',
                body: {
                    ...twoFactorData
                }
            }),
            invalidatesTags: [
                { type: 'Setting', id: "LIST" }
            ]
        }),
        getTwoFASecret: builder.query<TwoFAToken, void>({
            query: () => `setup-2fa/`,
            providesTags: (result) => 
                [{ type: 'Setting', id: 'LIST' }],
        }),
        updateEmail: builder.mutation({
            query: (emailData) => ({
                url: `${SLICE_URL}/email/`,
                method: 'POST',
                body: {
                    ...emailData
                }
            }),
            invalidatesTags: [
                { type: 'Setting', id: "LIST" }
            ]
        }),
        getSettings: builder.query<ISettingsData, void>({
            query: () => `${SLICE_URL}/`,
            providesTags: (result) => 
                [{ type: 'Setting', id: 'LIST' }],
        }),
        setSettings: builder.mutation({
            query: (settingsData) => ({
                url: `${SLICE_URL}/`,
                method: 'POST',
                body: {
                    ...settingsData
                }
            }),
            invalidatesTags: [
                { type: 'Setting', id: "LIST" }
            ]
        }),
        getPackages: builder.query<Package[], void>({
            query: () => `packages/`,
            providesTags: (result) => 
                [{ type: 'Setting', id: 'LIST' }],
        }),
    })
});

export const {
    useUpdatePasswordMutation,
    useSetupTwoFactorMutation,
    useGetTwoFASecretQuery,
    useUpdateEmailMutation,
    useGetSettingsQuery,
    useSetSettingsMutation,
    useGetPackagesQuery,
} = settingApiSlice;