import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

const SLICE_URL = "reports";

interface Statistics {
  total_updates: number;
  total_backups: number;
  security_checks: string;
  total_plugin_updates: number;
  average_uptime: number;
}

interface ReportSettings {
  brand_color: string;
  secondary_color: string;
  logo: string;
}

interface Report {
  month_written: string;
  month: string;
  year: string;
  plugin_updates: number;
  total_updates: number;
  total_backups: number;
  average_uptime: number;
  site_base_url: string;
  site_title: string;
  site_favorite: string;
}

interface ReportsData {
  statistics: Statistics;
  report_settings: ReportSettings;
  reports: Report[];
}

export const initialReportsData: ReportsData = {
  statistics: {
    total_updates: 0,
    total_backups: 0,
    security_checks: "0",
    total_plugin_updates: 0,
    average_uptime: 0,
  },
  report_settings: {
    brand_color: "",
    secondary_color: "",
    logo: "",
  },
  reports: [
    {
      month_written: "",
      month: "",
      year: "",
      plugin_updates: 0,
      total_updates: 0,
      total_backups: 0,
      average_uptime: 0,
      site_base_url: "",
      site_title: "",
      site_favorite: "",
    },
  ],
};

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query<ReportsData, void>({
      query: () => `${SLICE_URL}/`,
      providesTags: (result) => [{ type: "Report", id: "LIST" }],
    }),
    getWebsiteReports: builder.query({
      query: (website) => `${SLICE_URL}/${website}/`,
      providesTags: (result) => [{ type: "Report", id: "LIST" }],
    }),
  }),
});

export const { useGetReportsQuery, useGetWebsiteReportsQuery } = reportApiSlice;
