/**
 *
 * This view contains login page design you can change it as you want from ./styled.tsx
 *
 */
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useLoginMutation } from '../../Features/accountSlice';
import LoginBanner from '../../Assets/Images/login-img.png'
interface ILogin {
  email: string;
  password: string;
  two_factor_token?: number | undefined;
}

export type AuthenticatedUser = {
  access_token: string;
  refresh_token: string;
  user: {
    email: string;
    first_name: string;
    company_name: string;
    is_company: boolean;
    is_active: boolean;
  };
  detail?: string;
};

const Login = () => {
  /*-----------Initializations-------------*/

  let localUser;

  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const [twoFARequired, setTwoFARequired] = useState<boolean>(false);
  const initialValue = {
    email: '',
    password: '',
    two_factor_token: undefined
  };

  const [login, { isLoading }] = useLoginMutation();

  // Add Validation to login form

  const validationSchema = yup.object({
    email: yup
      .string()
      .required('Email is required!')
      .email('Please enter valid email!'),
    password: yup.string().required('Password is required!'),
    two_factor_token: yup.string()
  });

  /*-----------Initializations-------------*/

  /*--------------Handlers-------------*/

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      setError('');
      try {
        const userData: AuthenticatedUser = await login(data).unwrap();
        if (userData?.detail) {
          setTwoFARequired(true);
        } else {
          localStorage.setItem("wpAuthenticatedUser", JSON.stringify({ ...userData }));
          navigate('/');
        }
      } catch (error: any) {
        if (!error?.status) {
          // isLoading: true until timeout occurs
          setError('No Server Response');
        } else if (error.status === 403) {
          setError('Invalid Username or Password');
        } else {
          setError('Login Failed');
        }
      }
    }
  });

  /*--------------Handlers-------------*/

  /*--------------Effects-------------*/

  // This will loads the dashbaord if user is already logged in.

  useEffect(() => {
    localUser = localStorage.getItem('wpAuthenticatedUser');
    if (localUser) {
      navigate('/');
    } 
  }, [navigate]);

  /*--------------Effects-------------*/

  return (
    !localUser && (
      <>
        <Box sx={{
          minHeight: '100vh',
          marginTop: '-100px',
          display: 'flex',
          background: '#F4F5FE',
          boxSizing: 'border-box',
          '& *': {
            boxSizing: 'border-box',
          },
          '@media (max-width: 767px)': {
            flexWrap: 'wrap',
          },
        }}>
          <Box sx={{
            width: '50%',
            maxHeight: '50%',
            flex: '0 0 50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '60px 30px',
            '@media (max-width: 767px)': {
              width: '100%',
              maxHeight: '100%',
              flex: '0 0 100%',
              padding: '50px 15px',
            },
          }}>
            <Box sx={{
              width: '400px',
              maxWidth: '100%',
            }}>
              <Typography
                variant='h1'
                sx={{
                  color: '#1B1D29',
                  fontSize: '32px',
                  lineHeight: '40px',
                  fontWeight: '600',
                  marginBottom: '20px',
                  '@media (max-width: 767px)': {
                    fontSize: '26px',
                    lineHeight: '30px',
                    marginBottom: '10px',
                  },
                }}
              >
                Welcome to WP Monitoring
              </Typography>
              <Typography>Your WordPress monitoring tool that will change your website maintenance forever.</Typography>
              {error && <Typography variant="h6" color="red" sx={{ mt: 3 }}>Error Loging in: {error}</Typography>}
              {twoFARequired && <Typography variant="h6" color="green" sx={{ mt: 3 }}>Please provide additional information.</Typography>}
              <Box 
                sx={{ 
                  mt: 5 ,
                  '@media (max-width: 767px)': {
                    marginTop: '30px',
                  }
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label="Email Address"
                    margin="normal"
                    {...formik.getFieldProps('email')}
                    type="email"
                    variant="outlined"
                    sx={{
                      '& label': {
                        color: '#325AE7',
                      },
                      '& input': {
                        borderRadius: '10px',
                        background: '#fff',
                      },
                      '& fieldset': {
                        borderColor: '#325AE7',
                        borderRadius: '10px',
                      }
                    }}
                  />
                  <TextField
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    fullWidth
                    helperText={formik.touched.password && formik.errors.password}
                    label="Password"
                    margin="normal"
                    {...formik.getFieldProps('password')}
                    type="password"
                    variant="outlined"
                    sx={{
                      marginTop: '30px',
                      '& label': {
                        color: '#325AE7',
                      },
                      '& input': {
                        borderRadius: '10px',
                        background: '#fff',
                      },
                      '& fieldset': {
                        borderColor: '#325AE7',
                        borderRadius: '10px',
                      }
                    }}
                  />
                  {twoFARequired && (
                    <TextField
                      error={Boolean(
                        formik.touched.two_factor_token &&
                          formik.errors.two_factor_token
                      )}
                      fullWidth
                      helperText={
                        formik.touched.two_factor_token &&
                        formik.errors.two_factor_token
                      }
                      label="2FA Token"
                      margin="normal"
                      {...formik.getFieldProps('two_factor_token')}
                      type="password"
                      variant="outlined"
                      sx={{
                        marginTop: '30px',
                        '& label': {
                          color: '#325AE7',
                        },
                        '& input': {
                          borderRadius: '10px',
                          background: '#fff',
                        },
                        '& fieldset': {
                          borderColor: '#325AE7',
                          borderRadius: '10px',
                        }
                      }}
                    />
                  )}
                  <Box sx={{
                    marginTop: '30px',
                  }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={!formik.isValid || isLoading}
                      sx={{
                        width: '100%',
                        backgroundColor: '#325AE7',
                        borderRadius: '10px',
                        border: '1px solid #325AE7',
                        color: "#ffffff",
                        boxShadow: 'none',
                        padding: '10px 19px',
                        textTransform: 'inherit',
                        '&:hover': {
                          boxShadow: 'none',
                          backgroundColor: '#325AE7',
                        },
                        '& img': {
                          marginRight: '10px',
                        }
                      }}
                    >
                      { isLoading ? 'Please Wait...' : 'Login' }
                    </Button>
                  </Box>
                </form>
              </Box>
              <Typography
                sx={{
                  marginTop: '40px',
                  '@media (max-width: 767px)': {
                    marginTop: '20px',
                  },
                  '& a': {
                    color: '#325AE7',
                    textDecoration: 'none',
                  }
                }}
              >
                By using WP Monitoring I agree with the <a href='https://wpmonitoring.com/privacy-policy/' target="_blank">Terms and Conditions</a> of <a href='https://wpmonitoring.com' target='_blank'>WP Monitoring</a>.
              </Typography>
              {/* <Typography sx={{
                marginTop: '30px',
                '@media (max-width: 767px)': {
                  marginTop: '20px',
                },
                '& a': {
                  color: '#325AE7',
                }
              }}>
                Do you want to join us? <Link to="/register">Register here</Link>
              </Typography> */}
            </Box>
          </Box>
          <Box sx={{
            width: '50%',
            maxHeight: '50%',
            background: '#D5DDF9',
            flex: '0 0 50%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            paddingLeft: '100px',
            paddingTop: '100px',
            '@media (max-width: 1024px)': {
              paddingLeft: '40px',
              paddingTop: '40px',
            },
            '@media (max-width: 767px)': {
              width: '100%',
              maxHeight: '100%',
              flex: '0 0 100%',
              paddingLeft: '15px',
            },
          }}>
            <Box>
              <Box sx={{
                    maxWidth: '600px',
                    paddingRight: '30px',
                    '@media (max-width: 767px)': {
                      width: '100%',
                      maxHeight: '100%',
                      flex: '0 0 100%',
                      paddingRight: '15px',
                    },
              }}>
                <Typography
                  variant='h2'
                  sx={{
                    fontSize: '50px',
                    lineHeight: '58px',
                    textTransform: 'uppercase',
                    '@media (max-width: 1024px)': {
                      fontSize: '40px',
                      lineHeight: '45px',
                    },
                    '@media (max-width: 767px)': {
                      fontSize: '30px',
                      lineHeight: '38px',
                    },
                  }}
                >
                  Maintain YOUR Website easier than ever
                </Typography>
                <Box sx={{
                  borderLeft: '3px solid #325AE7',
                  paddingLeft: '40px',
                  margin: '40px 0',
                  '@media (max-width: 1024px)': {
                    margin: '30px 0',
                    paddingLeft: '20px',
                  },
                }}>
                  <Typography>- Automatically creates backups of your sites.</Typography>
                  <Typography>- Control your sites after the automated updates.</Typography>
                  <Typography>- It will save a lot of time</Typography>
                  <Typography>- Save logs of all the activity for clear overview.</Typography>
                </Box>
              </Box>
              <Box sx={{
                lineHeight: '0',
                '& img': {
                  width: '100%',
                }
              }}>
                <img src={LoginBanner} />
              </Box>
            </Box>
          </Box>
        </Box>

      </>
    )
  );
};

export default Login;
