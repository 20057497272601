import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import './style.css'
import { Formik } from 'formik';
import { SelectChangeEvent } from '@mui/material/Select';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import LoginBanner from '../../Assets/Images/register-banner.png'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const Register = () => {
  const [value1, setValue1] = React.useState('female');

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue1((event.target as HTMLInputElement).value);
  };

  const [timezone, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };


  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: '40px',
    height: '20px',
    padding: 0,
    display: 'flex',
    borderRadius: '100px',
    background: '#BECBFF',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      color: '#14161F',
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#325AE7',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '',
      width: '16px',
      height: '16px',
      borderRadius: '100px',
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  return (
    <Box sx={{
      minHeight: '100vh',
      marginTop: '-100px',
      display: 'flex',
      background: '#F4F5FE',
      boxSizing: 'border-box',
      '& *': {
        boxSizing: 'border-box',
      },
      '@media (max-width: 767px)': {
        flexWrap: 'wrap',
        flexDirection: 'column-reverse',
      },
    }}>
      <Box sx={{
        width: '40%',
        maxHeight: '40%',
        background: '#D5DDF9',
        flex: '0 0 40%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingLeft: '100px',
        paddingTop: '100px',
        '@media (max-width: 1024px)': {
          paddingLeft: '40px',
          paddingTop: '40px',
        },
        '@media (max-width: 767px)': {
          width: '100%',
          maxHeight: '100%',
          flex: '0 0 100%',
          paddingLeft: '15px',
        },
      }}>
        <Box>
          <Box sx={{
            maxWidth: '530px',
            paddingRight: '30px',
            '@media (max-width: 767px)': {
              width: '100%',
              maxHeight: '100%',
              flex: '0 0 100%',
              paddingRight: '15px',
            },
          }}>
            <Typography
              variant='h2'
              sx={{
                fontSize: '50px',
                lineHeight: '58px',
                textTransform: 'uppercase',
                '@media (max-width: 1024px)': {
                  fontSize: '40px',
                  lineHeight: '45px',
                },
                '@media (max-width: 767px)': {
                  fontSize: '30px',
                  lineHeight: '38px',
                },
              }}
            >
              Maintain YOUR Website easier than ever
            </Typography>
            <Box sx={{
              borderLeft: '3px solid #325AE7',
              paddingLeft: '40px',
              margin: '40px 0',
              '@media (max-width: 1024px)': {
                margin: '30px 0',
                paddingLeft: '20px',
              },
            }}>
              <Typography>WP Monitoring updates your system and plugins constantly and checks your sites for possible errors so you don’t have to! It will save a lot of time on website maintenance so you can focus on your main tasks.</Typography>
            </Box>
          </Box>
          <Box sx={{
            lineHeight: '0',
            '& img': {
              width: '100%',
            }
          }}>
            <img src={LoginBanner} />
          </Box>
        </Box>
      </Box>
      <Box sx={{
        width: '60%',
        maxHeight: '60%',
        flex: '0 0 60%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '60px 30px',
        '@media (max-width: 767px)': {
          width: '100%',
          maxHeight: '100%',
          flex: '0 0 100%',
          padding: '50px 15px',
        },
      }}>
        <Box sx={{
          width: '740px',
          maxWidth: '100%',
        }}>
          <Typography
            variant='h1'
            sx={{
              color: '#1B1D29',
              fontSize: '32px',
              lineHeight: '40px',
              fontWeight: '600',
              marginBottom: '20px',
              '@media (max-width: 767px)': {
                fontSize: '26px',
                lineHeight: '30px',
                marginBottom: '10px',
              },
            }}
          >
            Hi There!
          </Typography>
          <Typography>Please fill up the from below</Typography>

          <Box
            sx={{
              mt: 3,
              '@media (max-width: 767px)': {
                marginTop: '30px',
              }
            }}
          >
            {/* <Formik> */}
            <form>
              <Grid container spacing={4}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    type='email'
                    placeholder="example@mail.com"
                    variant="outlined"
                    sx={{
                      '& label': {
                        color: '#325AE7',
                      },
                      '& input': {
                        borderRadius: '10px',
                        background: '#fff',
                      },
                      '& fieldset': {
                        borderColor: '#325AE7',
                        borderRadius: '10px',
                      }
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label="Password"
                    name="password"
                    fullWidth
                    type="password"
                    placeholder="SAbcd#123"
                    variant="outlined"
                    sx={{
                      '& label': {
                        color: '#325AE7',
                      },
                      '& input': {
                        borderRadius: '10px',
                        background: '#fff',
                      },
                      '& fieldset': {
                        borderColor: '#325AE7',
                        borderRadius: '10px',
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{
                marginTop: '30px',
              }}>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '10px',
                    '& > span': {
                      marginRight: '5px',
                    }
                  }}
                >
                  <Checkbox {...label} sx={{ padding: 0, marginRight: '3px' }} />
                  News and features updates, as well as occasional company annoucements.
                </Typography>
              </Box>
              <Box sx={{
                marginTop: '30px',
              }}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{
                    backgroundColor: '#325AE7',
                    borderRadius: '10px',
                    border: '1px solid #325AE7',
                    color: "#ffffff",
                    boxShadow: 'none',
                    padding: '10px 40px',
                    textTransform: 'inherit',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#325AE7',
                    },
                    '& img': {
                      marginRight: '10px',
                    }
                  }}
                >
                  Sign Up
                </Button>
              </Box>
            </form>

            {/* </Formik> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
