/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, FormControl, Grid, InputBase, Radio, RadioGroup, Typography, TextField } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import RedI from '../../../Assets/Images/red-i.svg'
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import { useFormik, FormikConfig } from 'formik';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import type { ITimezone } from "react-timezone-select";

import Swal from 'sweetalert2';
// Yup validators
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { IAccount, useSetupTwoFactorMutation, useUpdatePasswordMutation, ISettingsData, useSetSettingsMutation } from '../../../Features/settingSlice';
import TwoFASetupDialog from '../TwoFASetupDialog';
YupPassword(Yup); // extend yup

interface PasswordChangeFormValues {
    old_password: string;
    new_password: string;
    confirm_password: string;
}

interface TwoFactorSetupFormikValues {
    action: string;
    current_password: string;
}

export interface TwoFactorSetupFormikProps extends FormikConfig<TwoFactorSetupFormikValues> { }

interface PropTypes {
    accountSettings: IAccount;
    settingsData: ISettingsData;
}

const StyledButton = styled('button')(
    ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  padding: 7px 12px;
  border-radius: 7px;
  text-align: left;
  min-height: 47px;
  line-height: 1.5;
  background: #ffffff;
  font-size: 16px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  font-family: Titillium Web;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  
  
  &.${selectUnstyledClasses.expanded} {
    &::after {
      float: right;
      transform: rotate(180deg);
    }
  }
  
  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
  box-sizing: border-box;
  padding: 6px;
  min-width: 200px;
  border-radius: 12px;
  font-family: Titillium Web;
  font-weight: 600;
  overflow: auto;
  outline: 0px;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  width: 100%;
  `,
);

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  
  &:last-of-type {
    border-bottom: none;
  }
  
  &.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
  `;

const CustomSelect = React.forwardRef(function CustomSelect<
    TValue extends {},
    Multiple extends boolean,
>(
    props: SelectUnstyledProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const slots: SelectUnstyledProps<TValue, Multiple>['slots'] = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: StyledPopper,
        ...props.slots,
    };

    return <SelectUnstyled {...props} ref={ref} slots={slots} />;
}) as <TValue extends {}, Multiple extends boolean>(
    props: SelectUnstyledProps<TValue, Multiple> &
        React.RefAttributes<HTMLButtonElement>,
) => JSX.Element;

const AccountSettingsTab = ({ accountSettings, settingsData }: PropTypes) => {
    console.log("accountSettings", accountSettings)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedTimezone, setSelectedTimezone] = React.useState<ITimezone>(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [notificationEmail, setNotificationEmail] = React.useState("");
    const [companyName, setCompanyName] = React.useState(settingsData?.team?.name || "");
    const [setSettings, { isSuccess }] = useSetSettingsMutation();

    const [updatePassword, { isLoading: isUpdatingPassword }] = useUpdatePasswordMutation();
    const [setupTwoFactor, { isLoading: settingUpTwoFa }] = useSetupTwoFactorMutation();

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    React.useEffect(() => {
        if (settingsData) {
            setNotificationEmail(settingsData.settings.notification_email);
            setSelectedTimezone(settingsData.team.timezone);
        }
    }, [settingsData]);

    const passwordChangeFormik = useFormik<PasswordChangeFormValues>({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            old_password: Yup.string(),
            new_password: Yup.string()
                .min(8, 'Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character.')
                .matches(/^(?=.*[a-z])/g, 'Password should contain at least 1 lowercase letter')
                .matches(/^(?=.*[A-Z])/g, 'Password should contain at least 1 uppercase letter')
                .matches(/^(?=.*\d)/g, 'Password should contain at least 1 number')
                .matches(/^(?=.*[^a-zA-Z\d])/g, 'Password should contain at least 1 special character')
                .required('Password is required')
                .test('not-same-as-old', 'New password must be different from the old password', function (value) {
                    const { old_password } = this.parent;
                    return value !== old_password;
                }),
            confirm_password: Yup.string()
                .max(255)
                .required('Please confirm your password')
                .oneOf([Yup.ref('new_password')], 'Your passwords do not match.')
        }),
        onSubmit: async (data) => {
            try {
                const passwordChangeData = JSON.stringify(data, null, 2);
                console.log(passwordChangeData);
                await updatePassword(passwordChangeData).unwrap();
                Swal.fire({
                    icon: 'success',
                    title: 'Update Successful',
                    text: 'Password change was successful!',
                    confirmButtonColor: '#325AE7',
                })
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: 'Update Failed',
                    text: error?.data?.message
                })
            }
        }
    });

    const handleUpdate = () => {
        // Push the updated settings to the API
        const updatedSettings = {
            ...settingsData,
            settings: {
                ...settingsData.settings,
                notification_email: notificationEmail
            },
            team: {
                ...settingsData.team,
                timezone: selectedTimezone
            }
        };

        setSettings(updatedSettings);
    };

    const twoFactorSetupFormikConfig: TwoFactorSetupFormikProps = {
        initialValues: {
            action: '',
            current_password: ''
        },
        validationSchema: Yup.object({
            action: Yup.string().required(),
            current_password: Yup.string().required('Your current password is required.')
        }),
        onSubmit: async (data) => {
            const twoFactorSetupData = JSON.stringify(data, null, 2);
            await setupTwoFactor(twoFactorSetupData);
        }
    };

    const twoFactorSetupFormik = useFormik(twoFactorSetupFormikConfig);

    // const twoFactorSetupFormik = useFormik({
    //     initialValues: {
    //       action: '',
    //       current_password: ''
    //     },
    //     validationSchema: Yup.object({
    //       action: Yup.string().required(),
    //       current_password: Yup.string().required(
    //         'Your current password is required.'
    //       )
    //     }),
    //     onSubmit: async(data) => {
    //       const twoFactorSetupData = JSON.stringify(data, null, 2);
    //       await setupTwoFactor(twoFactorSetupData);
    //     }
    // });

    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography variant='h6' sx={{
                        fontSize: '18px',
                        marginBottom: '20px',
                    }}>
                        General Information
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        background: '#fff',
                        padding: '20px',
                        height: 'calc(100% - 50px)',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{
                                    '& label': {
                                        width: '100%',
                                        display: 'inline-block',
                                        marginBottom: '8px',
                                        fontWeight: '600',
                                    },
                                    '& input': {
                                        fontSize: '16px',
                                        color: '#525566',
                                        border: '1px solid #AFBDFA',
                                        width: '100%',
                                        padding: '11px 15px',
                                        background: '#ffffff',
                                        borderRadius: '7px',
                                    }
                                }}>
                                    <label>Notification E-mail</label>
                                    <InputBase
                                        sx={{
                                            width: '100%',
                                        }}
                                        type='email'
                                        value={notificationEmail}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{
                                    position: 'relative',
                                    '& label': {
                                        width: '100%',
                                        display: 'inline-block',
                                        marginBottom: '8px',
                                        fontWeight: '600',
                                    },
                                    '& input': {
                                        fontSize: '16px',
                                        color: '#525566',
                                        width: '100%',
                                        padding: '11px 15px',
                                        background: '#ffffff',
                                        border: '1px solid #AFBDFA',
                                        borderRadius: '7px',
                                    }
                                }}>
                                    <label>Time zone</label>
                                    <TimezoneSelect
                                        value={selectedTimezone}
                                        onChange={setSelectedTimezone}
                                        timezones={{
                                            ...allTimezones,
                                            "America/Lima": "Pittsburgh",
                                            "Europe/Berlin": "Frankfurt"
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <Button sx={{
                                        backgroundColor: '#325AE7',
                                        borderRadius: '7px',
                                        border: '1px solid #325AE7',
                                        boxShadow: 'none',
                                        padding: '7px 19px',
                                        marginRight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        textTransform: 'inherit',
                                        '&:hover': {
                                            boxShadow: 'none',
                                            backgroundColor: '#325AE7',
                                        },
                                    }}
                                        onClick={handleUpdate}
                                    >
                                        Update
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography variant='h6' sx={{
                        fontSize: '18px',
                        marginBottom: '20px',
                    }}>
                        Change password
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        background: '#fff',
                        padding: '20px',
                        height: 'calc(100% - 50px)',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Box>
                                    <Typography>
                                        To create a new password, you have to meet all of the following requirements:
                                        <br />
                                        <br />
                                        ●  Minimum 8 characters.<br />
                                        ●  At least 1 special character.<br />
                                        ●  At least 1 number.<br />
                                        ●  Can't be the same as previous passwords.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                <form onSubmit={passwordChangeFormik.handleSubmit}>
                                    <Box sx={{
                                        '& label': {
                                            width: '100%',
                                            display: 'inline-block',
                                            marginBottom: '8px',
                                            fontWeight: '600',
                                            top: '-4px',
                                        },
                                        '& input': {
                                            fontSize: '16px',
                                            color: '#525566',
                                            border: '1px solid #AFBDFA',
                                            width: '100%',
                                            padding: '11px 15px',
                                            background: '#ffffff',
                                            borderRadius: '7px',
                                        }
                                    }}>
                                        <TextField
                                            error={Boolean(
                                                passwordChangeFormik.touched.old_password &&
                                                passwordChangeFormik.errors.old_password
                                            )}
                                            fullWidth
                                            helperText={
                                                passwordChangeFormik.touched.old_password &&
                                                passwordChangeFormik.errors.old_password
                                            }
                                            label="Current Password"
                                            placeholder="Current Password"
                                            margin="normal"
                                            type="password"
                                            {...passwordChangeFormik.getFieldProps('old_password')}
                                            variant="outlined"
                                            inputProps={{
                                                style: { backgroundColor: 'white' }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        '& label': {
                                            width: '100%',
                                            display: 'inline-block',
                                            marginBottom: '8px',
                                            top: '-4px',
                                            fontWeight: '600',
                                        },
                                        '& input': {
                                            fontSize: '16px',
                                            color: '#525566',
                                            border: '1px solid #AFBDFA',
                                            width: '100%',
                                            padding: '11px 15px',
                                            background: '#ffffff',
                                            borderRadius: '7px',
                                        }
                                    }}>
                                        <TextField
                                            error={Boolean(
                                                passwordChangeFormik.touched.new_password &&
                                                passwordChangeFormik.errors.new_password
                                            )}
                                            fullWidth
                                            helperText={
                                                passwordChangeFormik.touched.new_password &&
                                                passwordChangeFormik.errors.new_password
                                            }
                                            label="New Password"
                                            placeholder="New Password"
                                            margin="normal"
                                            type="password"
                                            {...passwordChangeFormik.getFieldProps('new_password')}
                                            variant="outlined"
                                            inputProps={{
                                                style: { backgroundColor: 'white' }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        '& label': {
                                            width: '100%',
                                            top: '-4px',
                                            display: 'inline-block',
                                            marginBottom: '8px',
                                            fontWeight: '600',
                                        },
                                        '& input': {
                                            fontSize: '16px',
                                            color: '#525566',
                                            border: '1px solid #AFBDFA',
                                            width: '100%',
                                            padding: '11px 15px',
                                            background: '#ffffff',
                                            borderRadius: '7px',
                                        }
                                    }}>
                                        <TextField
                                            error={Boolean(
                                                passwordChangeFormik.touched.confirm_password &&
                                                passwordChangeFormik.errors.confirm_password
                                            )}
                                            fullWidth
                                            helperText={
                                                passwordChangeFormik.touched.confirm_password &&
                                                passwordChangeFormik.errors.confirm_password
                                            }
                                            label="Confirm Password"
                                            placeholder="Confirm Password"
                                            margin="normal"
                                            type="password"
                                            {...passwordChangeFormik.getFieldProps('confirm_password')}
                                            variant="outlined"
                                            inputProps={{
                                                style: { backgroundColor: 'white' }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        marginTop: '15px',
                                    }}>
                                        <Button
                                            type="submit"
                                            disabled={isUpdatingPassword} // Disable the button while loading
                                            sx={{
                                                backgroundColor: '#325AE7',
                                                borderRadius: '7px',
                                                border: '1px solid #325AE7',
                                                boxShadow: 'none',
                                                padding: '7px 19px',
                                                marginRight: '12px',
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                                textTransform: 'inherit',
                                                '&:hover': {
                                                    boxShadow: 'none',
                                                    backgroundColor: '#325AE7',
                                                },
                                            }}
                                        >
                                            {isUpdatingPassword ? 'Loading...' : 'Save'} {/* Display "Loading..." while loading */}
                                        </Button>
                                        {
                                            passwordChangeFormik.values.new_password !== passwordChangeFormik.values.confirm_password &&
                                            <Typography sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                '& img': {
                                                    marginRight: '5px',
                                                }
                                            }}>
                                                <img src={RedI} />
                                                Current password doesn't match!
                                            </Typography>
                                        }
                                    </Box>
                                </form>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid sx={{ marginTop: '30px' }} item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography variant='h6' sx={{
                        fontSize: '18px',
                        marginBottom: '20px',
                    }}>
                        Two step authentication
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        background: '#fff',
                        padding: '20px',
                        height: 'calc(100% - 50px)',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Box sx={{
                            paddingRight: '40px',
                        }}>
                            <Typography>For the best security you can enable two step verification by using authenticator app or phone number.</Typography>
                            <Typography sx={{ margin: '13px 0 13px 0' }}><b>How is it work:</b> After successfully logging into your account a random 6 digits code will go to your authenticator app or phone number and you need that 6 digit code to get into your account.</Typography>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Button
                                    sx={{
                                        backgroundColor: '#325AE7',
                                        borderRadius: '7px',
                                        border: '1px solid #325AE7',
                                        boxShadow: 'none',
                                        padding: '7px 10px',
                                        marginRight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        whiteSpace: 'nowrap',
                                        minWidth: '124px',
                                        textTransform: 'inherit',
                                        '&:hover': {
                                            boxShadow: 'none',
                                            backgroundColor: '#325AE7',
                                        },
                                    }}
                                    onClick={() => setOpenDialog(true)}
                                >
                                    Set up 2FA
                                </Button>
                                <Typography>Use an authenticator app to generate one time security codes.</Typography>
                            </Box>
                        </Box>
                        <Typography
                            sx={{
                                display: 'flex',
                                '& b': {
                                    marginRight: '5px',
                                }
                            }}
                        >
                            <b>Status:</b>
                            {
                                accountSettings.two_factor
                                    ? <Typography sx={{ color: '#2D9D78' }}>On</Typography>
                                    : <Typography sx={{ color: '#E23A45' }}>Off</Typography>
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid sx={{ marginTop: '30px' }} item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography variant='h6' sx={{
                        fontSize: '18px',
                        marginBottom: '20px',
                    }}>
                        Company information
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        background: '#fff',
                        padding: '20px',
                        height: 'calc(100% - 50px)',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{
                                    '& label': {
                                        width: '100%',
                                        display: 'inline-block',
                                        marginBottom: '8px',
                                        fontWeight: '600',
                                    },
                                    '& input': {
                                        fontSize: '16px',
                                        color: '#525566',
                                        border: '1px solid #AFBDFA',
                                        width: '100%',
                                        padding: '11px 15px',
                                        background: '#ffffff',
                                        borderRadius: '7px',
                                    }
                                }}>
                                    <label>Company name</label>
                                    <InputBase
                                        sx={{
                                            width: '100%',
                                        }}
                                        type='text'
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Box sx={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <Button sx={{
                                        backgroundColor: '#325AE7',
                                        borderRadius: '7px',
                                        border: '1px solid #325AE7',
                                        boxShadow: 'none',
                                        padding: '7px 19px',
                                        marginRight: '12px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        textTransform: 'inherit',
                                        '&:hover': {
                                            boxShadow: 'none',
                                            backgroundColor: '#325AE7',
                                        },
                                    }}
                                        onClick={handleUpdate}

                                    >
                                        Update
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <TwoFASetupDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} accountSettings={accountSettings} />
        </Box>
    );
};

export default AccountSettingsTab;
