import React from 'react';
import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Box, Button, Checkbox, Fade, Grid, IconButton, InputBase, Modal, Select, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import SelectUnstyled, { SelectUnstyledProps, selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useInstallThemeMutation } from '../../../Features/siteSlice';
import Swal from 'sweetalert2';

export type ThemeList = Theme[];
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const StyledButton = styled('button')(
    ({ theme }) => `
  box-sizing: border-box;
  min-width: 144px;
  padding: 7px 12px;
  border-radius: 7px;
  text-align: left;
  line-height: 1.5;
  background: #ffffff;
  font-size: 16px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  font-family: Titillium Web;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  
  
  &.${selectUnstyledClasses.expanded} {
    &::after {
      float: right;
      transform: rotate(180deg);
    }
  }
  
  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
  box-sizing: border-box;
  padding: 6px;
  min-width: 200px;
  border-radius: 12px;
  font-family: Titillium Web;
  font-weight: 600;
  overflow: auto;
  outline: 0px;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  `,
);

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  
  &:last-of-type {
    border-bottom: none;
  }
  
  &.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  `,
);

interface ThemeModalProps {
    open: boolean;
    handleClose: () => void;
    website_url: string;
    // define other props you might need
}

type ThemeAuthor = {
    user_nicename: string;
    profile: string;
    avatar: string;
    display_name: string;
    author: string;
    author_url: string;
}

type Theme = {
    name: string;
    slug: string;
    author: ThemeAuthor;
    rating: number;
    num_ratings: number;
    description: string;
    requires: string;
    requires_php: string;
    screenshot_url: string;
}

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
  `;
const CustomSelect = React.forwardRef(function CustomSelect<
    TValue extends {},
    Multiple extends boolean,
>(
    props: SelectUnstyledProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const slots: SelectUnstyledProps<TValue, Multiple>['slots'] = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: StyledPopper,
        ...props.slots,
    };

    return <SelectUnstyled {...props} ref={ref} slots={slots} />;
}) as <TValue extends {}, Multiple extends boolean>(
    props: SelectUnstyledProps<TValue, Multiple> &
        React.RefAttributes<HTMLButtonElement>,
) => JSX.Element;


const InstallTheme: React.FC<ThemeModalProps> = ({ open, handleClose, website_url }) => {
    const [searchResults, setSearchResults] = useState<Theme[]>([]);
    const [searchInput, setSearchInput] = useState("");

    const [installTheme, { isLoading: installingTheme }] = useInstallThemeMutation();

    const fetchThemes = debounce((searchQuery) => {
        fetch(
            'https://api.wordpress.org/themes/info/1.2/?action=query_themes&request[per_page]=20&request[browse]=popular&request[search]=' +
            searchQuery
        )
            .then((response) => response.json())
            .then((response) => {
                setSearchResults(response.themes);
            });
    }, 500);

    useEffect(() => {
        fetchThemes(searchInput);
    }, [searchInput]);

    const handleInstallTheme = (name: string, slug: string) => {
        Swal.fire({
            title: `Install Theme ${name}?`,
            showCancelButton: true,
            confirmButtonText: 'Install!',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              try {
                await installTheme({ website_url, slug });
              } catch (error) {
                Swal.fire({
                  icon: 'error',
                  title: `Theme Install Failed`,
                  text: `Failed to install theme ${name}. Please try again later.`
                })
              }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async(result) => {
            if (result.isConfirmed) {
              Swal.fire({
                icon: 'success',
                title: `Theme Install Successful`,
                text: `The theme ${name} was installed successfully!`
              })
            }
        })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1500,
                    maxWidth: 'calc(100vw - 20px)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '10px',
                    padding: '30px 30px 30px 30px'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                    }}>
                        <Typography variant='h4'>Install Theme</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#ffffff',
                                borderRadius: '8px',
                                marginLeft: '10px',
                                marginRight: '10px',
                                border: '1px solid #AFBDFA',
                                '@media (max-width: 600px)': {
                                    display: 'none',
                                },
                            }}>
                                <IconButton
                                    type="button"
                                    aria-label="search"
                                    sx={{
                                        padding: '8px 10px',
                                        backgroundColor: 'transparent',
                                        borderRadius: '0px',
                                        color: '#325AE7',
                                        borderRight: '1px solid #AFBDFA'
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    sx={{
                                        fontSize: '16px',
                                        color: '#525566',
                                        minWidth: '216px',
                                        marginLeft: '20px',
                                    }}
                                    placeholder="Search"
                                    type='search'
                                    inputProps={{ 'aria-label': 'Search' }}
                                    onChange={(event) => setSearchInput(event.target.value)}
                                    value={searchInput}
                                />
                            </Box>
                            <Button onClick={handleClose} sx={{
                                backgroundColor: 'transparent',
                                borderRadius: '7px',
                                border: '1px solid #AFBDFA',
                                boxShadow: 'none',
                                padding: '7px 9px',
                                color: '#325AE7',
                                fontSize: '15px',
                                textTransform: 'inherit',
                                minWidth: 'auto',
                                '&:hover': {
                                    boxShadow: 'none',
                                    color: '#ffffff',
                                    backgroundColor: '#325AE7',
                                },
                            }}>
                                <CloseIcon />
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{
                        maxHeight: 'calc(100vh - 260px)',
                        overflowY: 'scroll',
                        paddingRight: '20px',
                    }}>
                        <Grid container spacing={2}>
                            {searchResults.map((theme, index) => (
                                <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={index}>
                                    <Box sx={{
                                        padding: '18px',
                                        background: '#ffffff',
                                        borderRadius: '10px',
                                        position: 'relative',
                                        border: '1px solid #AFBDFA',
                                    }}>
                                        <Box sx={{
                                            position: 'absolute',
                                            width: '30px',
                                            zIndex: '1',
                                            height: '30px',
                                            background: '#fff',
                                            borderRadius: '4px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            top: '10px',
                                            right: '10px',
                                            '& svg': {
                                                width: '20px',
                                                color: '#325AE7',
                                            }
                                        }}>
                                            <Checkbox {...label} sx={{ padding: 0, marginRight: '3px' }} />
                                        </Box>
                                        <Box sx={{
                                            '& img': {
                                                maxHeight: '170px',
                                                borderRadius: '5px',
                                                objectFit: 'cover',
                                                width: '100%',
                                            }
                                        }}>
                                            <img src={theme.screenshot_url} />
                                        </Box>
                                        <Typography variant='h6' sx={{
                                            borderBottom: '1px solid #AFBDFA',
                                            padding: '10px 0',
                                        }}>
                                            {theme.name}
                                        </Typography>
                                        <Box className='' sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            marginTop: '15px',
                                            '&.active-theme button:first-child img': {
                                                filter: 'brightness(0)',
                                                opacity: '0.5'
                                            },
                                            '&.active-theme button:first-child': {
                                                pointerEvents: 'none',
                                            },
                                            '&.active-theme button:last-child': {
                                                pointerEvents: 'none',
                                                background: '#918F97',
                                                borderColor: '#918F97',
                                            },
                                        }}>
                                            <Button
                                                sx={{
                                                    backgroundColor: '#325AE7',
                                                    borderRadius: '7px',
                                                    border: '1px solid #325AE7',
                                                    boxShadow: 'none',
                                                    padding: '3px 14px',
                                                    fontSize: '14px',
                                                    textTransform: 'inherit',
                                                    whiteSpace: 'nowrap',
                                                    minWidth: '98px',
                                                    minHeight: 'inherit',
                                                    '&:hover': {
                                                        boxShadow: 'none',
                                                        backgroundColor: '#325AE7',
                                                    },
                                                }}
                                                onClick={() => handleInstallTheme(theme.name, theme.slug)}
                                            >
                                                Install Theme
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default InstallTheme;
