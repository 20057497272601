/**
 *
 * All the theme colors typography and mui components can be modify from here.
 *
 */

const { createTheme } = require('@mui/material');
const theme = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#325AE7',
      dark: '#000000',
      light: '#494949',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1B1D29',
      dark: '#325AE7',
      light: '#ffe95a',
      contrastText: '#325AE7',
    },
    info: {
      main: '#2959b3',
    },
    text: {
      primary: '#325AE7',
      secondary: '#1B1D29',
    },
  },
  typography: {
    fontFamily: 'Titillium Web',
    button: {
      fontWeight: 600,
      
    },
    allVariants: {
      fontSize: '16px',
      color:'#1B1D29',
      fontWeight: 'normal',
    },
    h1: {
      fontWeight: 700,
      fontSize: '26px',
      color: '#1B1D29',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.7rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.1rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
      },
      styleOverrides: {
        containedSecondary: {
          fontWeight: 700,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: '400',
          '&.Mui-error': {
            fontSize: '12px',
            marginLeft: 0,
          },
        },
      },
    },
  },
});

export default theme;
