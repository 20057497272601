/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, InputBase, PropTypes, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ISettingsData } from '../../../Features/settingSlice';


interface PropTypes {
    settingsData: ISettingsData;
}

const PaymentSettingsTab = ({ settingsData }: PropTypes) => {

    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                    }}>
                        <Typography variant='h6' sx={{
                            fontSize: '18px',
                            marginBottom: '20px',
                        }}>
                            Payment via Stripe
                        </Typography>
                        <Box sx={{
                            borderRadius: '10px',
                            border: '1px solid #AFBDFA',
                            background: '#fff',
                            display: 'flex',
                            padding: '20px',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            height: 'calc(100% - 40px)'
                        }}>
                            <Typography sx={{
                                maxWidth: '370px',
                                width: '100%',
                                marginRight: '20px',
                            }}>
                                We use Stripe to process payments. You can add your payment details below. We will process the invoices at the end of each month.
                            </Typography>
                            <a href="https://billing.stripe.com/p/login/6oE3cGbia72t6goaEE" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button sx={{
                                    backgroundColor: '#325AE7',
                                    borderRadius: '7px',
                                    border: '1px solid #325AE7',
                                    boxShadow: 'none',
                                    padding: '7px 19px',
                                    textTransform: 'inherit',
                                    '&:hover': {
                                        boxShadow: 'none',
                                        backgroundColor: '#325AE7',
                                    },
                                    '& img': {
                                        marginRight: '10px',
                                    }
                                }}>
                                    {settingsData.payment_details.last_digits ? "Manage Billing" : "Setup Payments"}
                                </Button>
                            </a>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                    }}>
                        <Typography variant='h6' sx={{
                            fontSize: '18px',
                            marginBottom: '20px',
                        }}>
                            Payment Details
                        </Typography>
                        <Box sx={{
                            borderRadius: '10px',
                            border: '1px solid #AFBDFA',
                            background: '#fff',
                            display: 'flex',
                            padding: '20px',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            height: 'calc(100% - 40px)'
                        }}>
                            <Typography sx={{
                                maxWidth: '350px',
                                width: '100%',
                                marginRight: '20px',
                            }}>
                                If any payment details are saved then they are shown below. If you want to change your payment method, you can override it by submitting the new details via Stripe.
                            </Typography>
                            <Box sx={{
                                minWidth: '250px',
                                '& input': {
                                    fontSize: '16px',
                                    color: '#525566',
                                    border: '1px solid #AFBDFA',
                                    width: '100%',
                                    padding: '11px 15px',
                                    background: '#ffffff',
                                    borderRadius: '7px',
                                }
                            }}>
                                <InputBase
                                    sx={{
                                        width: '100%',
                                    }}
                                    type='text'  // changed from 'password' to 'text' to display the last digits
                                    value={settingsData && settingsData.payment_details ? `**** **** **** ${settingsData.payment_details.last_digits}` : 'None'}
                                    disabled
                                />

                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PaymentSettingsTab;
