import { Box, Typography } from '@mui/material';
import * as React from 'react';
import moment from 'moment';
import { ILog } from '../../../Features/securitySlice';
import CheckIcons from '../../../Assets/Images/check-Icons.svg';
import { PageChangeHandler } from '../AuditSecurityTab';
import Loader from '../Loader';
import Pagination from '../../Pagination/Pagination';
import { IPaginationState } from '../../../Views/Security';

interface PropTypes {
	logs: ILog[];
	handlePageChange: PageChangeHandler;
	loadingLogs: boolean;
	isFetching: boolean;
	pagination: IPaginationState;
}

const AuditSecurityTabPanel = ({ logs, handlePageChange, loadingLogs, isFetching, pagination }: PropTypes) => {

	{
		if (loadingLogs || isFetching) {
			return <Loader />
		}
	}

	return (
		<>
			<Box sx={{
				borderRadius: '10px',
				border: '1px solid #AFBDFA',
				background: '#fff',
				'&> div + div': {
					borderTop: '1px solid #AFBDFA',
				}
			}}>
				{
					logs?.length > 0
						? logs.map((log, index) => (
							<Box key={index} sx={{
								padding: '16px 18px',
								display: 'flex',
								alignItems: 'center',
							}}>
								<Typography variant='h6' sx={{
									display: 'flex',
									paddingRight: '15px',
									alignItems: 'center',
									minWidth: '15%',
									maxWidth: '15%',
									wordBreak: 'break-all',
									'& img': {
										marginRight: '10px',
									}
								}}>
									{log.site_base_url}
								</Typography>
								<Typography variant='h6' sx={{
									paddingRight: '15px',
									minWidth: '10%',
									maxWidth: '10%',
									wordBreak: 'break-all',
								}}>
									{log.ip}
								</Typography>
								<Typography variant='h6' sx={{
									paddingRight: '15px',
									minWidth: '8%',
									maxWidth: '8%',
									wordBreak: 'break-all',
								}}>
									{log.user}
								</Typography>
								<Typography
									sx={{
										paddingRight: '15px',
										minWidth: '12%',
										maxWidth: '12%',
										wordBreak: 'break-all',
									}}
								>
									wpmonitoring.com
								</Typography>
								<Typography
									sx={{
										paddingRight: '15px',
										minWidth: '14%',
										maxWidth: '14%',
										wordBreak: 'break-all',
									}}
								>
									{log.event}
								</Typography>
								{/* <Typography sx={{paddingRight: '25px',}}><b>WordPress login</b> form with username "<b>gyantheboss</b>" successful</Typography> */}
								<Typography sx={{ 
									paddingRight: '15px',
									minWidth: '25%',
									maxWidth: '25%',
								 }}>
									{log.message}
								</Typography>
								{/* <Typography sx={{marginLeft: 'auto'}}>26-Feb-2022  |  03:15 pm</Typography> */}
								<Typography
									sx={{
										minWidth: '16%',
										maxWidth: '16%',
										wordBreak: 'break-all',
									}}
								>
									{moment(log.date).format('MMMM Do YYYY, h:mm:ss A')}
								</Typography>
							</Box>
						))
						: 
						<Box sx={{
							padding: '20px',
							textAlign: 'center',
						}}>
							<Typography>No Data Found</Typography>
						</Box>
				}
				<Box sx={{
					'& .pagination-item':{
						minWidth: '40px',
						justifyContent: 'center',
						border: '1px solid #325AE7',
						borderRadius: '5px',
						background: 'transparent !important',
						color: '#325AE7 !important',
					},
					'& .pagination-item.active':{
						background: '#315ae7 !important',
						color: '#fff !important',
					},
					'& .pagination-item .arrow::before':{
						borderColor: '#315ae7 !important',
					}
				}}>
					<Pagination
						handlePageChange={handlePageChange}
						totalCount={pagination.totalItems}
						currentPage={pagination.currentPage}
						pageSize={pagination.pageSize}
					/>
				</Box>
			</Box>
		</>
	)
}

export default AuditSecurityTabPanel