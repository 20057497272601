import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import {
  OpenUpdate,
  SchedulePluginsUpdateHandler,
  UpdatePluginsHandler,
} from "../OverviewTab";
import ErrorIcon from "@mui/icons-material/Error";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface PropTypes {
  update: OpenUpdate;
  handleUpdatePlugins: UpdatePluginsHandler;
  handleSchedulePluginsUpdate: SchedulePluginsUpdateHandler;
}

const OpenUpdatesCheckboxItem = ({
  update,
  handleUpdatePlugins,
  handleSchedulePluginsUpdate,
}: PropTypes) => {
  const [selectedSchedule, setSelectedSchedule] = React.useState(0);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "17px 18px 16px 18px",
          "@media (max-width: 1540px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ marginRight: "4" }}>
            <Checkbox {...label} sx={{ padding: 0, marginRight: "3px" }} />
            {update?.name}
          </Typography>
          {update?.error && update?.error_message && (
            <Box sx={{ ml: 1 }}>
              <Tooltip title={update?.error_message}>
                <ErrorIcon color="error" />
              </Tooltip>
            </Box>
          )}
        </Box>
        <Typography
          sx={{
            position: "relative",
            paddingLeft: "16px",
            marginLeft: "auto",
            "&::before": {
              content: '""',
              position: "absolute",
              width: "8px",
              height: "8px",
              borderRadius: "100px",
              background: "#2680EB",
              left: "0px",
              top: "9px",
            },
          }}
        >
          Update available
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingTop: "15px",
            width: "100%",
            "@media (max-width: 1540px)": {
              paddingLeft: "0px",
              width: "100%",
              marginTop: "10px",
              justifyContent: "space-between",
            },
          }}
        >
          {/* <CustomSelect 
						defaultValue={selectedSchedule}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedSchedule(Number((e.target as HTMLSelectElement).value))}
					>
						<StyledOption value={0}>Schedule Update</StyledOption>
						<StyledOption value={7}>Schedule for 1 week</StyledOption>
						<StyledOption value={14}>Schedule for 2 weeks</StyledOption>
						<StyledOption value={21}>Schedule for 3 weeks</StyledOption>
					</CustomSelect> */}
          <Select
            defaultValue={selectedSchedule}
            label="Schedule Update"
            sx={{
              // background: 'red',
              "& .MuiSelect-select": {
                boxSizing: "border-box",
                width: "100%",
                padding: "5px 12px",
                borderRadius: "7px",
                textAlign: "left",
                lineHeight: "1.5",
                background: "#ffffff",
                fontSize: "14px",
                minWidth: "210px",
                border: "1px solid #AFBDFA",
                color: "#1B1D29",
                fontFamily: "Titillium Web",
                fontWeight: "600",
                transitionProperty: "all",
                transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                transitionDuration: "120ms",
              },
              "& fieldset": {
                display: "none !important",
              },
            }}
            onChange={(event) =>
              setSelectedSchedule(Number(event.target.value))
            }
          >
            <MenuItem value={0}>Schedule Update</MenuItem>
            <MenuItem value={7}>Schedule for 1 week</MenuItem>
            <MenuItem value={14}>Schedule for 2 weeks</MenuItem>
            <MenuItem value={21}>Schedule for 3 weeks</MenuItem>
          </Select>
          <Button
            sx={{
              backgroundColor: "#325AE7",
              borderRadius: "7px",
              border: "1px solid #325AE7",
              boxShadow: "none",
              whiteSpace: "nowrap",
              padding: "3px 14px",
              marginLeft: "12px",
              fontSize: "14px",
              textTransform: "inherit",
              minHeight: "inherit",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "#325AE7",
              },
            }}
            onClick={() => {
              if (selectedSchedule === 0) {
                handleUpdatePlugins(update?.name);
              } else {
                handleSchedulePluginsUpdate(update?.name, selectedSchedule);
              }
            }}
          >
            {selectedSchedule === 0 ? "Update Now" : "Schedule Update"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OpenUpdatesCheckboxItem;
