import * as React from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import './style.css'
import Plusicon from '../../Assets/Images/plus-icon.svg';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GeneralSecurityTab from '../../Components/UI/GeneralSecurityTab';
import AuditSecurityTab from '../../Components/UI/AuditSecurityTab';
import BlackSecurityTab from '../../Components/UI/BlackSecurityTab';
import { defaultSecurityLogs, defaultSecurityOverviewData, defaultWhitelistedIPs, useGetSecurityLogsQuery, useGetSecurityOverviewQuery, useGetWhitelistedIpsQuery } from '../../Features/securitySlice';
import Loader from '../../Components/UI/Loader';
type Anchor = 'left';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface IPaginationState {
  currentPage: number;
  pageSize: number;
  totalItems: number;
}

export const initialPaginationState: IPaginationState = {
  currentPage: 1,
  pageSize: 10,
  totalItems: 0,
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Security = () => {

  const [value1, setValue1] = React.useState(0);
  const [pagination, setPagination] = React.useState<IPaginationState>(initialPaginationState);
  const [event, setEvent] = React.useState('');
  const [query, setQuery] = React.useState('');

  console.log("Pagination", pagination);
  console.log("Event ", event);
  console.log("Query ", query);

  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };

  const { data: securityData = defaultSecurityOverviewData, isLoading } = useGetSecurityOverviewQuery();
  const { data: securityLogs = defaultSecurityLogs, isLoading: loadingLogs, isFetching, refetch } = useGetSecurityLogsQuery(query);
  const { data: whitelistedIps = defaultWhitelistedIPs, isLoading: fetchingIps, isFetching: reloadingIps } = useGetWhitelistedIpsQuery();

  console.log("Security Overview Data >>>>>>>>> ", securityData);
  console.log("Security Logs Data >>>>>>>>> ", securityLogs);
  console.log("Whitelisted IPs >>>>>>>>> ", whitelistedIps);

  React.useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      totalItems: securityLogs.count,
    }));
  }, [securityLogs.count]);
  
  const handlePageChange = (pageNumber: number) => {
    setPagination((prevState) => ({
      ...prevState,
      currentPage: pageNumber,
    }));
    if (event) {
      setQuery(`page=${pagination.currentPage}&event=${event}`);
      refetch();
    } else {
      setQuery(`page=${pagination.currentPage}`);
      refetch();
    }
  };
  
  const handleEventChange = (event: string) => {
    setEvent(event);
    setPagination((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    if (event) {
      setQuery(`page=${pagination.currentPage}&event=${event}`);
      refetch();
    } else {
      setQuery(`page=${pagination.currentPage}`);
      refetch();
    }
  }

  {
    if (isLoading) {
      return <Loader />
    }
  }

  return (
    <Box>
      <Box sx={{
        padding: '20px 40px',
        borderBottom: '1px solid #AFBDFA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#FAFBFF',
        position: 'sticky',
        top: '0',
        zIndex: '2',
        '@media (max-width: 1400px)': {
          padding: '20px 20px',
        },
        '@media (max-width: 1199px)': {
          position: 'relative',
        },
        '@media (max-width: 767px)': {
          display: 'block'
        },
      }}>
        <Typography
          variant="h1"
          sx={{
            '@media (max-width: 767px)': {
              marginBottom: '15px',
            },
          }}
        >Security</Typography>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          '@media (max-width: 400px)': {
            display: 'block',
          },
        }}>
          <Box sx={{
            marginLeft: '0',
            '@media (max-width: 400px)': {
              marginLeft: '0',
              marginTop: '15px',
            },
          }}>
            <Button sx={{
              backgroundColor: '#325AE7',
              borderRadius: '7px',
              border: '1px solid #325AE7',
              boxShadow: 'none',
              padding: '7px 19px',
              fontSize: '15px',
              textTransform: 'inherit',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#325AE7',
              },
              '& img': {
                marginRight: '10px',
              },
              '& a': {
                color: '#ffffff',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
              }
            }}>
              <Link to="/add/">
                <img src={Plusicon} />
                Add a website
              </Link>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        '@media (max-width: 1199px)': {
            display: 'block'
          },
      }}>
        <Box sx={{
          minWidth: '240px',
          minHeight: 'calc(100vh - 184px)',
          borderRight: '1px solid #AFBDFA',
          paddingLeft: '40px',
          paddingTop: '20px',
        //   position: 'sticky',
        //   top: '0px',
          '@media (max-width: 1199px)': {
            minHeight: 'auto',
            paddingLeft: '20px',
            paddingBottom: '20px',
            borderBottom: '1px solid #AFBDFA',
          },
        }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value1}
            onChange={handleChange1}
            aria-label="Vertical tabs example"
            sx={{
              position: 'sticky',
              top: '103px',
              '& .MuiTabs-indicator': {
                right: 'auto',
                left: 0,
              },
              '& button': {
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'capitalize',
                minHeight: 'auto',
                textAlign: 'left !important',
                minWidth: 'auto',
                justifyContent: 'flex-end !important',
                alignItems: 'flex-start !important',
                borderLeft: '2px solid #fff',
                padding: '6px 0 6px 20px',
              },
            }}
          >
            <Tab label="General" {...a11yProps(0)} />
            <Tab label="Audit Trails" {...a11yProps(1)} />
            <Tab label="Black/White list IPs" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <Box sx={{
          width: '100%',
        }}>
          <TabPanel value={value1} index={0}>
            <GeneralSecurityTab 
              statistics={securityData?.statistics} 
              top_sites={securityData?.top_sites} 
              top_countries={securityData?.top_countries}
              all_countries={securityData?.all_countries}
              latest_scans={securityData?.latest_scans}
            />
          </TabPanel>
          <TabPanel value={value1} index={1}>
            <AuditSecurityTab 
              logs={securityLogs?.results}
              handlePageChange={handlePageChange}
              handleEventChange={handleEventChange}
              loadingLogs={loadingLogs}
              isFetching={isFetching}
              pagination={pagination}
            />
          </TabPanel>
          <TabPanel value={value1} index={2}>
            <BlackSecurityTab whitelistedIps={whitelistedIps} fetchingIps={fetchingIps} />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default Security;
