/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { IUpdateData } from '../../../Views/Dashboard';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface PropTypes {
    updates: IUpdateData[];
}

const OpenUpdatesWordpress = ({ updates }: PropTypes) => {
    return (

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{
                    borderRadius: '10px',
                    border: '1px solid #AFBDFA',
                    background: '#fff',
                    overflow: 'hidden',
                    maxHeight: '364px',
                    minHeight: '364px',
                    overflowY: 'scroll',
                    '& > div + div': {
                        borderTop: '1px solid #AFBDFA',
                    }
                }}>
                    {
                        updates?.length > 0
                            ? updates.map(update => (
                                update?.sites &&
                                update.sites.map((site, index) => (
                                    <Box key={index} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '17px 18px 16px 18px',
                                    }}>
                                        <Typography variant='h6'><Checkbox {...label} sx={{ padding: 0, marginRight: '3px' }} /> {site.name}</Typography>
                                        <Typography>
                                            {site.site_base_url}
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: '10px',
                                            '& p + p': {
                                                marginLeft: '15px',
                                            }
                                        }}>
                                            <Typography>
                                                From: {site?.current_version ?? site?.old_version}
                                            </Typography>
                                            <Typography>
                                                To: {site.new_version}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))
                            ))
                            : <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                maxHeight: '362px',
                                minHeight: '362px',
                                justifyContent: 'center',
                            }}>
                                <Typography>No Recent Updates Found</Typography>
                            </Box>
                    }
                </Box>
            </Grid>
        </Grid>

    );
};

export default OpenUpdatesWordpress;
