import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Checkbox,
  Grid,
  InputBase,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Uptime from "../../Assets/Images/uptime.svg";
import Update from "../../Assets/Images/update.svg";
import Backup from "../../Assets/Images/backup.svg";
// import Attentions from '../../Assets/Images/attentions-icon.svg'
import { Link, useNavigate } from "react-router-dom";
import Plusicon from "../../Assets/Images/plus-icon.svg";
import redInfo from "../../Assets/Images/red-info.svg";
import Tooltip from "@mui/material/Tooltip";

import Info from "../../Assets/Images/info-icon.svg";
import controlSlider from "../../Assets/Images/control-slider.svg";
import Attentions from "../../Assets/Images/attentions.svg";
import OptimizationSpam from "../../Components/UI/OptimizationSpam";
import OptimizationRevisions from "../../Components/UI/OptimizationRevisions";
import {
  initialDashboardData,
  useGetDashboardStatsQuery,
} from "../../Features/dashboardSlice";
import Loader from "../../Components/UI/Loader";
import {
  useRunMalwareScanMutation,
  useUpdatePluginsMutation,
  useUpdateThemeMutation,
  useUpdateWordpressMutation,
  useOptimizationTrackMutation,
} from "../../Features/siteSlice";
import Swal from "sweetalert2";
import DashboardUpdateSection from "../../Components/UI/DashboardUpdateSection";

import {
  AttentionsContent,
  DowntimeContent,
  UpdatesContent,
  BackupsContent,
} from "./contentSections";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface SiteData {
  name: string;
  site_base_url: string;
  new_version: string;
  current_version?: string;
  old_version?: string;
  error?: boolean;
  error_message?: string | null;
  type: string;
  date?: string;
}

export interface IUpdateData {
  name: string;
  update_type: string;
  sites: SiteData[];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (event: React.SyntheticEvent) => setOpen(true);
  const handleClose = (event: React.SyntheticEvent) => setOpen(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [activeSection, setActiveSection] = React.useState("attentions");

  const [value1, setValue1] = React.useState(0);
  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };
  const [value2, setValue2] = React.useState(0);
  const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setValue2(newValue);
  };

  const handleSectionClick = (sectionName: string) => {
    setActiveSection(sectionName);
  };

  const handleRedirect = (url: string) => {
    navigate(`/sites/${url}/`);
  };
  const [checkedUrls, setCheckedUrls] = React.useState<string[]>([]);
  const [optimizationTrack] = useOptimizationTrackMutation();

  const [selectedUpdates, setSelectedUpdates] = React.useState<SiteData[]>([]);
  const navigate = useNavigate();

  const {
    data: dashboardData = initialDashboardData,
    isLoading,
    refetch,
  } = useGetDashboardStatsQuery();
  const [runMalwareScan, { isLoading: scanningMalware }] =
    useRunMalwareScanMutation();
  const [updatePlugins, { isLoading: updatingPlugins }] =
    useUpdatePluginsMutation();
  const [updateTheme, { isLoading: updatingTheme }] = useUpdateThemeMutation();
  const [updateWordpress, { isLoading: updatingWP }] =
    useUpdateWordpressMutation();

  const getSectionStyle = (sectionName: string) => ({
    borderRadius: "10px",
    border: "1px solid #AFBDFA",
    padding: "18px",
    display: "flex",
    alignItems: "center",
    backgroundColor: activeSection === sectionName ? "#F4F5FE" : "#fff", // change the background based on active section
    cursor: "pointer",
    "&:hover": {
      background: "#F4F5FE",
    },
    "& img": {
      width: "60px",
    },
  });

  const handleCheckboxChange = (checked: boolean, baseUrl: string) => {
    setCheckedUrls((currentCheckedUrls) =>
      checked
        ? [...currentCheckedUrls, baseUrl]
        : currentCheckedUrls.filter((url) => url !== baseUrl)
    );
  };

  const sendOptimizationData = () => {
    // Send a separate request for each checked URL
    checkedUrls.forEach((url) => {
      optimizationTrack(url)
        .unwrap()
        .then((payload) => {
          console.log(`Optimization successful for ${url}`, payload);
        })
        .catch((error) => {
          console.error(`Error in optimizing ${url}:`, error);
        });
    });

    // Optionally, clear the checked URLs after sending the requests
    setCheckedUrls([]);
  };

  const updatesTypeCount = (type: string, updates: IUpdateData[]) => {
    const lowerType = type.toLowerCase();

    // Count only updates without errors for 'Plugin', 'Theme', and 'Core'
    if (lowerType === "plugin" || lowerType === "theme" || lowerType === "wp") {
      return updates
        .filter((update) => update.update_type.toLowerCase() === lowerType)
        .flatMap((update) => update.sites)
        .filter((site) => !site.error).length; // Exclude sites with errors
    }
    // Count all updates for 'All', excluding failed ones
    else if (lowerType === "all") {
      return updates
        .flatMap((update) => update.sites)
        .filter(
          (site) =>
            !site.error &&
            (site.type.toLowerCase() === "plugin" ||
              site.type.toLowerCase() === "theme" ||
              site.type.toLowerCase() === "wp")
        ).length;
    }
    // Count only updates with errors for 'Failed'
    else if (lowerType === "failed") {
      return updates
        .flatMap((update) => update.sites)
        .filter((site) => site.error).length; // Include only sites with errors
    }
    return 0; // Return 0 by default if none of the above types match
  };

  const sectionContent = () => {
    switch (activeSection) {
      case "attentions":
        return <AttentionsContent data={dashboardData.attentions} />;
      case "downtime":
        return <DowntimeContent data={dashboardData.downtimes} />;
      case "update":
        return <UpdatesContent data={dashboardData.updates} />;
      case "backup":
        return <BackupsContent data={dashboardData.backups} />;
      default:
        return <AttentionsContent data={dashboardData.attentions} />;
    }
  };

  const filteredUpdates = (type: string, updates: IUpdateData[]) => {
    const lowerType = type.toLowerCase();
    if (lowerType === "failed") {
      // Return only updates where an error exists
      return updates
        .map((update) => ({
          ...update,
          sites: update.sites.filter(
            (site) =>
              site.error &&
              site.type.toLowerCase() === update.update_type.toLowerCase()
          ),
        }))
        .filter((update) => update.sites.length > 0);
    } else if (lowerType === "all") {
      // Return all updates excluding the ones with errors
      return updates
        .map((update) => ({
          ...update,
          sites: update.sites.filter((site) => !site.error),
        }))
        .filter((update) => update.sites.length > 0);
    } else {
      // Return updates excluding the ones with errors
      return updates
        .filter((update) => update.update_type.toLowerCase() === lowerType)
        .map((update) => ({
          ...update,
          sites: update.sites.filter((site) => !site.error),
        }))
        .filter((update) => update.sites.length > 0);
    }
  };

  // Highlight style for the active section
  const activeStyle = {
    backgroundColor: "#F4F5FE", // Highlight color
  };

  // Function to determine if a section is active based on the current activeSection state
  const isActive = (sectionName: string) => {
    return activeSection === sectionName ? activeStyle : {};
  };

  const handleRunMalwareScan = async (base_url: string) => {
    Swal.fire({
      title: `Run malware scan for ${base_url}?`,
      showCancelButton: true,
      confirmButtonText: "Scan Now!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await runMalwareScan(base_url);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: `Malware Scan Failed`,
            text: `Failed to scan malware on ${base_url}. Please try again later.`,
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: `Malware Scan Successful`,
          text: `The url ${base_url} was successfully scheduled for a malware scan! Come back in about 15-20 minutes to see the result.`,
        });
      }
    });
  };

  const performOpenUpdates = async (
    type: string,
    selectedUpdates: SiteData[]
  ) => {
    if (selectedUpdates.length === 0) {
      Swal.fire(
        "No Updates Selected",
        "Please select at least one open update to proceed",
        "warning"
      );
    } else {
      Swal.fire({
        title: `Perform Bulk Updates for selected ${type}(s)?`,
        showCancelButton: true,
        confirmButtonText: "Update Now!",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            if (type === "Plugin") {
              for (const update of selectedUpdates) {
                const { site_base_url, name } = update;
                await updatePlugins({
                  website_url: site_base_url,
                  plugins: name,
                });
              }
            } else if (type === "Theme") {
              for (const update of selectedUpdates) {
                const { site_base_url, name } = update;
                await updateTheme({
                  website_url: site_base_url,
                  theme_name: name,
                });
              }
            } else if (type === "Core") {
              for (const update of selectedUpdates) {
                const { site_base_url } = update;
                await updateWordpress(site_base_url);
              }
            }
            refetch();
          } catch (error) {
            Swal.fire(
              "Erro Performing Updates",
              "Something went wrong. Please try again later.",
              "error"
            );
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: `Bulk Updates Successful`,
            text: `The bulk updates for ${type}s was completed successfully!`,
          });
        }
      });
    }
  };

  {
    if (isLoading) {
      return <Loader />;
    }
  }

  return (
    <Box>
      <Box
        sx={{
          padding: "20px 40px",
          borderBottom: "1px solid #AFBDFA",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#FAFBFF",
          position: "sticky",
          top: "0",
          zIndex: "2",
          "@media (max-width: 1400px)": {
            padding: "20px 20px",
          },
          "@media (max-width: 1199px)": {
            position: "relative",
          },
          "@media (max-width: 767px)": {
            display: "block",
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            "@media (max-width: 767px)": {
              marginBottom: "15px",
            },
          }}
        >
          Dashboard{" "}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "@media (max-width: 400px)": {
              display: "block",
            },
          }}
        >
          <Box
            sx={{
              "@media (max-width: 400px)": {
                marginLeft: "0",
                marginTop: "15px",
              },
            }}
          >
            <Button
              onClick={handleOpen}
              sx={{
                backgroundColor: "#325AE7",
                borderRadius: "7px",
                border: "1px solid #325AE7",
                boxShadow: "none",
                padding: "7px 19px",
                fontSize: "15px",
                textTransform: "inherit",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#325AE7",
                },
                "& img": {
                  marginRight: "10px",
                },
                "& a": {
                  color: "#ffffff",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <Link to="/add/">
                <img src={Plusicon} />
                Add a website
              </Link>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "25px 40px",
          "@media (max-width: 1400px)": {
            padding: "25px 20px",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item lg={7} xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                marginBottom: "6px",
              }}
            >
              Quick Overview (last 14 days)
              <Tooltip title="See the activity of WP Monitoring over the last 14 days.">
                <img src={Info} alt="Info" style={{ marginLeft: "5px" }} />
              </Tooltip>
            </Typography>

            <Box>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  onClick={() => setActiveSection("attentions")}
                  style={isActive("attentions")}
                >
                  <Box sx={getSectionStyle("downtime")}>
                    <img src={Attentions} alt="Attentions" />
                    <Typography>Attentions</Typography>
                  </Box>
                </Grid>
                {/* Downtime Section */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  onClick={() => setActiveSection("downtime")}
                  style={isActive("downtime")}
                >
                  <Box sx={getSectionStyle("downtime")}>
                    <img src={Uptime} alt="Downtime" />
                    <Typography>Downtime Count</Typography>
                  </Box>
                </Grid>
                {/* Updates Section */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  onClick={() => setActiveSection("update")}
                  style={isActive("update")}
                >
                  <Box sx={getSectionStyle("update")}>
                    <img src={Update} alt="update" />
                    <Typography>Update</Typography>
                  </Box>
                </Grid>
                {/* Backups Section */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  onClick={() => setActiveSection("backup")}
                  style={isActive("backups")}
                >
                  <Box sx={getSectionStyle("backup")}>
                    <img src={Backup} alt="Backups" />
                    <Typography>Backup</Typography>
                  </Box>
                </Grid>
              </Grid>
              {sectionContent()}
            </Box>
          </Grid>
          <DashboardUpdateSection
            updates={dashboardData.open_updates}
            updatesTypeCount={updatesTypeCount}
            filteredUpdates={filteredUpdates}
            performOpenUpdates={performOpenUpdates}
            updateType="openUpdates"
          />
          <Grid item lg={7} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={5} sm={6} xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "18px",
                    marginBottom: "20px",
                  }}
                >
                  Security Center
                  <Tooltip title="See the Latest Security scan results here.">
                    <img src={Info} alt="Info" style={{ marginLeft: "5px" }} />
                  </Tooltip>
                </Typography>
                <Box
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid #AFBDFA",
                    background: "#fff",
                    overflow: "hidden",
                    maxHeight: "380px",
                    minHeight: "380px",
                    overflowY: "scroll",
                    "& > div + div": {
                      borderTop: "1px solid #AFBDFA",
                    },
                  }}
                >
                  {dashboardData?.recent_security_issues &&
                    dashboardData?.recent_security_issues.map(
                      (security_issue, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            padding: "11px 18px 11px 18px",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              margin: "5px 10px 5px 0",
                              "& img": {
                                width: "15px",
                                marginRight: "10px",
                              },
                            }}
                          >
                            <img src={redInfo} />
                            <Box>
                              <Typography variant="h6">
                                {security_issue.site_name}
                              </Typography>
                              <Typography>
                                {security_issue.site_base_url}
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            sx={{
                              backgroundColor: "#325AE7",
                              borderRadius: "7px",
                              border: "1px solid #325AE7",
                              boxShadow: "none",
                              padding: "3px 14px",
                              fontSize: "14px",
                              textTransform: "inherit",
                              minHeight: "inherit",
                              "&:hover": {
                                boxShadow: "none",
                                backgroundColor: "#325AE7",
                              },
                            }}
                            onClick={() =>
                              handleRedirect(security_issue.site_base_url)
                            }
                          >
                            View site
                          </Button>
                        </Box>
                      )
                    )}
                </Box>
              </Grid>
              <Grid item md={7} sm={6} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      width: "100%",
                    }}
                  >
                    Optimization
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      margin: "5px 0",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: " 7px",
                        border: "1px solid #AFBDFA",
                        background: "#fff",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          borderRight: "1px solid #AFBDFA",
                          width: "43px",
                          height: "43px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={controlSlider} />
                      </Box>
                      <Tabs
                        value={value2}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        onChange={handleChange2}
                        sx={{
                          minHeight: "auto",
                          padding: "0 20px",
                          "& .MuiTabs-indicator": {
                            display: "none",
                          },
                          "& button": {
                            padding: "0",
                            textTransform: "capitalize",
                            minHeight: "auto",
                            minWidth: "auto",
                          },
                          "& button + button": {
                            borderLeft: "1px solid #AFBDFA  ",
                            marginLeft: "10px",
                            paddingLeft: "10px",
                          },
                        }}
                      >
                        <Tab
                          label={`Optimizations ${dashboardData?.optimizations.length}`}
                          {...a11yProps(0)}
                        />
                      </Tabs>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        margin: "5px 0",
                      }}
                    ></Box>
                  </Box>
                  <Button
                    sx={{
                      backgroundColor: "#325AE7",
                      borderRadius: "7px",
                      border: "1px solid #325AE7",
                      boxShadow: "none",
                      padding: "8px 19px",
                      minHeight: "45px",
                      fontSize: "15px",
                      textTransform: "inherit",
                      "&.Mui-disabled": {
                        border: "1px solid #707070",
                        color: "#ffffff",
                        background: "#707070",
                      },
                      "&:hover": {
                        boxShadow: "none",
                        backgroundColor: "#325AE7",
                      },
                      "& img": {
                        marginRight: "10px",
                      },
                    }}
                    onClick={sendOptimizationData}
                    disabled={checkedUrls.length === 0} // Button is disabled if no URLs are checked
                  >
                    Optimize
                  </Button>
                </Box>
                <TabPanel value={value2} index={0}>
                  <OptimizationSpam
                    optimizations={dashboardData?.optimizations}
                    onCheckboxChange={handleCheckboxChange}
                    selectedUrls={checkedUrls} // Pass selectedUrls as a prop to Optimization
                  />
                </TabPanel>
              </Grid>
            </Grid>
            {/* <SecurityCenter /> */}
          </Grid>
          <DashboardUpdateSection
            updates={dashboardData.recent_updates}
            updatesTypeCount={updatesTypeCount}
            filteredUpdates={filteredUpdates}
            performOpenUpdates={performOpenUpdates}
            updateType="recentUpdates"
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
