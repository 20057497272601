import * as React from 'react';
import { Button, Checkbox, IconButton, InputBase, Radio, RadioGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import SearchIcon from '@mui/icons-material/Search';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '../../Assets/Images/slider.svg'
import { Link } from 'react-router-dom';
import CrossIconLight from '../../Assets/Images/cross-icon-light.svg'
import './style.css'
import Plusicon from '../../Assets/Images/plus-icon.svg';
import { useGetSitesQuery, useSetFavoriteMutation } from '../../Features/siteSlice';

import WebsiteItem from '../../Components/UI/Overview/WebsiteItem';
import Loader from '../../Components/UI/Loader';
import Swal from 'sweetalert2';

type Anchor = 'left';
const Overview = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [favoriteFilter, setFavoriteFilter] = React.useState('all');
  const [statusFilter, setStatusFilter] = React.useState('');
  const [sortFilter, setSortFilter] = React.useState('aToZ');
  const [labelFilter, setLabelFilter] = React.useState<string[]>([]);
  const [enteredLabel, setEnteredLabel] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState('');

  const handleFavoriteFilterChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setFavoriteFilter(event.target.value);
  };

  const handleSortFilterChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSortFilter(event.target.value);
  };

  const handleStatusFilterChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setStatusFilter(event.target.value);
  };

  const handleLabelFilterAdd = () => {
    if (enteredLabel.trim() !== '') {
      setLabelFilter([...labelFilter, enteredLabel.trim()]);
      setEnteredLabel('');
    }
  };

  const handleLabelFilterRemove = (label: string) => {
    const updatedFilter = labelFilter.filter((item) => item !== label);
    setLabelFilter(updatedFilter);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const { data: sitesData, isLoading, refetch } = useGetSitesQuery();
  const [setFavorite] = useSetFavoriteMutation();

  const filteredSitesData = () => {
    if (!sitesData?.results) return [];

    let allSitesData = [...sitesData.results];

    if (favoriteFilter === 'favorite') {
      allSitesData = allSitesData.filter((site) => site.favorite);
    }



    if (statusFilter) {
      if (statusFilter === 'inactive') {
        allSitesData = allSitesData.filter((site) => site.active === false);
      } 

      else {
        const lowerCaseStatusFilter = statusFilter.toLowerCase();
        allSitesData = allSitesData.filter((site) => site.status.toLowerCase() === lowerCaseStatusFilter);
      }
    }  

    if (sortFilter === 'aToZ') {
      allSitesData.sort((a, b) => {
        const titleA = a.title || a.base_url;  // Use 'base_url' as a fallback
        const titleB = b.title || b.base_url;  // Use 'base_url' as a fallback
        return titleA.localeCompare(titleB);
      });
    } else if (sortFilter === 'zToA') {
      allSitesData.sort((a, b) => {
        const titleA = a.title || a.base_url;  // Use 'base_url' as a fallback
        const titleB = b.title || b.base_url;  // Use 'base_url' as a fallback
        return titleB.localeCompare(titleA);
      });
    } else if (sortFilter === 'newestFirst') {
      allSitesData.sort((a, b) => new Date(b.date_added).getTime() - new Date(a.date_added).getTime());
    } else if (sortFilter === 'oldestFirst') {
      allSitesData.sort((a, b) => new Date(a.date_added).getTime() - new Date(b.date_added).getTime());
    }

    if (labelFilter.length > 0) {
      allSitesData = allSitesData.filter((site) => {
        if (site.labels) {
          return labelFilter.some((label) => site.labels.toLowerCase() === label.toLowerCase());
        }
        return false;
      });
    }

    if (searchQuery) {
      // Adding a null check for 'searchQuery' before calling 'toLowerCase'
      const lowerCaseSearchQuery = searchQuery ? searchQuery.toLowerCase() : '';
      allSitesData = allSitesData.filter((site) =>
        // Adding null checks for 'site.title' and 'site.url' before calling 'toLowerCase' and 'includes'
        (site.title && site.title.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (site.url && site.url.toLowerCase().includes(lowerCaseSearchQuery))
      );
    }


    return allSitesData;
  }

  const filteredSites = React.useMemo(() => filteredSitesData(), [sitesData, favoriteFilter, statusFilter, sortFilter, labelFilter, searchQuery]);

  const handleSetFavorite = async (website_url: string) => {
    try {
      await setFavorite(website_url);
      Swal
        .fire({
          icon: 'success',
          title: 'Success',
          text: "Action completed!",
          showCancelButton: false,
          confirmButtonColor: '#325AE7',
          confirmButtonText: 'OK'
        })
      refetch();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `Adding Website to Favorite List Failed`,
        text: `Failed to add ${website_url} to favorite list. Please try again later.`
      })
    }
  }

  {
    if (isLoading) {
      return <Loader />
    }
  }

  return (
    <Box>
      <Box sx={{
        padding: '20px 40px',
        borderBottom: '1px solid #AFBDFA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#FAFBFF',
        position: 'sticky',
        top: '0',
        zIndex: '2',
        '@media (max-width: 1400px)': {
          padding: '20px 20px',
        },
        '@media (max-width: 1199px)': {
          position: 'relative',
        },
        '@media (max-width: 767px)': {
          display: 'block'
        },
      }}>
        <Typography
          variant="h1"
          sx={{
            '@media (max-width: 767px)': {
              marginBottom: '15px',
            },
          }}
        >Website Overview</Typography>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          '@media (max-width: 400px)': {
            display: 'block',
          },
        }}>
          <Box sx={{
            marginLeft: '0',
            display: 'flex',
            '@media (max-width: 400px)': {
              marginLeft: '0',
              marginTop: '15px',
            },
          }}>
            <Box sx={{
              padding: '0',
              backgroundColor: '#ffffff',
              borderRadius: '5px',
              border: '1px solid #AFBDFA',
              overflow: 'hidden',
              display: 'flex',
              marginRight: '15px',
              alignItems: 'center',
              '@media (max-width: 1199px)': {
                display: 'none',
              },
            }}>
              <IconButton
                type="button"
                aria-label="search"
                sx={{
                  padding: '9px 10px',
                  color: '#325AE7',
                  borderRadius: ' 0',
                  borderRight: '1px solid #AFBDFA',
                  backgroundColor: '#ffffff',
                }}
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{
                  fontSize: '16px',
                  color: '#525566',
                  minWidth: '280px',
                  '& input': {
                    padding: '0 15px',
                  }
                }}
                placeholder="Search website"
                type='search'
                inputProps={{ 'aria-label': 'Search website' }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />

            </Box>
            <Button sx={{
              backgroundColor: '#325AE7',
              borderRadius: '7px',
              border: '1px solid #325AE7',
              boxShadow: 'none',
              padding: '7px 19px',
              fontSize: '15px',
              textTransform: 'inherit',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#325AE7',
              },
              '& img': {
                marginRight: '10px',
              },
              '& a': {
                color: '#ffffff',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
              }
            }}>
              <Link to="/add/">
                <img src={Plusicon} />
                Add a website
              </Link>
            </Button>
            {(['left',] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <Button onClick={toggleDrawer(anchor, true)} sx={{
                  backgroundColor: '#325AE7',
                  borderRadius: '7px',
                  border: '1px solid #325AE7',
                  boxShadow: 'none',
                  padding: '7px 19px',
                  // display: 'none',
                  display: 'inline-flex',
                  marginLeft: '10px',
                  textTransform: 'inherit',
                  '@media (max-width: 1199px)': {
                    display: 'inline-flex',
                  },
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#325AE7',
                  },
                  '& img': {
                    marginRight: '10px',
                    filter: 'brightness(0) invert(1)',
                  }
                }}>
                  <img src={Slider} />
                  Filters
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  sx={{
                    zIndex: '9999'
                  }}
                >
                  <Box sx={{
                    minWidth: '240px',
                    borderRight: '1px solid #AFBDFA',
                    maxHeight: '100vh',
                    minHeight: '100vh',
                    overflowY: 'scroll',
                    position: 'sticky',
                    top: '85px',
                    '@media (max-width: 1199px)': {
                      top: '0',
                      maxHeight: 'calc(100vh - 100px)',
                      // display: 'none',
                    },
                  }}>
                    <Typography
                      variant='h5'
                      sx={{
                        fontSize: '18px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '20px 40px',
                        borderBottom: '1px solid #AFBDFA',
                        '& img': {
                          marginRight: '20px',
                        }
                      }}
                    >
                      <img src={Slider} />
                      Filters
                    </Typography>
                    <Box>
                      <Box sx={{
                        padding: '20px 40px',
                        borderBottom: '1px solid #AFBDFA',
                      }}>
                        <Typography
                          variant='h6'
                          sx={{
                            marginBottom: '15px'
                          }}
                        >
                          View
                        </Typography>
                        <RadioGroup
                          value={favoriteFilter}
                          onChange={handleFavoriteFilterChange}
                          sx={{
                            '& .MuiFormControlLabel-root': {
                              marginBottom: '10px',
                            },
                          }}
                        >
                          <FormControlLabel value="all" control={<Radio />} label="All" />
                          <FormControlLabel value="favorite" control={<Radio />} label="Favorite" />
                        </RadioGroup>
                      </Box>
                      <Box sx={{
                        padding: '20px 40px',
                        borderBottom: '1px solid #AFBDFA',
                      }}>
                        <Typography
                          variant='h6'
                          sx={{
                            marginBottom: '15px'
                          }}
                        >
                          Sort
                        </Typography>
                        <RadioGroup
                          value={sortFilter}
                          onChange={handleSortFilterChange}
                          sx={{
                            '& .MuiFormControlLabel-root': {
                              marginBottom: '10px',
                            },
                          }}
                        >
                          <FormControlLabel value="aToZ" control={<Radio />} label="A to Z" />
                          <FormControlLabel value="zToA" control={<Radio />} label="Z to A" />
                          <FormControlLabel value="newestFirst" control={<Radio />} label="Newest first" />
                          <FormControlLabel value="oldestFirst" control={<Radio />} label="Oldest first" />
                        </RadioGroup>
                      </Box>
                      <Box sx={{
                        padding: '20px 40px',
                        borderBottom: '1px solid #AFBDFA',
                      }}>
                        <Typography
                          variant='h6'
                          sx={{
                            marginBottom: '15px'
                          }}
                        >
                          Status
                        </Typography>
                        <RadioGroup
                          value={statusFilter}
                          onChange={handleStatusFilterChange}
                          sx={{
                            '& .MuiFormControlLabel-root': {
                              marginBottom: '10px',
                            },
                          }}
                        >
                          <FormControlLabel value="" control={<Radio />} label="All" />
                          <FormControlLabel value="OK" control={<Radio />} label="Good" />
                          <FormControlLabel value="NC" control={<Radio />} label="No Connection" />
                          <FormControlLabel value="OFF" control={<Radio />} label="Offline" />
                          <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                        </RadioGroup>
                      </Box>
                      <Box sx={{
                        padding: '20px 40px',
                        borderBottom: '1px solid #AFBDFA',
                      }}>
                        <Typography
                          variant='h6'
                          sx={{
                            marginBottom: '15px'
                          }}
                        >
                          Labels
                        </Typography>
                        <FormGroup sx={{
                          '& .MuiCheckbox-root': {
                            padding: 0,
                            marginRight: '10px',
                          },
                          '& label': {
                            margin: 0,
                          },
                          '&>div + div': {
                            marginTop: '10px',
                          },
                        }}>
                          {labelFilter.map((label) => (
                            <Box
                              key={label}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& img': {
                                  marginLeft: 'auto',
                                  cursor: 'pointer',
                                },
                              }}
                            >
                              <FormControlLabel control={<Checkbox />} label={label} />
                              <img
                                src={CrossIconLight}
                                onClick={() => handleLabelFilterRemove(label)}
                              />
                            </Box>
                          ))}
                        </FormGroup>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '10px',
                        }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            label={
                              <InputBase
                                sx={{
                                  fontSize: '16px',
                                  color: '#525566',
                                  width: '100%',
                                  marginLeft: '0',
                                  '& input': {
                                    padding: '',
                                    borderBottom: '1px solid #707070'
                                  }
                                }}
                                type="text"
                                placeholder="new labels here"
                                value={enteredLabel}
                                onChange={(e) => setEnteredLabel(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === ' ' || e.key === 'Enter') {
                                    e.preventDefault();
                                    handleLabelFilterAdd();
                                  }
                                }}
                                inputProps={{ 'aria-label': 'new labels here' }}
                              />
                            }
                          />
                          <Button
                            variant="contained"
                            size="small"
                            sx={{ marginLeft: '10px' }}
                            onClick={handleLabelFilterAdd}
                          >
                            Add
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'flex-start',
        '@media (max-width: 1400px)': {
        },
      }}>
        <Box sx={{
          padding: '20px 40px',
          width: '100%',
          '@media (max-width: 1400px)': {
            padding: '25px 20px',
          },
        }}>
          <Box sx={{
            borderRadius: '10px',
            border: '1px solid #AFBDFA',
            background: '#fff',
            '& > div + div': {
              borderTop: '1px solid #AFBDFA',
            }
          }}>
            {
              filteredSites?.length > 0
                ? filteredSites.map((site, index) => <WebsiteItem key={index} site={site} handleSetFavorite={handleSetFavorite} />)
                : <Box sx={{
                  minHeight: '300px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Typography>No Sites Found</Typography>
                </Box>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
