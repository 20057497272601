/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import OffenseIcon from '../../../Assets/Images/offence-icon.svg'
import Ipicon from '../../../Assets/Images/ip-icon.svg'
import Roboticon from '../../../Assets/Images/robot-icon.svg'
import WorldMap from 'react-svg-worldmap';
import CloseIcons from '../../../Assets/Images/CloseIcons.svg';
import { IAllCountry, IStatisticsNew, ITopCountry, ITopSite } from '../../../Features/securitySlice';
import { Link } from 'react-router-dom';


interface PropTypes {
    statistics: IStatisticsNew[];
    top_sites: ITopSite[];
    top_countries: ITopCountry[];
    all_countries: IAllCountry[];
    latest_scans: any[];
}

interface IEventCatalog {
    event: string;
    eventName: string;
    imageName: string;
}

function getEventStatistic(statistics: IStatisticsNew[], event: string): { eventName: string; count: number } {
    const matchingStatistic = statistics.find((statistic) => statistic.event === event);

    return {
        eventName: event,
        count: matchingStatistic ? matchingStatistic.count : 0,
    };
}

const eventsCatalog: IEventCatalog[] = [
    { event: 'ip_offense', eventName: 'Offense triggered', imageName: OffenseIcon },
    { event: 'ip_block_auto', eventName: 'IP Blocked', imageName: Ipicon },
    { event: 'antibot_fail', eventName: 'Antibot Fail', imageName: Roboticon },
    { event: 'antibot_pass', eventName: 'Antibot Pass', imageName: Roboticon },
    { event: 'bottrack_404', eventName: 'Bot detection 404', imageName: Roboticon },
    { event: 'bottrack_fakewebcrawler', eventName: 'Fake Web Crawler', imageName: Roboticon },
    { event: 'login_block', eventName: 'Failed Login', imageName: Ipicon },
    { event: 'block_author_fishing', eventName: 'Author Fishing Block', imageName: Ipicon },
    { event: 'bottrack_xmlrpc', eventName: 'XML-RPC', imageName: Roboticon },
    { event: 'hide_login_url', eventName: 'Custom Login URL Fail', imageName: Ipicon },
];

const GeneralSecurityTab = ({ statistics, top_sites, top_countries, all_countries, latest_scans }: PropTypes) => {
    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Box sx={{
                width: '100%',
            }}>
                <Grid container spacing={3}>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Typography variant='h6' sx={{
                            fontSize: '18px',
                            marginBottom: '20px',
                        }}>
                            World traffic heat map (30 days)
                        </Typography>
                        <Box sx={{
                            minHeight: '386px',
                            border: '1px solid #AFBDFA',
                            borderRadius: '10px',
                            maxHeight: '386px',
                            background: '#ffffff',
                            overflow: 'hidden',
                            '& svg': {
                                minHeight: '386px',
                                maxHeight: '386px',
                            },
                        }}>
                            <WorldMap
                                color="#325AE7"
                                value-suffix="people"
                                size="lg"
                                data={all_countries}
                            />
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                        <Typography variant='h6' sx={{
                            fontSize: '18px',
                            marginBottom: '20px',
                        }}>
                            Top Websites by Bot Visitors
                        </Typography>
                        <Box sx={{
                            border: '1px solid #AFBDFA',
                            borderRadius: '10px',
                            background: '#ffffff',
                            minHeight: '386px',
                            maxHeight: '386px',
                            overflowY: 'scroll',
                            '&> div + div': {
                                borderTop: '1px solid #AFBDFA',
                            }
                        }}>
                            {
                                top_sites?.length > 0
                                    ? top_sites.map((site, index) => (
                                        <Box key={index} sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '15px',
                                        }}>
                                            <Typography>
                                                {index + 1}. <Link to={`/sites/${site.site__base_url}/`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                                    {site.site__base_url}
                                                </Link>
                                            </Typography>
                                            <Typography>{site.count}</Typography>
                                        </Box>


                                    ))
                                    : <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minHeight: '384px',
                                        maxHeight: '384px',
                                        justifyContent: 'center',
                                    }}>
                                        <Typography>No Data Found</Typography>
                                    </Box>
                            }
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                        <Typography variant='h6' sx={{
                            fontSize: '18px',
                            marginBottom: '20px',
                        }}>
                            Bot Traffic By Country
                        </Typography>
                        <Box sx={{
                            border: '1px solid #AFBDFA',
                            borderRadius: '10px',
                            background: '#ffffff',
                            minHeight: '386px',
                            maxHeight: '386px',
                            overflowY: 'scroll',
                            '&> div + div': {
                                borderTop: '1px solid #AFBDFA',
                            }
                        }}>
                            {
                                top_countries?.length > 0
                                    ? top_countries.map((country, index) => (
                                        <Box key={index} sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '15px',
                                        }}>
                                            <Typography>{index + 1}.  {country.country_full}</Typography>
                                            <Typography>{country.count}</Typography>
                                        </Box>
                                    ))
                                    : <Box>
                                        <Typography>No Data Found</Typography>
                                    </Box>
                            }
                        </Box>
                    </Grid>
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                        <Typography variant='h6' sx={{
                            fontSize: '18px',
                            marginBottom: '20px',
                        }}>
                            Security Statistics (30 days)
                        </Typography>
                        <Box sx={{
                            border: '1px solid #AFBDFA',
                            borderRadius: '10px',
                            background: '#ffffff',
                            display: 'flex',
                            flexWrap: 'wrap',
                            '&>div': {
                                display: 'flex',
                                alignItems: 'center',
                                width: '50%',
                                maxWidth: '50%',
                                flex: '0 0 50%',
                                padding: '10px',
                            },
                            '&>div:nth-child(odd)': {
                                borderRight: '1px solid #AFBDFA',
                            },
                            '&> div:nth-child(2) ~ div': {
                                borderTop: '1px solid #AFBDFA',
                            }
                        }}>
                            {
                                eventsCatalog?.length > 0
                                    ? eventsCatalog.map((catalog, index) => {
                                        const eventStatistics = getEventStatistic(statistics, catalog.event);
                                        return (
                                            <Box key={index}>
                                                <img src={catalog.imageName} />
                                                <Box sx={{
                                                    marginLeft: '15px',
                                                }}>
                                                    <Typography sx={{
                                                        fontSize: '24px',
                                                        fontWeight: '600',
                                                    }}>
                                                        {eventStatistics.count}
                                                    </Typography>
                                                    <Typography>{catalog.eventName}</Typography>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                    : <Box>
                                        <Typography>No Data Found</Typography>
                                    </Box>
                            }
                        </Box>
                    </Grid>
                    <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                        <Typography variant='h6' sx={{
                            fontSize: '18px',
                            marginBottom: '20px',
                        }}>
                            Latest Scan Results
                        </Typography>
                        <Box sx={{
                            border: '1px solid #AFBDFA',
                            borderRadius: '10px',
                            background: '#ffffff',
                            overflow: 'hidden',
                            maxHeight: '406px',
                            overflowY: 'scroll',
                            minHeight: '406px',
                        }}>
                            <Box sx={{
                                padding: '20px 0',
                                alignItems: 'center',
                                background: '#ffffff',
                                position: 'sticky',
                                top: '0',
                                borderBottom: '1px solid #AFBDFA',
                                display: 'flex',
                                '& > h6': {
                                    width: '16.66%',
                                    maxWidth: '16.66%',
                                    textAlign: 'center',
                                    padding: '0 10px',
                                }
                            }}>
                                <Typography variant='h6'>Website URL</Typography>
                                <Typography variant='h6'>Malware found</Typography>
                                <Typography variant='h6'>Unrecognized files</Typography>
                                <Typography variant='h6'>Checksum failed</Typography>
                                <Typography variant='h6'>Vulnerability found</Typography>
                                <Typography variant='h6'>Abandoned Plugins</Typography>
                            </Box>
                            <Box sx={{
                                '& > div + div': {
                                    borderTop: '1px solid #AFBDFA',
                                }
                            }}>
                                {latest_scans.map((latest_scan, index) => (
                                    <Box key={index} sx={{
                                        padding: '20px 0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        '& > div': {
                                            width: '16.66%',
                                            maxWidth: '16.66%',
                                            textAlign: 'center',
                                            padding: '0 10px',
                                        }
                                    }}>
                                        <Box sx={{
                                            '& a': {
                                                wordBreak: 'break-all',
                                                color: '#325AE7',
                                                textDecoration: 'none'
                                            }
                                        }}>
                                            <Link to={`/sites/${latest_scan.site_base_url}/`}>{latest_scan.site_base_url}</Link>
                                        </Box>
                                        <Box>{latest_scan.malware_found ? <img src={CloseIcons} /> : '-'}</Box>
                                        <Box>{latest_scan.unrecognized_files ? <img src={CloseIcons} /> : '-'}</Box>
                                        <Box>{latest_scan.checksum_fail ? <img src={CloseIcons} /> : '-'}</Box>
                                        <Box>{latest_scan.vulnerability_found ? <img src={CloseIcons} /> : '-'}</Box>
                                        <Box>{latest_scan.abandoned_plugin ? <img src={CloseIcons} /> : '-'}</Box>
                                    </Box>
                                ))}


                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default GeneralSecurityTab;
