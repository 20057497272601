/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ThemeBox from '../ThemeBox';
import Stack from '@mui/material/Stack';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import macIcon from '../../../Assets/Images/mac-icon.svg'
import phoneIcon from '../../../Assets/Images/smartphone-icon.svg';
import moment from 'moment';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export interface PageSpeedData {
    accessibility_desktop: number;
    accessibility_mobile: number;
    best_practices_desktop: number;
    best_practices_mobile: number;
    cls_desktop: number;
    cls_mobile: number;
    date: string;
    fcp_desktop: number;
    fcp_mobile: number;
    lcp_desktop: number;
    lcp_mobile: number;
    score_desktop: number;
    score_mobile: number;
    seo_desktop: number;
    seo_mobile: number;
    si_desktop: number;
    si_mobile: number;
    tbt_desktop: number;
    tbt_mobile: number;
    tti_desktop: number;
    tti_mobile: number;
}

export interface UptimeData {
    avg_load_time: number;
    month: number;
    week: number;
    day: number;
}

interface BrokenLink {
    url: string;
    found_on: string;
    status_code: number;
}
  
export interface BrokenLinksData {
    broken_links: BrokenLink[];
    broken_link_count: number;
    total_checked_count: number;
    scan_date: string;
}

export type CheckBrokenLinksHandler = () => Promise<void>;

interface PropTypes {
    pagespeed: PageSpeedData;
    uptime: UptimeData;
    pagespeed_score: number;
    broken_links: BrokenLinksData;
    handleCheckBrokenLinks: CheckBrokenLinksHandler;
}

const getColorBasedOnValue = (value: number) => {
    if (value >= 90) {
        return '#2D9D78'; // Green
    } else if (value >= 80) {
        return '#D5A51F'; // Orange
    } else {
        return '#E23A45'; // Red
    }
};

const getBgColorBasedOnValue = (value: number) => {
    if (value < 2) {
        return '#2D9D78'; // Green
    } else if (value <= 3) {
        return '#D5A51F'; // Orange
    } else {
        return '#E23A45'; // Red
    }
};

const BorderLinearProgress = styled(LinearProgress)(({ theme, ...props }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: getColorBasedOnValue(props.value || 0),
    },
}));

const BorderLinearProgress2 = styled(LinearProgress)(({ theme, ...props }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: getBgColorBasedOnValue(props.value || 0),
    },
}));

const getPerformanceClassName = (value: number): string => {
    if (value >= 90) {
        return 'speed-high';
    } else if (value >= 80) {
        return 'speed-normal';
    } else {
        return 'speed-slow';
    }
};

const PerformanceIndicator = ({ value, label }: { value: number; label: string }) => {
    const performanceClassName = getPerformanceClassName(value);
  
    return (
        <Box className={performanceClassName} sx={{
            textAlign: 'center',
            position: 'relative',
            '&.speed-slow span': {
                backgroundColor: 'rgba(226, 48, 69, 0.15)',
                color: '#E23A45',
            },
            '&.speed-slow h6': {
                color: '#E23A45',
            },
            '&.speed-normal span': {
                backgroundColor: 'rgba(255, 170, 51, 0.15)',
                color: '#FFAA33',
            },
            '&.speed-normal h6': {
                color: '#FFAA33',
            },
            '&.speed-high span': {
                backgroundColor: 'rgba(45, 157, 120, 0.15)',
                color: '#2D9D78',
            },
            '&.speed-high h6': {
                color: '#2D9D78',
            },
            '& .MuiCircularProgress-root': {
                width: '80px !important',
                height: '80px !important',
                borderRadius: '100%',
            }
        }}>
            <CircularProgress variant="determinate" value={value} />
            <Typography variant='h6' sx={{
                fontSize: '20px',
                fontWeight: '600',
                lineHeight: 'normal',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: '25px',
            }}>
                {value}%
            </Typography>
            <Typography sx={{
                textAlign: 'center',
                marginTop: '10px',
            }}>
                {label}
            </Typography>
        </Box>
    );
};

const PerformanceOverviewTab = ({ pagespeed, uptime, pagespeed_score, broken_links, handleCheckBrokenLinks }: PropTypes) => {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 800);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Typography variant='h6' sx={{
                fontSize: '18px',
                marginBottom: '20px',
            }}>
                Overview
            </Typography>
            <Grid container spacing={2}>
                <Grid item xl={9} lg={8} md={8} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <Box sx={{
                                padding: '18px',
                                background: '#ffffff',
                                borderRadius: '10px',
                                border: '1px solid #AFBDFA',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '13px',
                                }}>
                                    <Typography sx={{ marginRight: '10px' }}>Google PageSpeed Score</Typography>
                                    <Typography variant='h6'>{pagespeed_score}/100</Typography>
                                </Box>
                                <BorderLinearProgress
                                    sx={{ backgroundColor: '#CCD6FF !important' }}
                                    variant="determinate"
                                    value={pagespeed_score}
                                />
                            </Box>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <Box sx={{
                                padding: '18px',
                                background: '#ffffff',
                                borderRadius: '10px',
                                border: '1px solid #AFBDFA',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '13px',
                                }}>
                                    <Typography sx={{ marginRight: '10px' }}>Response Time</Typography>
                                    <Typography variant='h6'>{uptime?.avg_load_time}ms</Typography>
                                </Box>
                                <BorderLinearProgress2
                                    sx={{ backgroundColor: '#CCD6FF !important' }}
                                    variant="determinate"
                                    value={((uptime?.avg_load_time || 0) / 100) * 1500 }
                                />
                            </Box>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <Box sx={{
                                padding: '18px',
                                background: '#ffffff',
                                borderRadius: '10px',
                                border: '1px solid #AFBDFA',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '13px',
                                }}>
                                    <Typography sx={{ marginRight: '10px' }}>Uptime</Typography>
                                    <Typography variant='h6'>{uptime?.month}%</Typography>
                                </Box>
                                <BorderLinearProgress
                                    sx={{ backgroundColor: '#CCD6FF !important' }}
                                    variant="determinate"
                                    value={uptime?.month}
                                />
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={6} xs={12}>
                            <Box sx={{
                                padding: '18px',
                                background: '#ffffff',
                                borderRadius: '10px',
                                border: '1px solid #AFBDFA',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '50px',
                                }}>
                                    <Typography>Performance on desktop</Typography>
                                    <img src={macIcon} />
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xl={3} lg={6} md={6} sm={6} xs={6}>
                                        <PerformanceIndicator value={pagespeed?.score_desktop} label="Performance" />
                                    </Grid>
                                    <Grid item xl={3} lg={6} md={6} sm={6} xs={6}>
                                        <PerformanceIndicator value={pagespeed?.accessibility_desktop} label="Accessibility" />
                                    </Grid>
                                    <Grid item xl={3} lg={6} md={6} sm={6} xs={6}>
                                        <PerformanceIndicator value={pagespeed?.best_practices_desktop} label="Best Practices" />
                                    </Grid>
                                    <Grid item xl={3} lg={6} md={6} sm={6} xs={6}>
                                        <PerformanceIndicator value={pagespeed?.seo_desktop} label="SEO" />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={6} xs={12}>
                            <Box sx={{
                                padding: '18px',
                                background: '#ffffff',
                                borderRadius: '10px',
                                border: '1px solid #AFBDFA',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '50px',
                                }}>
                                    <Typography>Performance on mobile</Typography>
                                    <img src={phoneIcon} />
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xl={3} lg={6} md={6} sm={6} xs={6}>
                                        <PerformanceIndicator value={pagespeed?.score_mobile} label="Performance" />
                                    </Grid>
                                    <Grid item xl={3} lg={6} md={6} sm={6} xs={6}>
                                        <PerformanceIndicator value={pagespeed?.accessibility_mobile} label="Accessibility" />
                                    </Grid>
                                    <Grid item xl={3} lg={6} md={6} sm={6} xs={6}>
                                        <PerformanceIndicator value={pagespeed?.best_practices_mobile} label="Best Practices" />
                                    </Grid>
                                    <Grid item xl={3} lg={6} md={6} sm={6} xs={6}>
                                        <PerformanceIndicator value={pagespeed?.seo_mobile} label="SEO" />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
                    <Box sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        maxHeight: '364px',
                        minHeight: '364px',
                        overflowY: 'scroll',
                        '& > div + div': {
                            borderTop: '1px solid #AFBDFA',
                        }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '18px',
                            justifyContent: 'space-between',
                        }}>
                            <Typography sx={{ marginRight: '10px' }}>First Contentful Paint (FCP)</Typography>
                            <Typography sx={{
                                color: "#2D9D78",
                                backgroundColor: '#B7E2D4',
                                fontSize: '14px',
                                fontWeight: '600',
                                borderRadius: '100px',
                                padding: '6px 18px',
                                whiteSpace: 'nowrap',
                            }}>
                                {pagespeed?.fcp_desktop} s
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '18px',
                            justifyContent: 'space-between',
                        }}>
                            <Typography sx={{ marginRight: '10px' }}>Total Blocking Time</Typography>
                            <Typography sx={{
                                color: "#2D9D78",
                                backgroundColor: '#B7E2D4',
                                fontSize: '14px',
                                fontWeight: '600',
                                borderRadius: '100px',
                                padding: '6px 18px',
                                whiteSpace: 'nowrap',
                            }}>
                                {pagespeed?.tbt_desktop} s
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '18px',
                            justifyContent: 'space-between',
                        }}>
                            <Typography sx={{ marginRight: '10px' }}>Largest Contentful Paint (LCP)</Typography>
                            <Typography sx={{
                                color: "#2D9D78",
                                backgroundColor: '#B7E2D4',
                                fontSize: '14px',
                                fontWeight: '600',
                                borderRadius: '100px',
                                padding: '6px 18px',
                                whiteSpace: 'nowrap',
                            }}>
                                {pagespeed?.lcp_desktop}  s
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '18px',
                            justifyContent: 'space-between',
                        }}>
                            <Typography sx={{ marginRight: '10px' }}>Time to Interactive</Typography>
                            <Typography sx={{
                                color: "#2D9D78",
                                backgroundColor: '#B7E2D4',
                                fontSize: '14px',
                                fontWeight: '600',
                                borderRadius: '100px',
                                padding: '6px 18px',
                                whiteSpace: 'nowrap',
                            }}>
                                {pagespeed?.tti_desktop} s
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '18px',
                            justifyContent: 'space-between',
                        }}>
                            <Typography sx={{ marginRight: '10px' }}>Speed Index</Typography>
                            <Typography sx={{
                                color: "#2D9D78",
                                backgroundColor: '#B7E2D4',
                                fontSize: '14px',
                                fontWeight: '600',
                                borderRadius: '100px',
                                padding: '6px 18px',
                                whiteSpace: 'nowrap',
                            }}>
                                {pagespeed?.si_desktop} s
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '18px',
                            justifyContent: 'space-between',
                        }}>
                            <Typography sx={{ marginRight: '10px' }}>Cumulative Layout Shift</Typography>
                            <Typography sx={{
                                color: "#2D9D78",
                                backgroundColor: '#B7E2D4',
                                fontSize: '14px',
                                fontWeight: '600',
                                borderRadius: '100px',
                                padding: '6px 18px',
                                whiteSpace: 'nowrap',
                            }}>
                                {pagespeed?.cls_desktop} s
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        display: 'flex',
                        '@media (max-width: 1024px)': {
                            display: 'block',
                        }
                    }}>
                        <Box sx={{
                            minWidth: '350px',
                            borderRight: '1px solid #AFBDFA',
                            '@media (max-width: 1024px)': {
                                minWidth: '100%',
                                border: 'none',
                            }
                        }}>
                            <Typography
                                sx={{
                                    marginBottom: '15px',
                                }}
                                variant='h6'>
                                Total Scanned pages:
                            </Typography>
                            <Typography variant='h3'>
                                {broken_links?.total_checked_count}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                marginTop: '30px',
                                marginBottom: '20px',
                            }}>
                                <Box sx={{
                                    paddingRight: '40px',
                                }}>
                                    <Typography
                                        sx={{
                                            marginBottom: '15px',
                                        }}
                                        variant='h6'>
                                        Good URL:
                                    </Typography>
                                    <Typography variant='h3' sx={{ color: '#6BE6A1' }}>
                                        {broken_links?.total_checked_count - broken_links?.broken_link_count}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            marginBottom: '15px',
                                        }}
                                        variant='h6'>
                                        Bad URL:
                                    </Typography>
                                    <Typography variant='h3' sx={{ color: '#EB4D58' }}>
                                        {broken_links?.broken_link_count}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={{ marginBottom: '20px', }}><b>
                                    {broken_links?.scan_date ? `Last scan on ${moment(broken_links?.scan_date).format('MMMM Do YYYY, h:mm:ss a')}` : 'Not Scanned yet'}
                                    </b>
                                    </Typography>
                                <Button 
                                    sx={{
                                        backgroundColor: '#325AE7',
                                        borderRadius: '7px',
                                        border: '1px solid #325AE7',
                                        boxShadow: 'none',
                                        padding: '3px 14px',
                                        fontSize: '14px',
                                        textTransform: 'inherit',
                                        minHeight: 'inherit',
                                        '&:hover': {
                                            boxShadow: 'none',
                                            backgroundColor: '#325AE7',
                                        },
                                    }}
                                    onClick={() => handleCheckBrokenLinks()}
                                >
                                    Crawl now
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{
                            width: '100%',
                            paddingLeft: '30px',
                            '@media (max-width: 1024px)': {
                                paddingLeft: '0',
                                paddingTop: '30px',
                            },
                            '@media (max-width: 767px)': {
                                overflowX: 'scroll',
                            },
                            '& > div + div': {
                                borderTop: '1px solid #000000',
                            },
                            '& > div': {
                                paddingTop: '15px',
                                paddingBottom: '15px',
                                '@media (max-width: 767px)': {
                                    minWidth: '580px',
                                },
                            },
                            '& > div:first-child': {
                                paddingTop: '0px',
                            },
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <Typography variant='h6'>URL</Typography>
                                <Typography variant='h6'>Status</Typography>
                            </Box>
                            {
                                broken_links?.broken_links?.length > 0
                                ?   broken_links.broken_links.map((broken_link, index) => (
                                    <Box key={index} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}>
                                        <Typography>{broken_link.url}</Typography>
                                        <Typography sx={{marginLeft: '15px'}}>{broken_link.status_code === 404 ? '404 Too many request' : broken_link.status_code}</Typography>
                                    </Box>
                                ))
                                :   <Box>
                                    <Typography variant='h6'>No broken links at the moment</Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PerformanceOverviewTab;
