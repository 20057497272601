/**
 *
 * This is a Higher Order Component which will manage user authentication and role
 *
 */
import { AuthenticatedUser } from "../Views/Login"

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    // you can change this to your localstorage value
    const storedUser = localStorage.getItem('wpAuthenticatedUser');
    const localUser: AuthenticatedUser | null = storedUser ? JSON.parse(storedUser) : null;
    if (!localUser) {
      window.location.replace('/login');
      return null;
    }
    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
