import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from './apiSlice';

const SLICE_URL = 'security';

export interface IStatistics {
    offense_triggered: number;
    ip_blocked: number;
    antibot_failed: number;
    antibot_pass: number;
    failed_login: number;
    spam_form_fail: number;
    xml_rpc: number;
    firewall_block: number;
    bot_detection_404: number;
    fake_web_crawler: number;
    block_author_fishing: number;
    hide_login_url: number;
}

export interface IStatisticsNew {
    event: string;
    count: number;
}

interface IScanResult {
    scan_date: string;
    core: boolean;
    plugin: boolean;
    theme: boolean;
    checksum_fail: boolean;
    path: string;
    unrecognized: boolean;
    abandoned: boolean;
    vulnerable: boolean;
    site_base_url: string;
}

export interface IScan {
    scan_results: IScanResult[];
    text: string | null;
    scan_date: string;
    malware_found: boolean;
    unrecognized_files: boolean;
    checksum_fail: boolean;
    vulnerability_found: boolean;
    abandoned_plugin: boolean;
    site_base_url: string;
    site_name: string;
}

export interface ITopCountry {
    country_full: string;
    count: number;
}

export interface ITopSite {
    site__base_url: string;
    count: number;
}

export interface IAllCountry {
    country: string;
    value: number;
}

interface ISecurityOverview {
    statistics: IStatisticsNew[];
    top_countries: ITopCountry[]; 
    top_sites: ITopSite[];
    latest_scans: IScan[];
    all_countries: IAllCountry[]; // Replace with a proper interface when the structure is known
}  

export interface ILog {
    user: string;
    message: string;
    event: null | string;
    level: string;
    ip: string;
    country: string;
    date: string;
    site_base_url: string;
}
  
interface ISecurityLogs {
    count: number;
    next: string | null;
    previous: string | null;
    results: ILog[];
}

export const defaultSecurityOverviewData: ISecurityOverview = {
    statistics: [
        { event: "offense_triggered", count: 0 },
        { event: "ip_blocked", count: 0 },
        { event: "antibot_failed", count: 0 },
        { event: "antibot_pass", count: 0 },
        { event: "failed_login", count: 0 },
        { event: "spam_form_fail", count: 0 },
        { event: "xml_rpc", count: 0 },
        { event: "firewall_block", count: 0 },
        { event: "bot_detection_404", count: 0 },
        { event: "fake_web_crawler", count: 0 },
        { event: "block_author_fishing", count: 0 },
        { event: "hide_login_url", count: 0 }
    ],
    top_countries: [],
    top_sites: [],
    latest_scans: [],
    all_countries: [],
};
  
export const defaultSecurityLogs: ISecurityLogs = {
    count: 0,
    next: null,
    previous: null,
    results: [
        {
            user: '',
            message: '',
            event: null,
            level: '',
            ip: '',
            country: '',
            date: '',
            site_base_url: '',
        }
    ]
};

export interface IWhitelistedIP {
    address: string;
    date: string;
}

export const defaultWhitelistedIPs: IWhitelistedIP[] = [
    {
        address: '',
        date: '',
    }
]

export const securityApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSecurityOverview: builder.query<ISecurityOverview, void>({
            query: () => `${SLICE_URL}/`,
            providesTags: (result) => 
                [{ type: 'Security', id: 'LIST' }],
        }),
        getSecurityLogs: builder.query<ISecurityLogs, string | null>({
            query: (queryParams) => `${SLICE_URL}/logs/${queryParams && '?'+ queryParams}`,
            providesTags: (result) => 
                [{ type: 'Log', id: 'LIST' }],
        }),
        getWebsiteSecurityLogs: builder.query<ISecurityLogs, { website: string, queryParams: string | null }>({
            query: ({website, queryParams}) => `${SLICE_URL}/logs/${website}/${queryParams && '?'+ queryParams}`,
            providesTags: (result) => 
                [{ type: 'Log', id: 'LIST' }],
        }),
        getSecurity: builder.query({
            query: (id) => `${SLICE_URL}/id`,
            providesTags: (result, error, id) => [{ type: 'Security', id }],
        }),
        getWhitelistedIps: builder.query<IWhitelistedIP[], void>({
            query: () => `${SLICE_URL}/whitelist-ips/`,
            providesTags: (result) => 
                [{ type: 'Whitelist', id: 'LIST' }],
        }),
        deleteWhitelistedIps: builder.mutation({
            query: (ip_address) => ({
                url: `${SLICE_URL}/whitelist-ips/${ip_address}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'Whitelist', id: "LIST" }
            ]
        }),
        addWhitelistedIp: builder.mutation({
            query: (ip_address) => ({
                url: `${SLICE_URL}/whitelist-ips/${ip_address}/`,
                method: 'PUT',
            }),
            invalidatesTags: [
                { type: 'Whitelist', id: "LIST" }
            ]
        }),
    })
});

export const {
    useGetSecurityOverviewQuery,
    useGetSecurityLogsQuery,
    useGetWebsiteSecurityLogsQuery,
    useGetSecurityQuery,
    useGetWhitelistedIpsQuery,
    useDeleteWhitelistedIpsMutation,
    useAddWhitelistedIpMutation,
} = securityApiSlice;