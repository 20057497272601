/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Grid, InputBase, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import { ISite } from '../../../Views/WebsiteOverview';
import { useGetPackagesQuery } from '../../../Features/settingSlice';
import { useToggleSiteActiveMutation, useDeleteSiteMutation } from '../../../Features/siteSlice';
import Swal from 'sweetalert2';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const StyledButton = styled('button')(
    ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  padding: 7px 12px;
  border-radius: 7px;
  text-align: left;
  min-height: 47px;
  line-height: 1.5;
  background: #ffffff;
  font-size: 16px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  font-family: Titillium Web;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  
  
  &.${selectUnstyledClasses.expanded} {
    &::after {
      float: right;
      transform: rotate(180deg);
    }
  }
  
  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
  box-sizing: border-box;
  padding: 6px;
  min-width: 200px;
  border-radius: 12px;
  font-family: Titillium Web;
  font-weight: 600;
  overflow: auto;
  outline: 0px;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  width: 100%;
  `,
);

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  
  &:last-of-type {
    border-bottom: none;
  }
  
  &.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
  `;
const CustomSelect = React.forwardRef(function CustomSelect<
    TValue extends {},
    Multiple extends boolean,
>(
    props: SelectUnstyledProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const slots: SelectUnstyledProps<TValue, Multiple>['slots'] = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: StyledPopper,
        ...props.slots,
    };

    return <SelectUnstyled {...props} ref={ref} slots={slots} />;
}) as <TValue extends {}, Multiple extends boolean>(
    props: SelectUnstyledProps<TValue, Multiple> &
        React.RefAttributes<HTMLButtonElement>,
) => JSX.Element;

export type SaveGeneralSettingsHandler = (type: string, value: string) => Promise<void>;

interface PropTypes {
    handleSaveGeneralSettings: SaveGeneralSettingsHandler;
    site: ISite;
}

type SettingsState = {
    [type: string]: string;
};

const PackageSettingsTab = ({ handleSaveGeneralSettings, site }: PropTypes) => {
    const [settingsState, setSettingsState] = React.useState<SettingsState>({});
    const { data: packages } = useGetPackagesQuery();
    const [toggleSiteActive] = useToggleSiteActiveMutation();
    const [deleteSite] = useDeleteSiteMutation();

    const handleInputChange = (type: string, value: string) => {
        setSettingsState((prevState) => ({
            ...prevState,
            [type]: value,
        }));
    };

    const handleToggleSiteActive = async () => {
        Swal.fire({
            title: `${site.active ? 'Deactivate' : 'Activate'} Site?`,
            showCancelButton: true,
            confirmButtonText: 'Proceed!',
            showLoaderOnConfirm: true,
            html: 'You can deactivate your site anytime, it will not be monitored but the data will be retained. <br /><br /><b>Do note that the billing continues since we are still storing the information.</b>',
            preConfirm: async () => {
                try {
                    await toggleSiteActive(site.base_url);
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: `${site.active ? 'Removing' : 'Reconnecting'} Site Failed`,
                        text: `Failed to ${site.active ? 'remove' : 'reconnect'} your site. Please try again later.`
                    })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    confirmButtonColor: '#325AE7',
                    title: `Site ${site.active ? 'Deactivated' : 'Activated'} Successful`,
                    text: `Your site was ${site.active ? 'deactivated' : 'activated'} successfully!`
                })
            }
        })
    }

    const handleDeleteSite = async () => {
        Swal.fire({
            title: `Delete Site?`,
            showCancelButton: true,
            confirmButtonText: 'Proceed!',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    await deleteSite(site.base_url);
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: `Site Deleted Failed`,
                        text: `Failed to remove your site. Please try again later.`
                    })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    confirmButtonColor: '#325AE7',
                    title: `Site Removed Successful`,
                    text: `Your site was removed successfully!`
                })
            }
        })
    }

    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
                justifyContent: 'space-between',
                '@media (max-width: 767px)': {
                    display: 'block',
                },
            }}>
                <Typography variant='h6' sx={{
                    fontSize: '18px',
                    marginBottom: '0',
                    '@media (max-width: 767px)': {
                        marginBottom: '10px',
                    },
                }}>
                    General Settings
                </Typography>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    {/* <Button sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '7px',
                        border: '1px solid #AFBDFA',
                        boxShadow: 'none',
                        padding: '7px 19px',
                        color: '#1B1D29',
                        marginRight: '12px',
                        textTransform: 'inherit',
                        '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#325AE7',
                            color: '#ffffff',
                        },
                    }}>
                        Refresh
                    </Button>
                    <Button sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '7px',
                        border: '1px solid #AFBDFA',
                        boxShadow: 'none',
                        padding: '7px 19px',
                        color: '#1B1D29',
                        marginRight: '12px',
                        textTransform: 'inherit',
                        '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#325AE7',
                            color: '#ffffff',
                        },
                    }}>
                        Pause site
                </Button> */}
                    <Button sx={{
                        backgroundColor: '#325AE7',
                        borderRadius: '7px',
                        border: '1px solid #325AE7',
                        boxShadow: 'none',
                        padding: '7px 19px',
                        marginRight: '12px',
                        textTransform: 'inherit',
                        '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#325AE7',
                        },

                    }}
                        onClick={() => handleToggleSiteActive()}

                    >
                        {site.active ? 'Deactivate Site' : 'Activate Site'}
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: '#E23A45',
                            borderRadius: '7px',
                            border: '1px solid #E23A45',
                            boxShadow: 'none',
                            padding: '7px 19px',
                            textTransform: 'inherit',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#E23A45',
                            },
                        }}
                        onClick={() => handleDeleteSite()}
                    >
                        Remove Site
                    </Button>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Box sx={{
                        '& label': {
                            width: '100%',
                            display: 'inline-block',
                            marginBottom: '8px',
                            fontWeight: '600',
                        },
                        '& input': {
                            fontSize: '16px',
                            color: '#525566',
                            border: '1px solid #AFBDFA',
                            width: '100%',
                            padding: '11px 15px',
                            background: '#ffffff',
                            borderRadius: '7px',
                        }
                    }}>
                        <label>Site title</label>
                        <Box sx={{
                            position: 'relative',
                        }}>
                            <InputBase
                                sx={{
                                    width: '100%',
                                    '& input': {
                                        padding: '0 165px 0 18px',
                                        fontSize: '16px',
                                        color: '#525566',
                                        backgroundColor: '#ffffff',
                                        borderRadius: '7px',
                                        border: '1px solid #AFBDFA',
                                        height: '44px',
                                        maxHeight: '44px',
                                        boxSizing: 'border-box',
                                    }
                                }}
                                placeholder="Website Name"
                                type='text'
                                inputProps={{ 'aria-label': 'Website Name Input' }}
                                value={settingsState['title'] || ''}
                                onChange={(e) => handleInputChange('title', e.target.value)}
                            />
                            <Button
                                type="button"
                                aria-label="search"
                                sx={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '0',
                                    height: '100%',
                                    backgroundColor: '#325AE7',
                                    borderRadius: '7px',
                                    border: '1px solid #325AE7',
                                    boxShadow: 'none',
                                    padding: '7px 19px',
                                    textTransform: 'inherit',
                                    '&:hover': {
                                        boxShadow: 'none',
                                        backgroundColor: '#325AE7',
                                    },
                                }}
                                onClick={() => handleSaveGeneralSettings("title", settingsState['title'])}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Box sx={{
                        '& label': {
                            width: '100%',
                            display: 'inline-block',
                            fontWeight: '600',
                            marginBottom: '8px',
                        },
                        '& input': {
                            fontSize: '16px',
                            color: '#525566',
                            border: '1px solid #AFBDFA',
                            width: '100%',
                            padding: '11px 15px',
                            background: '#ffffff',
                            borderRadius: '7px',
                        }
                    }}>
                        <label>Admin username</label>
                        {/* <InputBase
                            placeholder='Admin'
                            sx={{
                                width: '100%',
                            }}
                            type='text'
                        /> */}
                        <Box sx={{
                            position: 'relative',
                        }}>
                            <InputBase
                                sx={{
                                    width: '100%',
                                    '& input': {
                                        padding: '0 165px 0 18px',
                                        fontSize: '16px',
                                        color: '#525566',
                                        backgroundColor: '#ffffff',
                                        borderRadius: '7px',
                                        border: '1px solid #AFBDFA',
                                        height: '44px',
                                        maxHeight: '44px',
                                        boxSizing: 'border-box',
                                    }
                                }}
                                placeholder="Admin Username"
                                type='text'
                                inputProps={{ 'aria-label': 'Admin Username Input' }}
                                value={settingsState['username'] || ''}
                                onChange={(e) => handleInputChange('username', e.target.value)}
                            />
                            <Button
                                type="button"
                                aria-label="search"
                                sx={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '0',
                                    height: '100%',
                                    backgroundColor: '#325AE7',
                                    borderRadius: '7px',
                                    border: '1px solid #325AE7',
                                    boxShadow: 'none',
                                    padding: '7px 19px',
                                    textTransform: 'inherit',
                                    '&:hover': {
                                        boxShadow: 'none',
                                        backgroundColor: '#325AE7',
                                    },
                                }}
                                onClick={() => handleSaveGeneralSettings("username", settingsState['username'])}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
                marginTop: '30px',
                justifyContent: 'space-between',
                '@media (max-width: 767px)': {
                    display: 'block',
                },
            }}>
                <Typography variant='h6' sx={{
                    fontSize: '18px',
                    marginBottom: '0',
                    '@media (max-width: 767px)': {
                        marginBottom: '10px',
                    },
                }}>
                    General Settings
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {packages?.map((package_item) => (
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12} key={package_item.name}>
                        <Box sx={{
                            borderRadius: '20px',
                            border: '1px solid #AFBDFA',
                            background: '#fff',
                            overflow: 'hidden',
                            padding: '20px',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #AFBDFA',
                                paddingBottom: '10px',
                                marginBottom: '15px',
                            }}>
                                <Typography variant='h6'>{package_item.name} package</Typography>
                                <Typography sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Typography sx={{ fontWeight: '600', color: '#325AE7', fontSize: '22px', marginRight: '5px', }}>
                                        €{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(package_item?.price / 100)}
                                    </Typography> /month
                                </Typography>
                            </Box>
                            <Box sx={{
                                '& p + p': {
                                    marginTop: '10px',
                                },
                                '&>p': {
                                    fontSize: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                                '& .list-dot': {
                                    color: '#325AE7',
                                    fontSize: '18px',
                                    marginRight: '8px',
                                },
                                '& .disable-dot .list-dot': {
                                    color: '#707070',
                                },
                                '& .disable-dot': {
                                    color: '#707070',
                                }
                            }}>
                                <Typography><Typography className='list-dot'>●</Typography>{package_item.backup_count} backups per week</Typography>
                                <Typography><Typography className='list-dot'>●</Typography>{package_item.update_count} times updates per week</Typography>
                                {package_item.security ? (
                                    <Typography><Typography className='list-dot'>●</Typography>Real-time Security</Typography>
                                ) : (
                                    <Typography className='disable-dot'><Typography className='list-dot'>●</Typography> Real-time Security (roadmap)</Typography>
                                )}
                                {package_item.image_optimization ? (
                                    <Typography><Typography className='list-dot'>●</Typography>Image optimization</Typography>
                                ) : (
                                    <Typography className='disable-dot'><Typography className='list-dot'>●</Typography> Image optimization (roadmap)</Typography>
                                )}
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '20px',
                                '& .btn-disable': {
                                    background: '#707070',
                                    borderColor: '#707070',
                                }
                            }}>
                                <Button
                                    className={site?.package_name === package_item.name ? 'btn-disable' : ''}
                                    disabled={site?.package_name === package_item.name}
                                    sx={{
                                        backgroundColor: '#325AE7',
                                        borderRadius: '7px',
                                        border: '1px solid #325AE7',
                                        boxShadow: 'none',
                                        padding: '7px 19px',
                                        marginRight: '20px',
                                        textTransform: 'inherit',
                                        '&:hover': {
                                            boxShadow: 'none',
                                            backgroundColor: '#325AE7',
                                        },
                                    }}
                                    onClick={() => handleSaveGeneralSettings("package", package_item.name)}
                                >
                                    {site?.package_name === package_item.name ? 'Active' : 'Activate'}
                                </Button>
                                {site?.package_name === package_item.name && <Typography>This package is now active</Typography>}
                            </Box>
                        </Box>
                    </Grid>
                ))}

            </Grid>
        </Box>
    );
};

export default PackageSettingsTab;
