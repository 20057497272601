/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Grid, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import ThemeBox, { ChangeThemeHandler, Theme } from '../ThemeBox';
import InstallTheme from './InstallTheme';
import { usePauseThemeUpdateMutation, useGetThemesMutation } from '../../../Features/siteSlice';
import SyncIcon from '@mui/icons-material/Sync';
import Swal from 'sweetalert2';

export type ThemeList = Theme[];

type PropTypes = {
    themes: ThemeList;
    handleChangeTheme: ChangeThemeHandler;
    changingTheme: boolean;
    website_url: string;
}

const ThemesTab = ({ themes, handleChangeTheme, changingTheme, website_url }: PropTypes) => {

    const [open, setOpen] = React.useState(false);
    const [checkedThemes, setCheckedThemes] = React.useState<Theme[]>([]);

    const [pauseThemeUpdate] = usePauseThemeUpdateMutation();
    const [getThemes] = useGetThemesMutation();

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const handleCheckboxChange = (theme: Theme) => {
        if (checkedThemes.some((t) => t.name === theme.name)) {
            setCheckedThemes((prevCheckedThemes) =>
                prevCheckedThemes.filter((t) => t.name !== theme.name)
            );
        } else {
            setCheckedThemes((prevCheckedThemes) => [...prevCheckedThemes, theme]);
        }
    };

    const handleGetThemes = async (website_url: string) => {
        try {
            await getThemes({website_url});
            Swal.fire({
                icon: 'success',
                confirmButtonColor: '#325AE7',
                title: 'Themes Retrieved Successfully',
                text: `It might take 5 minutes for the themes to be retrieved. Please revisit this page later.`
            })
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Theme Retrieval Failed.',
                text: `We could not retrieve the themes at this moment. Please try again later.`
            })
        }
    }

    const handlePauseThemeUpdates = () => {
        if (checkedThemes.length === 0) {
            Swal.fire('No Themes Selected', 'Please select at least one theme to proceed', 'warning');
        } else {
            Swal.fire({
                title: `Pause Update for Selected Themes?`,
                showCancelButton: true,
                confirmButtonText: `Yes, Proceed!`,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        for (const theme of checkedThemes) {
                            const { site_base_url, name } = theme;
                            console.log("Theme Name >>>> ", name);
                            await pauseThemeUpdate({ website_url: site_base_url, theme_name: name })
                        }
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: `Unable to Pause Theme Updates`,
                            text: `Failed to pause updates for the selected themes. Please try again later.`
                        })
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then(async (result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        icon: 'success',
                        confirmButtonColor: '#325AE7',
                        title: `Pause Theme Updates Successful`,
                        text: `Pause updates for the selected themes completed successfully!`
                    })
                }
            })
        }
    };

    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
                justifyContent: 'space-between',
                '@media (max-width: 767px)': {
                    display: 'block',
                },
            }}>
                <Typography variant='h6' sx={{
                    fontSize: '18px',
                    marginBottom: '0',
                    '@media (max-width: 767px)': {
                        marginBottom: '10px',
                    },
                }}>
                    Themes
                </Typography>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Button
                        sx={{
                            backgroundColor: '#ffffff',
                            borderRadius: '7px',
                            border: '1px solid #AFBDFA',
                            boxShadow: 'none',
                            padding: '7px 19px',
                            color: '#1B1D29',
                            marginRight: '12px',
                            textTransform: 'inherit',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#325AE7',
                                color: '#ffffff',
                            },
                        }}
                        onClick={handlePauseThemeUpdates}
                    >
                        Pause Updates
                    </Button>
                    {/* <Button sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '7px',
                        border: '1px solid #AFBDFA',
                        boxShadow: 'none',
                        padding: '7px 19px',
                        color: '#1B1D29',
                        marginRight: '12px',
                        textTransform: 'inherit',
                        '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#325AE7',
                            color: '#ffffff',
                        },
                    }}>
                        Delete Themes
                    </Button> */}
                    <Box sx={{
                        display: 'flex',
                        marginRight: '12px',
                        alignItems: 'center',
                    }}>
                        <Button
                            sx={{
                                backgroundColor: '#ffffff',
                                borderRadius: '7px',
                                border: '1px solid #AFBDFA',
                                boxShadow: 'none',
                                padding: '7px 19px',
                                color: '#1B1D29',
                                textTransform: 'inherit',
                                '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: '#325AE7',
                                    color: '#ffffff',
                                },
                            }}
                            onClick={() => handleGetThemes(website_url)}

                        >
                            <Tooltip title="Refresh Themes">
                                <SyncIcon />
                            </Tooltip>
                        </Button>
                    </Box>
                    <Button onClick={handleOpen} sx={{
                        backgroundColor: '#325AE7',
                        borderRadius: '7px',
                        border: '1px solid #325AE7',
                        boxShadow: 'none',
                        padding: '7px 19px',
                        textTransform: 'inherit',
                        '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#325AE7',
                        },
                    }}>
                        Add Theme
                    </Button>
                    <InstallTheme open={open} handleClose={handleClose} website_url={website_url} />

                </Box>
            </Box>
            <Grid container spacing={2}>
                {
                    themes?.length > 0
                        ? themes.map((theme, index) => (
                            <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                                <ThemeBox
                                    theme={theme}
                                    handleChangeTheme={handleChangeTheme}
                                    changingTheme={changingTheme}
                                    checkedThemes={checkedThemes}
                                    onCheckboxChange={() => handleCheckboxChange(theme)}
                                />
                            </Grid>
                        ))
                        : <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Typography variant="h6">No themes</Typography>
                        </Grid>
                }
            </Grid>
        </Box>
    );
};

export default ThemesTab;
