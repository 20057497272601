/**
 *
 * This view contains login page design you can change it as you want from ./styled.tsx
 *
 */
import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { LoginWrapper } from './styled';
import { Link } from 'react-router-dom';
import TextBox from '../../Components/UI/TextBox';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import LoginBanner from '../../Assets/Images/login-img.png'


const ForgetPassword = () => {


  return (

    <Box sx={{
      minHeight: '100vh',
      marginTop: '-100px',
      display: 'flex',
      background: '#F4F5FE',
      boxSizing: 'border-box',
      '& *': {
        boxSizing: 'border-box',
      },
      '@media (max-width: 767px)': {
        flexWrap: 'wrap',
      },
    }}>
      <Box sx={{
        width: '50%',
        maxHeight: '50%',
        flex: '0 0 50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '60px 30px',
        '@media (max-width: 767px)': {
          width: '100%',
          maxHeight: '100%',
          flex: '0 0 100%',
          padding: '50px 15px',
        },
      }}>
        <Box sx={{
          width: '400px',
          maxWidth: '100%',
        }}>
          <Typography
            variant='h1'
            sx={{
              color: '#1B1D29',
              fontSize: '32px',
              lineHeight: '40px',
              fontWeight: '600',
              marginBottom: '20px',
              '@media (max-width: 767px)': {
                fontSize: '26px',
                lineHeight: '30px',
                marginBottom: '10px',
              },
            }}
          >
            Forget password?
          </Typography>
          <Typography>Enter your email address to save new password.</Typography>
          <Box
            sx={{
              mt: 5,
              '@media (max-width: 767px)': {
                marginTop: '30px',
              }
            }}
          >

            <form>
              <TextField
                label="Email"
                name="email"
                fullWidth
                type='email'
                placeholder="example@mail.com"
                variant="outlined"
                sx={{
                  '& label': {
                    color: '#325AE7',
                  },
                  '& input': {
                    borderRadius: '10px',
                    background: '#fff',
                  },
                  '& fieldset': {
                    borderColor: '#325AE7',
                    borderRadius: '10px',
                  }
                }}
              />

              <Box sx={{
                marginTop: '30px',
              }}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{
                    width: '100%',
                    backgroundColor: '#325AE7',
                    borderRadius: '10px',
                    border: '1px solid #325AE7',
                    color: "#ffffff",
                    boxShadow: 'none',
                    padding: '10px 19px',
                    textTransform: 'inherit',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#325AE7',
                    },
                    '& img': {
                      marginRight: '10px',
                    }
                  }}
                >
                  Send me a mail
                </Button>
              </Box>
            </form>

          </Box>
          <Typography sx={{
            marginTop: '30px',
            '@media (max-width: 767px)': {
              marginTop: '20px',
            },
            '& a': {
              color: '#325AE7',
            }
          }}>
            Want to sign in? <Link to="/login">Sign in here</Link>
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        width: '50%',
        maxHeight: '50%',
        background: '#D5DDF9',
        flex: '0 0 50%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingLeft: '100px',
        paddingTop: '100px',
        '@media (max-width: 1024px)': {
          paddingLeft: '40px',
          paddingTop: '40px',
        },
        '@media (max-width: 767px)': {
          width: '100%',
          maxHeight: '100%',
          flex: '0 0 100%',
          paddingLeft: '15px',
        },
      }}>
        <Box>
          <Box sx={{
            maxWidth: '600px',
            paddingRight: '30px',
            '@media (max-width: 767px)': {
              width: '100%',
              maxHeight: '100%',
              flex: '0 0 100%',
              paddingRight: '15px',
            },
          }}>
            <Typography
              variant='h2'
              sx={{
                fontSize: '50px',
                lineHeight: '58px',
                textTransform: 'uppercase',
                '@media (max-width: 1024px)': {
                  fontSize: '40px',
                  lineHeight: '45px',
                },
                '@media (max-width: 767px)': {
                  fontSize: '30px',
                  lineHeight: '38px',
                },
              }}
            >
              Maintain YOUR Website easier than ever
            </Typography>
            <Box sx={{
              borderLeft: '3px solid #325AE7',
              paddingLeft: '40px',
              margin: '40px 0',
              '@media (max-width: 1024px)': {
                margin: '30px 0',
                paddingLeft: '20px',
              },
            }}>
              <Typography>Fully Automated<br />Our system tools automatically create backups of your website(s) and controls your site(s) after the automated updates. When sites don’t work the way they should, we will notify you so that you can take action before your clients even know!</Typography>
            </Box>
          </Box>
          <Box sx={{
            lineHeight: '0',
            '& img': {
              width: '100%',
            }
          }}>
            <img src={LoginBanner} />
          </Box>
        </Box>
      </Box>
    </Box>

  );
};
export default ForgetPassword;
