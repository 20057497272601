import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import WebsiteIcon from "../../Assets/Images/website-icon.svg";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Slider from "../../Assets/Images/slider.svg";
import "./style.css";
import Plusicon from "../../Assets/Images/plus-icon.svg";
import Loveicon from "../../Assets/Images/love-icon.svg";
import Loveiconfill from "../../Assets/Images/love-icon-fill.svg";
import {
  initialReportsData,
  useGetReportsQuery,
  useGetWebsiteReportsQuery,
} from "../../Features/reportSlice";
import ReportsFilter from "../../Components/UI/ReportsFilter";
import ReportPreview from "../../Components/PdfReportDownload/ReportPreview";
import ReportPDF from "../../Components/PdfReportDownload/ReportPDF";

type Anchor = "left";
const Report = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [favoriteFilter, setFavoriteFilter] = React.useState("all");
  const [sortFilter, setSortFilter] = React.useState("aToZ");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [websiteUrl, setWebsiteUrl] = React.useState("");
  const [isDataReady, setIsDataReady] = React.useState(false);

  const handleClose = () => setOpenModal(false);

  const handleFavoriteFilterChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setFavoriteFilter(event.target.value);
  };

  const handleSortFilterChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSortFilter(event.target.value);
  };

  const handleSearchQueryChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchQuery(event.target.value);
  };

  const { data: reportsData = initialReportsData } = useGetReportsQuery();

  const { data: websiteReportsData, refetch } =
    useGetWebsiteReportsQuery(websiteUrl);

  const handleSetWebsiteUrl = async (website_url: string) => {
    setWebsiteUrl(website_url);
    setIsDataReady(false);

    try {
      await refetch();
      setIsDataReady(true);
    } catch (error) {
      console.error("Error fetching website reports:", error);
    }
  };

  React.useEffect(() => {
    if (isDataReady && websiteReportsData) {
      setOpenModal(true);
    }
  }, [isDataReady, websiteReportsData]);

  console.log("Reports Data >>>>>>>>>>>>>>>>> ", reportsData);
  console.log("Specific Reports Data >>>>>>>>>>>>>>>>> ", websiteReportsData);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const [alignment, setAlignment] = React.useState<string | null>("left");

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAlignment(newAlignment);
  };

  const filteredReports = React.useMemo(() => {
    if (!reportsData?.reports) return [];

    let allReports = [...reportsData.reports];

    if (favoriteFilter === "favorite") {
      allReports = allReports.filter(
        (report) => report.site_favorite === "True"
      );
    }

    if (sortFilter === "aToZ") {
      allReports.sort((a, b) =>
        a.site_title ? a.site_title.localeCompare(b.site_title || "") : 0
      );
    } else if (sortFilter === "zToA") {
      allReports.sort((a, b) =>
        b.site_title ? b.site_title.localeCompare(a.site_title || "") : 0
      );
    } else if (sortFilter === "newestFirst") {
      allReports.sort(
        (a, b) =>
          new Date(b.month_written).getTime() -
          new Date(a.month_written).getTime()
      );
    } else if (sortFilter === "oldestFirst") {
      allReports.sort(
        (a, b) =>
          new Date(a.month_written).getTime() -
          new Date(b.month_written).getTime()
      );
    }

    if (searchQuery) {
      // Adding a null check for 'searchQuery' before calling 'toLowerCase'
      const lowerCaseSearchQuery = searchQuery ? searchQuery.toLowerCase() : "";
      allReports = allReports.filter(
        (report) =>
          // Adding null checks for 'report.site_title' and 'report.site_base_url' before calling 'toLowerCase' and 'includes'
          (report.site_title &&
            report.site_title.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (report.site_base_url &&
            report.site_base_url.toLowerCase().includes(lowerCaseSearchQuery))
      );
    }

    return allReports;
  }, [reportsData, favoriteFilter, sortFilter, searchQuery]);

  return (
    <Box>
      <Box
        sx={{
          padding: "20px 40px",
          borderBottom: "1px solid #AFBDFA",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#FAFBFF",
          position: "sticky",
          top: "0",
          zIndex: "2",
          "@media (max-width: 1400px)": {
            padding: "20px 20px",
          },
          "@media (max-width: 1199px)": {
            position: "relative",
          },
          "@media (max-width: 767px)": {
            display: "block",
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            "@media (max-width: 767px)": {
              marginBottom: "15px",
            },
          }}
        >
          Reports
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "@media (max-width: 400px)": {
              display: "block",
            },
          }}
        >
          <Box
            sx={{
              marginLeft: "0",
              "@media (max-width: 400px)": {
                marginLeft: "0",
                marginTop: "15px",
              },
            }}
          >
            <Button
              sx={{
                backgroundColor: "#325AE7",
                borderRadius: "7px",
                border: "1px solid #325AE7",
                boxShadow: "none",
                padding: "7px 19px",
                fontSize: "15px",
                textTransform: "inherit",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#325AE7",
                },
                "& img": {
                  marginRight: "10px",
                },
                "& a": {
                  color: "#ffffff",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <Link to="/add/">
                <img src={Plusicon} />
                Add a website
              </Link>
            </Button>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <Button
                  onClick={toggleDrawer(anchor, true)}
                  sx={{
                    backgroundColor: "#325AE7",
                    borderRadius: "7px",
                    border: "1px solid #325AE7",
                    boxShadow: "none",
                    padding: "7px 19px",
                    display: "none",
                    marginLeft: "10px",
                    textTransform: "inherit",
                    "@media (max-width: 1199px)": {
                      display: "inline-flex",
                    },
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#325AE7",
                    },
                    "& img": {
                      marginRight: "10px",
                      filter: "brightness(0) invert(1)",
                    },
                  }}
                >
                  Filters
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  sx={{
                    zIndex: "9999",
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "240px",
                      borderRight: "1px solid #AFBDFA",
                      maxHeight: "100vh",
                      minHeight: "100vh",
                      overflowY: "scroll",
                      position: "sticky",
                      top: "85px",
                      "@media (max-width: 1199px)": {
                        top: "0",
                        maxHeight: "100vh",
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        padding: "20px 40px",
                        borderBottom: "1px solid #AFBDFA",
                        "& img": {
                          marginRight: "20px",
                        },
                      }}
                    >
                      Filters
                    </Typography>
                    <ReportsFilter
                      handleFavoriteFilterChange={handleFavoriteFilterChange}
                      handleSortFilterChange={handleSortFilterChange}
                      handleSearchQueryChange={handleSearchQueryChange}
                      favoriteFilter={favoriteFilter}
                      sortFilter={sortFilter}
                      searchQuery={searchQuery}
                    />
                  </Box>
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          "@media (max-width: 1400px)": {},
        }}
      >
        <Box
          sx={{
            minWidth: "240px",
            borderRight: "1px solid #AFBDFA",
            maxHeight: "calc(100vh - 185px)",
            minHeight: "calc(100vh - 185px)",
            overflowY: "scroll",
            position: "sticky",
            top: "85px",
            "@media (max-width: 1199px)": {
              top: "0",
              maxHeight: "calc(100vh - 100px)",
              display: "none",
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              padding: "20px 40px",
              borderBottom: "1px solid #AFBDFA",
              "& img": {
                marginRight: "20px",
              },
            }}
          >
            Filters
          </Typography>
          <ReportsFilter
            handleFavoriteFilterChange={handleFavoriteFilterChange}
            handleSortFilterChange={handleSortFilterChange}
            handleSearchQueryChange={handleSearchQueryChange}
            favoriteFilter={favoriteFilter}
            sortFilter={sortFilter}
            searchQuery={searchQuery}
          />
        </Box>
        <Box
          sx={{
            padding: "20px 40px",
            width: "100%",
            "@media (max-width: 1400px)": {
              padding: "25px 20px",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              background: "#fff",
              "& > div + div": {
                borderTop: "1px solid #AFBDFA",
              },
            }}
          >
            {filteredReports?.length > 0 ? (
              filteredReports.map((report, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "16px 18px",
                    "@media (max-width: 767px)": {
                      display: "block",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "300px",
                      maxWidth: "300px",
                      "& img": {
                        width: "40px",
                      },
                    }}
                  >
                    <img src={WebsiteIcon} />
                    <Box
                      sx={{
                        marginLeft: "12px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "16px",
                        }}
                      >
                        {report.site_title}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "normal",
                        }}
                      >
                        {report.site_base_url}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      "@media (max-width: 767px)": {
                        padding: "15px 0",
                        justifyContent: "flex-start",
                      },

                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      padding: "0 20px",
                      justifyContent: "center",
                      "& > p": {
                        borderRight: "1px solid #AFBDFA",
                      },
                      "& > p:first-child": {
                        borderLeft: "1px solid #AFBDFA",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        position: "relative",
                        padding: "0 10px 0 10px",
                        minWidth: "160px",
                        textAlign: "center",
                      }}
                    >
                      Update - {report.total_updates}
                    </Typography>
                    <Typography
                      sx={{
                        position: "relative",
                        padding: "0 10px 0 10px",
                        minWidth: "160px",
                        textAlign: "center",
                      }}
                    >
                      Backup - {report.total_backups}
                    </Typography>
                    <Typography
                      sx={{
                        position: "relative",
                        padding: "0 10px 0 10px",
                        minWidth: "160px",
                        textAlign: "center",
                      }}
                    >
                      Plugin update - {report.plugin_updates}
                    </Typography>
                    <Typography
                      sx={{
                        position: "relative",
                        padding: "0 10px 0 10px",
                        minWidth: "160px",
                        textAlign: "center",
                      }}
                    >
                      Average uptime - {report.average_uptime}%
                    </Typography>
                  </Box>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    sx={{
                      marginLeft: "auto",
                      marginRight: "20px",
                      "& button": {
                        padding: 0,
                        border: "none",
                        background: "transparent !important",
                      },
                      "& .MuiTouchRipple-root": {
                        display: "none !important",
                      },
                      "& img": {
                        width: "16px",
                      },
                      "& .love-icon-fill": {
                        display: "none",
                      },
                      "& .Mui-selected .love-icon-fill": {
                        display: "inline-block",
                      },
                      "& .Mui-selected .love-icon": {
                        display: "none",
                      },
                    }}
                  >
                    <ToggleButton value="list" aria-label="list">
                      {report.site_favorite === "True" ? (
                        <img className="love-icon" src={Loveiconfill} />
                      ) : (
                        <img className="love-icon" src={Loveicon} />
                      )}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      sx={{
                        backgroundColor: "#325AE7",
                        borderRadius: "7px",
                        border: "1px solid #325AE7",
                        boxShadow: "none",
                        padding: "3px 14px",
                        marginLeft: "12px",
                        fontSize: "14px",
                        textTransform: "inherit",
                        whiteSpace: "nowrap",
                        minWidth: "98px",
                        minHeight: "inherit",
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "#325AE7",
                        },
                      }}
                      onClick={() => handleSetWebsiteUrl(report.site_base_url)}
                    >
                      Download report
                    </Button>
                  </Box>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  minHeight: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>No Reports Found</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Preview Dialog */}
      <Dialog
        open={openModal}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            sx={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}
          >
            Website Report
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ReportPreview reportData={websiteReportsData} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ padding: "12px" }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ marginRight: "12px" }}
            >
              Cancel
            </Button>
            <PDFDownloadLink
              document={<ReportPDF reportData={websiteReportsData} />}
              fileName={websiteReportsData?.site_url + ".pdf"}
            >
              <Button variant="contained" sx={{ marginRight: "12px" }}>
                Download
              </Button>
            </PDFDownloadLink>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Report;
