import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import WebsiteIcon from "../../../Assets/Images/website-icon.svg";
import GoogleIcon from "../../../Assets/Images/google-icon.svg";
import AutoPilot from "../../../Assets/Images/bot.svg";
import Rollback from "../../../Assets/Images/Rollback.svg";
import Loveicon from "../../../Assets/Images/love-icon.svg";
import Disconnectedicon from "../../../Assets/Images/disconnected-icon.svg";
import ManualIcon from "../../../Assets/Images/icon_manual.svg";
import Globicon from "../../../Assets/Images/glob-icon.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Loveiconfill from "../../../Assets/Images/love-icon-fill.svg";
import Neweye from "../../../Assets/Images/new-eye.svg";
import InputIcon from "@mui/icons-material/Input";
import { useSiteDirectLoginMutation } from "../../../Features/siteSlice";
import Swal from "sweetalert2";

type WebsiteItemType = {
  title?: string;
  base_url: string;
  pagespeed_score?: string;
  active: boolean;
  auto_pilot?: boolean;
  version: string;
  open_update_count?: number;
  security_issue?: string;
  package_name?: string;
  date_added: string;
  favorite: boolean;
  image_file: string;
  labels?: string;
  last_backup: string | null;
  last_check: string | null;
  last_downtime: string | null;
  last_plugin_check: string | null;
  last_rollback: string | null;
  last_update: string | null;
  package_security?: boolean;
  php?: string;
  status?: string;
  timezone: string;
  url: string;
  last_active?: string;
};

export type SetFavoriteHandler = (website_url: string) => Promise<void>;

interface PropTypes {
  site: WebsiteItemType;
  handleSetFavorite: SetFavoriteHandler;
}

const WebsiteItem = ({ site, handleSetFavorite }: PropTypes) => {
  const [siteDirectLogin, { isLoading }] = useSiteDirectLoginMutation();

  const handleSiteDirectLogin = async (website_url: string) => {
    try {
      const siteLoginData = await siteDirectLogin(website_url).unwrap();
      if (siteLoginData) {
        window.open(siteLoginData.link, "_blank");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `Failed to Login`,
        text: `Failed to login to ${website_url}. Please try again later.`,
      });
    }
  };

  return (
    <>
      {site.active ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "16px 18px",
            justifyContent: "flex-start",
            backgroundColor: "initial",
            "@media (max-width: 767px)": {
              display: "block",
            },
          }}
        >
          <Box
            component={Link}
            to={`/sites/${site.base_url}/`}
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "260px",
              maxWidth: "260px",
              "& img": {
                width: "40px",
              },
              cursor: "pointer", // Add cursor pointer to indicate it's clickable
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <img src={site.image_file ?? WebsiteIcon} />
            <Box
              sx={{
                marginLeft: "12px",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "16px",
                }}
              >
                {site?.title ?? site.base_url}
              </Typography>
              <Typography
                sx={{
                  wordBreak: "break-all",
                  fontWeight: "normal",
                }}
              >
                {site?.base_url}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              "@media (max-width: 767px)": {
                padding: "15px 0",
                justifyContent: "flex-start",
              },
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "0 20px",
              justifyContent: "flex-start",
              "& > p": {
                borderRight: "1px solid #AFBDFA",
              },
              "& > p:first-child": {
                borderLeft: "1px solid #AFBDFA",
              },
            }}
          >
            <Typography
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
                marginTop: "3px",
                marginBottom: "3px",
                minWidth: "132px",
                "& img": {
                  marginRight: "5px",
                },
              }}
            >
              <img src={GoogleIcon} />
              {site.pagespeed_score} / 100
            </Typography>
            <Typography
              className={site.status === "OK" ? "green-status" : "red-status"}
              sx={{
                position: "relative",
                padding: "0 20px 0 40px",
                marginTop: "3px",
                marginBottom: "3px",
                minWidth: "160px",
              }}
            >
              {site.status === "OK" ? "Online" : "No Connection"}
            </Typography>
            <Typography
              sx={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                padding: "0 20px 0 20px",
                marginTop: "3px",
                minWidth: "123px",
                marginBottom: "3px",
                "& img": {
                  marginRight: "5px",
                },
              }}
            >
              {site.auto_pilot ? (
                <img src={AutoPilot} />
              ) : (
                <img src={ManualIcon} />
              )}
              {site.auto_pilot ? "AutoPilot" : "Manual"}
            </Typography>
            <Typography
              sx={{
                padding: "0 20px",
                marginTop: "3px",
                marginBottom: "3px",
                minWidth: "160px",
                "& img": {
                  marginRight: "5px",
                },
              }}
            >
              WordPress {site.version}
            </Typography>
            <Typography
              className="blue-status"
              sx={{
                position: "relative",
                padding: "0 20px 0 40px",
                marginTop: "3px",
                marginBottom: "3px",
                minWidth: "130px",
              }}
            >
              {site.open_update_count} Open Updates
            </Typography>
            <Typography
              className={
                site.security_issue === "OK" ? "green-status" : "red-status"
              }
              sx={{
                position: "relative",
                padding: "0 20px 0 40px",
                marginTop: "3px",
                marginBottom: "3px",
                minWidth: "208px",
              }}
            >
              {site.security_issue === "OK"
                ? "No Security Issues"
                : "Security Issues Found"}
            </Typography>
            <Typography
              sx={{
                padding: "0 20px",
                marginTop: "3px",
                marginBottom: "3px",
                minWidth: "125px",
                "& img": {
                  marginRight: "5px",
                },
              }}
            >
              {site.package_name} Package
            </Typography>
          </Box>
          <ToggleButton
            sx={{
              marginLeft: "auto",
              padding: "0",
              height: "36px",
              border: "1px solid #FAFBFF",
              width: "36px",
              "& img": {
                width: "16px",
              },
            }}
            value="list"
            aria-label="list"
            onClick={() => handleSetFavorite(site.base_url)}
          >
            {site.favorite ? (
              <img className="love-icon-fill" src={Loveiconfill} />
            ) : (
              <img className="love-icon" src={Loveicon} />
            )}
          </ToggleButton>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                backgroundColor: "transparent",
                color: "#325AE7",
                borderRadius: "0px",
                height: "36px",
                border: "1px solid #FAFBFF",
                width: "36px",
                boxShadow: "none",
                padding: "0",
                marginLeft: "17px",
                textTransform: "inherit",
                whiteSpace: "nowrap",
                minWidth: "auto",
                minHeight: "inherit",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "rgba(50, 90, 231, 0.04)",
                },
              }}
              onClick={() => handleSiteDirectLogin(site.base_url)}
            >
              <img src={Globicon} />
            </Button>
            <Box
              sx={{
                "& a": {
                  backgroundColor: "transparent",
                  color: "#325AE7",
                  borderRadius: "0px",
                  height: "36px",
                  border: "1px solid #FAFBFF",
                  width: "36px",
                  boxShadow: "none",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "12px",
                  textTransform: "inherit",
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                  minHeight: "inherit",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "rgba(50, 90, 231, 0.04)",
                  },
                  "& svg": {
                    position: "relative",
                    top: "3px",
                  },
                },
              }}
            >
              <NavLink to={`/sites/${site.base_url}/`}>
                <img src={Neweye} />
              </NavLink>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "16px 18px",
            justifyContent: "flex-start",
            background: "#F0F1FC",
            "@media (max-width: 767px)": {
              display: "block",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "260px",
              maxWidth: "260px",
              "& img": {
                width: "40px",
              },
              cursor: "pointer", // Add cursor pointer to indicate it's clickable
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <img src={site.image_file ?? WebsiteIcon} />
            <Box
              sx={{
                marginLeft: "12px",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "16px",
                }}
              >
                {site?.title ?? site.base_url}
              </Typography>
              <Typography
                sx={{
                  wordBreak: "break-all",
                  fontWeight: "normal",
                }}
              >
                {site?.base_url}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              paddingLeft: "50px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                "& img": {
                  marginRight: "5px",
                },
              }}
            >
              <img src={Disconnectedicon} />
              Last active on:{" "}
              {site?.last_active
                ? new Date(site.last_active).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                : "No data available"}
            </Typography>
            {/* <Button
              sx={{
                backgroundColor: "#E23A45",
                borderRadius: "7px",
                border: "1px solid #E23A45",
                boxShadow: "none",
                padding: "7px 19px",
                textTransform: "inherit",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#E23A45",
                },
              }}
            >
              Reconnect
            </Button> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default WebsiteItem;
