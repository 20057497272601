/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Tab, Tabs, Typography } from '@mui/material';
import * as React from 'react';
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import controlSlider from '../../../Assets/Images/control-slider.svg';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { styled } from '@mui/material/styles';
import BackupsTabFilter from '../BackupsTabFilter';

const StyledButton = styled('button')(
    ({ theme }) => `
  box-sizing: border-box;
  min-width: 144px;
  padding: 7px 12px;
  border-radius: 7px;
  text-align: left;
  line-height: 1.5;
  background: #ffffff;
  font-size: 16px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  font-family: Titillium Web;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  
  
  &.${selectUnstyledClasses.expanded} {
    &::after {
      float: right;
      transform: rotate(180deg);
    }
  }
  
  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
  box-sizing: border-box;
  padding: 6px;
  min-width: 200px;
  border-radius: 12px;
  font-family: Titillium Web;
  font-weight: 600;
  overflow: auto;
  outline: 0px;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  `,
);

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  
  &:last-of-type {
    border-bottom: none;
  }
  
  &.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  `,
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface BackupInfo {
    creation_date: string;
    current_theme: string;
    path: string;
    php_version: string;
    plugin_count: string;
    size: number;
    type: string;
    wp_version: string;
}

export type MakeBackupHandler = (ip_address: string) => Promise<void>;

interface PropTypes {
    backups: BackupInfo[];
    handleMakeBackup: MakeBackupHandler;
    website_url: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
  `;

const CustomSelect = React.forwardRef(function CustomSelect<
    TValue extends {},
    Multiple extends boolean,
>(
    props: SelectUnstyledProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const slots: SelectUnstyledProps<TValue, Multiple>['slots'] = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: StyledPopper,
        ...props.slots,
    };

    return <SelectUnstyled {...props} ref={ref} slots={slots} />;
}) as <TValue extends {}, Multiple extends boolean>(
    props: SelectUnstyledProps<TValue, Multiple> &
        React.RefAttributes<HTMLButtonElement>,
) => JSX.Element;

const Backuptab = ({ backups, handleMakeBackup, website_url }: PropTypes) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getBackupTypesCount = (type?: string) => {
        let count = 0;
        if (type) {
            count = backups.filter(backup => backup.type === type).length;
        } else {
            count = backups.length;
        }
        return count;
    }
    
    return (
        <>
            <Box sx={{
                padding: '30px 40px',
                '@media (max-width: 1400px)': {
                    padding: '25px 20px',
                },
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                    justifyContent: 'space-between',
                    '@media (max-width: 767px)': {
                        display: 'block',
                    },
                }}>
                    <Typography variant='h6' sx={{
                        fontSize: '18px',
                        marginBottom: '0',
                        '@media (max-width: 767px)': {
                            marginBottom: '10px',
                        },
                    }}>
                        Backup
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            borderRadius: ' 7px',
                            border: '1px solid #AFBDFA',
                            background: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '10px',
                        }}>
                            <Box sx={{
                                borderRight: '1px solid #AFBDFA',
                                width: '43px',
                                height: '43px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img src={controlSlider} />
                            </Box>
                            <Tabs
                                value={value}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                onChange={handleChange}
                                sx={{
                                    minHeight: 'auto',
                                    padding: '0 20px',
                                    '& .MuiTabs-indicator': {
                                        display: 'none',
                                    },
                                    '& button': {
                                        padding: '0',
                                        textTransform: 'capitalize',
                                        minHeight: 'auto',
                                        minWidth: 'auto',
                                    },
                                    '& button + button': {
                                        borderLeft: '1px solid #AFBDFA  ',
                                        marginLeft: '10px',
                                        paddingLeft: '10px',
                                    },
                                }}
                            >
                                <Tab label={`All (${getBackupTypesCount()})`} {...a11yProps(0)} />
                                <Tab label={`Automatic (${getBackupTypesCount('NORMAL')})`} {...a11yProps(1)} />
                                <Tab label={`Manual (${getBackupTypesCount('MANUAL')})`} {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        {/* <Box sx={{
                            '& > button': {
                                padding: '10px 16px',
                            }
                        }}>
                            <CustomSelect defaultValue={10}>
                                <StyledOption value={10}>Sort</StyledOption>
                                <StyledOption value={20}>Sort</StyledOption>
                            </CustomSelect>
                        </Box> */}
                    </Box>
                </Box>
                <TabPanel value={value} index={0}>
                    <BackupsTabFilter backups={backups} handleMakeBackup={handleMakeBackup} tabType="all" website_url={website_url} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <BackupsTabFilter backups={backups} handleMakeBackup={handleMakeBackup} tabType="automatic" website_url={website_url} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <BackupsTabFilter backups={backups} handleMakeBackup={handleMakeBackup} tabType="manual" website_url={website_url} />
                </TabPanel>
            </Box>

        </>
    );
};

export default Backuptab;