import { apiSlice } from "./apiSlice";

const SLICE_URL = "sites";

interface ISitesOverview {
  count: number;
  next: string | null;
  previous: string | null;
  results: WebsiteInfo[];
}

interface WebsiteInfo {
  title: string;
  url: string;
  active: boolean;
  base_url: string;
  favorite: boolean;
  version: string;
  date_added: string;
  labels: string;
  last_update: string | null;
  last_downtime: string | null;
  last_check: string | null;
  last_backup: string | null;
  last_plugin_check: string | null;
  last_rollback: string | null;
  timezone: string;
  normal_package: number;
  customer_package: string | null;
  image_file: string;
  status: string;
  site_optimization: any;
}

export const siteApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSites: builder.query<ISitesOverview, void>({
      query: () => `${SLICE_URL}/`,
      providesTags: (result) => [{ type: "Site", id: "LIST" }],
    }),
    searchSites: builder.query({
      query: (website_url) => `${SLICE_URL}/?search=`,
      providesTags: (result, error, id) => [{ type: "Site", id: "List" }],
    }),
    getSite: builder.query({
      query: (website_url) => `${SLICE_URL}/${website_url}/`,
      providesTags: (result, error, id) => [{ type: "Site", id: "List" }],
    }),
    addSite: builder.mutation({
      query: (siteData) => ({
        url: `${SLICE_URL}/`,
        method: "POST",
        body: {
          ...siteData,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    deleteSite: builder.mutation({
      query: (website_url) => ({
        url: `${SLICE_URL}/${website_url}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    scanSite: builder.mutation({
      query: (base_url) => ({
        url: `/scans/${base_url}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    setSiteSettings: builder.mutation({
      query: ({ base_url, site_settings }) => ({
        url: `/site-settings/${base_url}/`,
        method: "POST",
        body: {
          ...site_settings,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    getSiteSettings: builder.query({
      query: (website_url: string) => `/site-settings/${website_url}`,
      providesTags: (result, error, id) => [{ type: "Site", id }],
    }),
    siteDirectLogin: builder.mutation({
      query: (website_url) => ({
        url: `${SLICE_URL}/${website_url}/login/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    setFavorite: builder.mutation({
      query: (website_url) => ({
        url: `${SLICE_URL}/${website_url}/favorite/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    setAutopilot: builder.mutation({
      query: ({ website_url, autopilot }) => ({
        url: `${SLICE_URL}/${website_url}/set_autopilot/`,
        method: "POST",
        body: {
          autopilot: autopilot,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    getPlugins: builder.mutation({
      query: ({ website_url }) => ({
        url: `${SLICE_URL}/${website_url}/get_plugins/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    getThemes: builder.mutation({
      query: ({ website_url }) => ({
        url: `${SLICE_URL}/${website_url}/get_themes/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    runMalwareScan: builder.mutation({
      query: (website_url) => ({
        url: `scans/${website_url}/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    addWhitelistIp: builder.mutation({
      query: ({ website_url, whitelist }) => ({
        url: `${SLICE_URL}/${website_url}/add_whitelist_ip/`,
        method: "POST",
        body: {
          ...whitelist,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    makeBackup: builder.mutation({
      query: ({ website_url, type }) => ({
        url: `${SLICE_URL}/${website_url}/backup/`,
        method: "POST",
        body: {
          type: type,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    downloadBackupLink: builder.mutation({
      query: ({ website_url, path }) => ({
        url: `${SLICE_URL}/${website_url}/download_backup/`,
        method: "POST",
        body: {
          path: path, // <- Set 'path' as a property in 'body'
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    setTitleAdminPackage: builder.mutation({
      query: ({ website_url, url_link, data }) => ({
        url: `${SLICE_URL}/${website_url}/${url_link}/`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    changeTheme: builder.mutation({
      query: ({ website_url, theme_name }) => ({
        url: `${SLICE_URL}/${website_url}/change_theme/`,
        method: "POST",
        body: {
          theme_name: theme_name,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    installTheme: builder.mutation({
      query: ({ website_url, slug }) => ({
        url: `${SLICE_URL}/${website_url}/install_theme/`,
        method: "POST",
        body: {
          slug,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    updateTheme: builder.mutation({
      query: ({ website_url, theme_name }) => ({
        url: `${SLICE_URL}/${website_url}/update_themes/`,
        method: "POST",
        body: {
          theme_name,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    pauseThemeUpdate: builder.mutation({
      query: ({ website_url, theme_name }) => ({
        url: `${SLICE_URL}/${website_url}/pause_theme_update/`,
        method: "POST",
        body: {
          theme_name,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    updatePlugins: builder.mutation({
      query: ({ website_url, plugins }) => ({
        url: `${SLICE_URL}/${website_url}/update_plugins/`,
        method: "POST",
        body: {
          plugins,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    schedulePluginUpdate: builder.mutation({
      query: ({ website_url, schedule_info }) => ({
        url: `${SLICE_URL}/${website_url}/schedule_plugin_update/`,
        method: "POST",
        body: {
          ...schedule_info,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    togglePlugin: builder.mutation({
      query: ({ website_url, pluginToggleData }) => ({
        url: `${SLICE_URL}/${website_url}/toggle_plugin/`,
        method: "POST",
        body: pluginToggleData,
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    deletePlugin: builder.mutation({
      query: ({ website_url, plugin_name }) => ({
        url: `${SLICE_URL}/${website_url}/delete_plugin/`,
        method: "POST",
        body: {
          plugin_name,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    pausePluginUpdate: builder.mutation({
      query: ({ website_url, plugin_name }) => ({
        url: `${SLICE_URL}/${website_url}/pause_plugin_update/`,
        method: "POST",
        body: {
          ...plugin_name,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    installPlugin: builder.mutation({
      query: ({ website_url, slug }) => ({
        url: `${SLICE_URL}/${website_url}/install_plugin/`,
        method: "POST",
        body: {
          slug,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    checkBrokenLinks: builder.mutation({
      query: (website_url) => ({
        url: `${SLICE_URL}/${website_url}/broken_link_check/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    updateWordpress: builder.mutation({
      query: (website_url) => ({
        url: `${SLICE_URL}/${website_url}/update_wp/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    scheduleWPUpdate: builder.mutation({
      query: ({ website_url, delay }) => ({
        url: `${SLICE_URL}/${website_url}/schedule_wp_update/`,
        method: "POST",
        body: {
          delay,
        },
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    toggleSiteActive: builder.mutation({
      query: (website_url) => ({
        url: `${SLICE_URL}/${website_url}/toggle_active/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    testSiteConnection: builder.mutation({
      query: (website_url) => ({
        url: `${SLICE_URL}/${website_url}/debug_connection/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
    optimizationTrack: builder.mutation({
      query: (website_url) => ({
        url: `/${SLICE_URL}/${website_url}/optimize/`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Site", id: "LIST" }],
    }),
  }),
});

export const {
  useGetSitesQuery,
  useGetSiteQuery,
  useAddSiteMutation,
  useDeleteSiteMutation,
  useScanSiteMutation,
  useSetSiteSettingsMutation,
  useGetSiteSettingsQuery,
  useSiteDirectLoginMutation,
  useSetFavoriteMutation,
  useSetAutopilotMutation,
  useRunMalwareScanMutation,
  useAddWhitelistIpMutation,
  useMakeBackupMutation,
  useSetTitleAdminPackageMutation,
  useChangeThemeMutation,
  useInstallThemeMutation,
  useUpdateThemeMutation,
  usePauseThemeUpdateMutation,
  useUpdatePluginsMutation,
  useSchedulePluginUpdateMutation,
  useTogglePluginMutation,
  useDeletePluginMutation,
  useGetPluginsMutation,
  useGetThemesMutation,
  usePausePluginUpdateMutation,
  useInstallPluginMutation,
  useCheckBrokenLinksMutation,
  useDownloadBackupLinkMutation,
  useUpdateWordpressMutation,
  useScheduleWPUpdateMutation,
  useToggleSiteActiveMutation,
  useTestSiteConnectionMutation,
  useOptimizationTrackMutation,
} = siteApiSlice;
