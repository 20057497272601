import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Box,
  Typography,
  Divider,
  CardMedia,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { alpha, TableHead } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import moment from "moment";
import { rgba } from "polished";

import Report1Img from "../../Assets/Images/report-1.jpg";
import Report2Img from "../../Assets/Images/report-2.jpg";
import Report3Img from "../../Assets/Images/report-3.jpg";
import Report4Img from "../../Assets/Images/report-4.png";

interface IReportSettings {
  logo: string;
}

interface ICompanyDetails {
  url: string;
  logo_url: string;
}

interface IUpdates {
  name: string;
  new_version: string;
}

interface IBackups {
  creation_date: string;
  size: string;
}

interface IData {
  update_count: number;
  backup_count: number;
  uptime: string;
  website_security: boolean;
  plugin_security: boolean;
  theme_security: boolean;
  ip_blocked: number;
  antibot_fail: number;
  login_failed: number;
  spam_prevented: number;
  firewall_blocks: number;
  offense_triggered: number;
  bot_404: number;
  updates: IUpdates[];
  backups: IBackups[];
}

export interface IReportData {
  brand_color: string;
  contrast_color: string;
  site_url: string;
  month: number;
  year: number;
  image_url: string;
  intro_text: string;
  update_text: string;
  uptime_text: string;
  backup_text: string;
  report_settings: IReportSettings;
  company_details: ICompanyDetails;
  data: IData;
}

interface PropTypes {
  reportData: IReportData;
}

const getUptime = (uptime: string | undefined): string => {
  if (uptime) {
    const value = parseFloat(uptime);
    if (!isNaN(value)) {
      return value.toFixed(1);
    }
  }
  return "100.0"; // default value in case of undefined or invalid string
};

const ReportPreview = ({ reportData }: PropTypes) => {
  const reportContainerRef = React.useRef<HTMLDivElement | null>(null);
  const reportRibbonRef = React.useRef<HTMLDivElement | null>(null);
  const primaryColor = reportData.brand_color
    ? reportData.brand_color
    : "#000000"; // default to black if undefined
  const contrastColor = reportData.contrast_color
    ? reportData.contrast_color
    : "#FFFFFF"; // default to white if undefined

  useEffect(() => {
    window.addEventListener("resize", setBorders);
    return () => {
      window.removeEventListener("resize", setBorders);
    };
  }, []);

  useEffect(() => {
    if (!reportContainerRef.current) return;
    setBorders();
  }, [reportContainerRef]);

  useEffect(() => {
    if (!reportRibbonRef.current) return;
    setBorders();
  }, [reportRibbonRef]);

  const setBorders = () => {
    const containerElement = document.querySelector(
      ".report-container"
    ) as HTMLDivElement | null;
    if (!containerElement) return; // Null/undefined check

    let containerWidth = containerElement.clientWidth;
    let containerHeight = containerElement.clientHeight;
    let reportStyle = reportContainerRef.current?.style;
    let reportRibbonStyle = reportRibbonRef.current?.style;

    if (reportStyle) {
      reportStyle.width = containerWidth + "px";
    }

    if (reportRibbonStyle) {
      reportRibbonStyle.width = containerWidth + "px";
    }
  };

  const updatesCoverStyle = {
    height: "100vh",
    backgroundImage: `url(${Report2Img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    overflow: "hidden",
  };

  const bottomSlantingDivs = {
    position: "absolute",
    bottom: 0,
    height: "350px",
    backgroundColor: "white",
  };

  const reportRibbonStyles = {
    position: "absolute",
    height: "100px",
    bottom: "350px",
    backgroundColor: primaryColor + "80",
  };

  const reportSummary = {
    position: "absolute",
    right: 0,
    bottom: 0,
    textAlign: "right",
    paddingRight: "32px",
    paddingBottom: "32px",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: primaryColor + "80",
      color: "rgb(255, 255, 255)",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: primaryColor + "80",
      color: "rgb(255, 255, 255)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: primaryColor + "33",
      color: primaryColor,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Container>
      <Grid>
        <Paper>
          <Box
            sx={{ position: "relative", width: "100%" }}
            className="report-container"
          >
            <CardMedia
              component="img"
              alt="Cover Page image"
              image={Report1Img}
            />
            <Box
              sx={{
                position: "absolute",
                top: "64px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{}}>
                <CardMedia
                  component="img"
                  alt="Company Logo"
                  image={reportData?.report_settings?.logo}
                  style={{ maxWidth: "150px", marginLeft: "20px" }}
                />
              </Box>
              <Box
                style={{
                  textAlign: "right",
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 6,
                  backgroundColor: rgba(primaryColor, 0.7),
                  color: contrastColor,
                }}
              >
                <Typography style={{ fontSize: "22px", color: "#ffff" }}>
                  {reportData?.company_details?.url}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={bottomSlantingDivs}
              className="report-style"
              ref={reportContainerRef}
            ></Box>
            <Box sx={reportRibbonStyles} ref={reportRibbonRef}></Box>
            <Box sx={reportSummary}>
              <Typography sx={{ fontSize: "24px", mb: 2 }}>
                {reportData?.site_url}
              </Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 5,
                  backgroundColor: "#000133",
                  marginLeft: "55%",
                }}
              />
              <Typography
                sx={{
                  fontSize: "48px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#000133",
                }}
              >
                {reportData?.month} {reportData?.year}
              </Typography>
              <Divider
                flexItem
                sx={{
                  borderBottomWidth: 5,
                  backgroundColor: "#000133",
                  marginLeft: "55%",
                }}
              />
              <Typography
                sx={{
                  fontSize: "64px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: primaryColor,
                }}
              >
                Monthly Report
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Paper sx={{ padding: "32px" }}>
          <Box sx={{ maxWidth: "100%" }}>
            <CardMedia
              component="img"
              alt="Site Screenshot"
              image={reportData?.image_url}
            />
          </Box>

          <Box
            sx={{
              backgroundColor: primaryColor,
              color: "#000",
              padding: "24px",
            }}
          >
            <Typography>Beste klant,</Typography>
            <Typography>{reportData?.intro_text}</Typography>
          </Box>

          <Box sx={{ mt: "32px" }}>
            <Table>
              <TableBody>
                <StyledTableRow
                  sx={{
                    backgroundColor: primaryColor + "80",
                    color: "rgb(255, 255, 255)",
                  }}
                >
                  <StyledTableCell align="right" sx={{ color: "#fff" }}>
                    Updates
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ color: "#fff" }}>
                    {reportData?.data?.update_count}
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ color: "#fff" }}>
                    Aantal updates de afgelopen 30 dagen.
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow
                  sx={{
                    backgroundColor: primaryColor + "80",
                    color: "rgb(255, 255, 255)",
                  }}
                >
                  <StyledTableCell align="right" sx={{ color: primaryColor }}>
                    Backups
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ color: primaryColor }}>
                    {reportData?.data?.backup_count}
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ color: primaryColor }}>
                    Backups gemaakt de laatste 30 dagen.
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              mt: "32px",
              width: "100%",
            }}
          >
            <Box sx={{ maxWidth: "100%" }}>
              <Table>
                <TableHead>
                  <StyledTableRow
                    sx={{
                      backgroundColor: primaryColor + "80",
                      color: "rgb(255, 255, 255)",
                    }}
                  >
                    <StyledTableCell align="left">
                      Software Name
                    </StyledTableCell>
                    <StyledTableCell align="center">Version</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {reportData?.data?.updates?.map((update, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: key % 2 === 0 ? "#fff" : primaryColor,
                        }}
                      >
                        {update.name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: key % 2 === 0 ? "#fff" : primaryColor,
                        }}
                      >
                        {update.new_version}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ maxHeight: "100%" }}>
              <Box sx={{ backgroundColor: primaryColor, padding: "32px" }}>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "48px",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Updates
                </Typography>
              </Box>
              <Box sx={updatesCoverStyle}>
                <Box
                  sx={{
                    position: "absolute",
                    backgroundColor: alpha(primaryColor, 0.5),
                    color: "white",
                    padding: "32px",
                    top: "64px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <Typography>{reportData?.update_text}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Paper sx={{ padding: "32px" }}>
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <Box>
              <Box
                sx={{
                  backgroundColor: primaryColor,
                  padding: "32px",
                  marginLeft: "-32px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "48px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Uptime
                </Typography>
              </Box>
              <Box sx={{ pl: "32px", pt: "32px", marginRight: "-200px" }}>
                <Typography>{reportData?.uptime_text}</Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "150px",
                  height: "150px",
                  backgroundColor: alpha(primaryColor, 0.5),
                  borderRadius: "50%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "140px",
                    height: "140px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "130px",
                      height: "130px",
                      backgroundColor: primaryColor,
                      borderRadius: "50%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "90px",
                        height: "90px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "80px",
                          height: "80px",
                          backgroundColor: alpha(primaryColor, 0.5),
                          borderRadius: "50%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "70px",
                            height: "70px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        >
                          <Typography
                            sx={{
                              color: primaryColor,
                              fontSize: "24px",
                              fontWeight: "bold",
                            }}
                          >
                            {getUptime(reportData?.data?.uptime)}%
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ mt: "64px", display: "grid", gridTemplateColumns: "1fr 1fr" }}
          >
            <Box>
              <CardMedia
                component="img"
                alt="backups background"
                image={Report3Img}
                height="100%"
              />
            </Box>
            <Box>
              <Box
                sx={{
                  backgroundColor: primaryColor,
                  marginRight: "-32px",
                  paddingLeft: "32px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "48px",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Backups
                </Typography>
              </Box>
              <Box sx={{ pl: "32px", pr: "64px", pt: "32px" }}>
                <Typography>{reportData?.backup_text}</Typography>
              </Box>
              <Box sx={{ pr: "64px" }}>
                <Table>
                  <TableHead>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell align="left">Datum</StyledTableCell>
                      <StyledTableCell align="center">Grootte</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {reportData?.data?.backups?.map((backup, key) => (
                      <StyledTableRow key={key}>
                        <StyledTableCell
                          align="left"
                          sx={{
                            color: key % 2 === 0 ? "#fff" : primaryColor,
                          }}
                        >
                          {moment(backup.creation_date).format("DD/MM/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: key % 2 === 0 ? "#fff" : primaryColor,
                          }}
                        >
                          {backup.size}MB
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ mt: "64px", display: "grid", gridTemplateColumns: "1fr 1fr" }}
          >
            <Box>
              <Box
                sx={{
                  backgroundColor: primaryColor,
                  marginLeft: "-32px",
                  pl: "32px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "48px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Security
                </Typography>
              </Box>
              <Box sx={{ pl: "64px", pt: "32px" }}>
                <Table>
                  <TableBody>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        Website security
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        {reportData?.data?.website_security
                          ? "Security issues resolved"
                          : "No security issues found"}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        Plugin security
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        {reportData?.data?.plugin_security
                          ? "Security issues resolved"
                          : "No security issues found"}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        Theme security
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        {reportData?.data?.theme_security
                          ? "Security issues resolved"
                          : "No security issues found"}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        IP Blocked
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        {reportData?.data?.ip_blocked}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        Antibot fail
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        {reportData?.data?.antibot_fail}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        Login failed
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        {reportData?.data?.login_failed}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        Spam prevented
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        {reportData?.data?.spam_prevented}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        Firewall blocks
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        {reportData?.data?.firewall_blocks}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        Offense Triggered
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        {reportData?.data?.offense_triggered}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{
                        backgroundColor: primaryColor + "80",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      <StyledTableCell
                        align="left"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        Bot detection 404
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: primaryColor,
                        }}
                      >
                        {reportData?.data?.bot_404}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            </Box>

            <Box sx={{ backgroundColor: primaryColor }}>
              <CardMedia
                component="img"
                alt="security background"
                image={Report4Img}
                height="100%"
              />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Container>
  );
};

export default ReportPreview;
