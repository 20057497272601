/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { useOptimizationTrackMutation } from "../../../Features/siteSlice";

const DatabaseOptimizationTab = ({
  optimizations,
  siteBaseUrl,
}: {
  optimizations: any;
  siteBaseUrl: string;
}) => {
  const [optimizationTrack] = useOptimizationTrackMutation();

  const sendOptimizationData = () => {
    // Send a separate request for each checked URL
    optimizationTrack(siteBaseUrl)
      .unwrap()
      .then((payload) => {
        console.log(`Optimization successful for ${siteBaseUrl}`, payload);
      })
      .catch((error) => {
        console.error(`Error in optimizing ${siteBaseUrl}:`, error);
      });
  };

  return (
    <Box
      sx={{
        padding: "30px 40px",
        "@media (max-width: 1400px)": {
          padding: "25px 20px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
          }}
        >
          Database Optimization
        </Typography>
        <Button
          sx={{
            backgroundColor: "#325AE7",
            borderRadius: "7px",
            border: "1px solid #325AE7",
            boxShadow: "none",
            padding: "3px 14px",
            fontSize: "14px",
            textTransform: "inherit",
            minHeight: "inherit",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#325AE7",
            },
          }}
          onClick={sendOptimizationData}
        >
          Optimize Site
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Auto Draft Posts
              </Typography>
              <Typography>
                will be removed ({optimizations?.auto_draft_posts}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Comment Meta Data
              </Typography>
              <Typography>
                will be cleaned ({optimizations?.comment_meta_data}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Expired Transient Options
              </Typography>
              <Typography>
                will be removed ({optimizations?.expired_transient_options}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Database tables
              </Typography>
              <Typography>
                Tables will be optimized ({optimizations?.optimize_tables}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Pingbacks
              </Typography>
              <Typography>
                will be removed ({optimizations?.pingbacks}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Post Meta Data
              </Typography>
              <Typography>
                will be optimized ({optimizations?.post_meta_data}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Post Revision
              </Typography>
              <Typography>
                older than 14 days will be removed (
                {optimizations?.post_revisions}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Spam and Trashed Comments
              </Typography>
              <Typography>
                will be removed ({optimizations?.spam_and_trashed_comments}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Trackbacks
              </Typography>
              <Typography>
                will be optimized ({optimizations?.trackbacks}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Trashed Posts
              </Typography>
              <Typography>
                will be removed ({optimizations?.trashed_posts}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Unapproved Comments
              </Typography>
              <Typography>
                will be removed ({optimizations?.unapproved_comments}).
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Box
            sx={{
              padding: "18px",
              background: "#ffffff",
              borderRadius: "10px",
              border: "1px solid #AFBDFA",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            {/* <Checkbox {...label} sx={{ padding: 0, }} /> */}
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Expired User Meta Data
              </Typography>
              <Typography>
                will be cleaned ({optimizations?.user_meta_data}).
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DatabaseOptimizationTab;
