/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, Tab, Tabs, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import controlSlider from '../../../Assets/Images/control-slider.svg'
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import ThemeBox from '../ThemeBox';
import CheckIcons from '../../../Assets/Images/check-Icons.svg';
import moment from 'moment';
import { defaultSecurityLogs, useGetWebsiteSecurityLogsQuery } from '../../../Features/securitySlice';
import { IPaginationState, initialPaginationState } from '../../../Views/Security';
import Loader from '../Loader';
import AuditSecurityTabPanel from '../AuditSecurityTabPanel';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const StyledButton2 = styled('button')(
    ({ theme }) => `
  box-sizing: border-box;
  min-width: 95px;
  padding: 11px 12px;
  border-radius: 7px;
  margin-right: 10px;
  text-align: left;
  line-height: 1.5;
  background: #325AE7;
  font-size: 14px;
  border: 1px solid #325AE7;
  color: #ffffff;
  font-family: Titillium Web;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  
  
  &.${selectUnstyledClasses.expanded} {
    &::after {
      float: right;
      transform: rotate(180deg);
    }
  }
  
  &::after {
    content: '▾';
    margin-left:5px;
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
    box-sizing: border-box;
    padding: 6px;
    min-width: 200px;
    border-radius: 12px;
    font-family: Titillium Web;
    font-weight: 600;
    overflow: auto;
    outline: 0px;
    background: #ffffff;
    font-size: 14px;
    border: 1px solid #AFBDFA;
    color: #1B1D29;
    `,
);

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    
    &:last-of-type {
      border-bottom: none;
    }
    
    &.${optionUnstyledClasses.selected} {
      background-color: #325AE7;
      color: #ffffff;
    }
    
    &.${optionUnstyledClasses.highlighted} {
      background-color: #325AE7;
      color: #ffffff;
    }
    
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: #325AE7;
      color: #ffffff;
    }
    `,
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface PropTypes {
    base_url: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StyledPopper = styled(PopperUnstyled)`
    z-index: 1;
    `;

const CustomSelect2 = React.forwardRef(function CustomSelect2<
    TValue extends {},
    Multiple extends boolean,
>(
    props: SelectUnstyledProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const slots: SelectUnstyledProps<TValue, Multiple>['slots'] = {
        root: StyledButton2,
        listbox: StyledListbox,
        popper: StyledPopper,
        ...props.slots,
    };

    return <SelectUnstyled {...props} ref={ref} slots={slots} />;
}) as <TValue extends {}, Multiple extends boolean>(
    props: SelectUnstyledProps<TValue, Multiple> &
        React.RefAttributes<HTMLButtonElement>,
) => JSX.Element;

const AuditTrailsTab = ({ base_url }: PropTypes) => {
    const [value, setValue] = React.useState(0);
    const [pagination, setPagination] = React.useState<IPaginationState>(initialPaginationState);
    const [event, setEvent] = React.useState('');
    const [query, setQuery] = React.useState('');

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { data: websiteLogs= defaultSecurityLogs, isLoading, isFetching, refetch } = useGetWebsiteSecurityLogsQuery({website: base_url, queryParams: query});

    React.useEffect(() => {
        setPagination((prevState) => ({
          ...prevState,
          totalItems: websiteLogs.count,
        }));
    }, [websiteLogs.count]);
    
    const handlePageChange = (pageNumber: number) => {
        setPagination((prevState) => ({
            ...prevState,
            currentPage: pageNumber,
        }));
        if (event) {
            setQuery(`page=${pagination.currentPage}&event=${event}`);
            refetch();
        } else {
            setQuery(`page=${pagination.currentPage}`);
            refetch();
        }
    };
    
    const handleEventChange = (event: string) => {
        setEvent(event);
        setPagination((prevState) => ({
            ...prevState,
            currentPage: 1,
        }));
        if (event) {
            setQuery(`page=${pagination.currentPage}&event=${event}`);
            refetch();
        } else {
            setQuery(`page=${pagination.currentPage}`);
            refetch();
        }
    }

    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginBottom: '10px',
            }}>
                <Typography variant='h6' sx={{
                    fontSize: '18px',
                    marginRight: '17px',
                }}>
                    Audit Trails
                </Typography>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    margin: '5px 0',
                }}>
                    <Box sx={{
                        borderRadius: ' 7px',
                        border: '1px solid #AFBDFA',
                        background: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}>
                        <Box sx={{
                            borderRight: '1px solid #AFBDFA',
                            width: '43px',
                            height: '43px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <img src={controlSlider} />
                        </Box>
                        <Tabs
                            value={value}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            onChange={handleChange}
                            sx={{
                                minHeight: 'auto',
                                padding: '0 20px',
                                '& .MuiTabs-indicator': {
                                    display: 'none',
                                },
                                '& button': {
                                    padding: '0',
                                    textTransform: 'capitalize',
                                    minHeight: 'auto',
                                    minWidth: 'auto',
                                },
                                '& button + button': {
                                    borderLeft: '1px solid #AFBDFA  ',
                                    marginLeft: '10px',
                                    paddingLeft: '10px',
                                },
                            }}
                        >
                            <Tab label="All" {...a11yProps(0)} onClick={() => handleEventChange('') } />
                            <Tab label="Login Block" {...a11yProps(1)} onClick={() => handleEventChange('login_block') } />
                            <Tab label="IP Block" {...a11yProps(2)} onClick={() => handleEventChange('ip_block_auto') } />
                        </Tabs>
                    </Box>
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <AuditSecurityTabPanel 
                    logs={websiteLogs?.results}
                    handlePageChange={handlePageChange}
                    loadingLogs={isLoading}
                    isFetching={isFetching}
                    pagination={pagination}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AuditSecurityTabPanel 
                    logs={websiteLogs?.results}
                    handlePageChange={handlePageChange}
                    loadingLogs={isLoading}
                    isFetching={isFetching}
                    pagination={pagination}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AuditSecurityTabPanel 
                    logs={websiteLogs?.results}
                    handlePageChange={handlePageChange}
                    loadingLogs={isLoading}
                    isFetching={isFetching}
                    pagination={pagination}
                />
            </TabPanel>
        </Box>
    );
};

export default AuditTrailsTab;
