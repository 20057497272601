import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthenticatedUser } from "../Views/Login";
import { baseUrlDev, baseUrlPro } from "../Config/urlConfig";

const baseURL =
    window.location.hostname.includes("dev") || window.location.hostname.includes("localhost")
        ? baseUrlDev
        : baseUrlPro;

const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    credentials: 'include', // you might want to review if 'include' is necessary
    prepareHeaders: async (headers, { getState }) => {
        const origin = window.location.origin;
        headers.set('Origin', origin); // Set the Origin header
        headers.set('Accept', 'application/json');

        const storedUser = localStorage.getItem('wpAuthenticatedUser');
        const localUser: AuthenticatedUser | null = storedUser ? JSON.parse(storedUser) : null;
        const accessToken = localUser?.refresh_token; // Changed to access_token

        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
    }
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 403) {
        // logout user by removing localstorage user
        localStorage.removeItem("wpAuthenticatedUser");
        // Optionally redirect to login page or handle re-authentication
    }

    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Dashboard', 'Site', 'Security', 'Log', 'Setting', 'Account', 'Report', 'Whitelist'
    ],
    refetchOnMountOrArgChange: 5,
    refetchOnFocus: true,
    endpoints: builder => ({})
});
