import React from 'react';

import { styled } from '@mui/material/styles';
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import Switch, { SwitchProps } from '@mui/material/Switch';

export const StyledButton = styled('button')(
    ({ theme }) => `
        box-sizing: border-box;
        min-width: 144px;
        padding: 7px 12px;
        border-radius: 7px;
        text-align: left;
        line-height: 1.5;
        background: #ffffff;
        font-size: 16px;
        border: 1px solid #AFBDFA;
        color: #1B1D29;
        font-family: Titillium Web;
        font-weight: 600;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;
        &.${selectUnstyledClasses.expanded} {
            &::after {
                float: right;
                transform: rotate(180deg);
            }
        }
        &::after {
            content: '▾';
            float: right;
        }
    `,
);

export const StyledListbox = styled('ul')(
    ({ theme }) => `
  box-sizing: border-box;
  padding: 6px;
  min-width: 200px;
  border-radius: 12px;
  font-family: Titillium Web;
  font-weight: 600;
  overflow: auto;
  outline: 0px;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  `,
);

export const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  
  &:last-of-type {
    border-bottom: none;
  }
  
  &.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted} {
    background-color: #325AE7;
    color: #ffffff;
  }
  
  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  `,
);

export const AntSwitch = styled(Switch)(({ theme }) => ({
    width: '40px',
    height: '20px',
    padding: 0,
    display: 'flex',
    borderRadius: '100px',
    background: '#BECBFF',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        color: '#14161F',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#325AE7',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '',
        width: '16px',
        height: '16px',
        borderRadius: '100px',
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));
  