import * as React from 'react';
import { Checkbox, IconButton, InputBase, Typography, Radio, RadioGroup } from '@mui/material';
import { Box } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '../../../Assets/Images/slider.svg'
// import './style.css';

interface EventTargetValue {
	target: {
	  value: React.SetStateAction<string>;
	};
}

interface HandleFavoriteFilterChange {
	(event: EventTargetValue): void;
}

interface HandleSortFilterChange {
	(event: EventTargetValue): void;
}

interface HandleSearchQueryChange {
	(event: EventTargetValue): void;
}

interface PropTypes {
	handleFavoriteFilterChange: HandleFavoriteFilterChange;
	handleSortFilterChange: HandleSortFilterChange;
	handleSearchQueryChange: HandleSearchQueryChange;
	favoriteFilter: string;
	sortFilter: string;
	searchQuery: string;
}

const ReportsFilter = ({ 
	handleFavoriteFilterChange, 
	handleSortFilterChange, 
	favoriteFilter, 
	sortFilter,
	searchQuery,
	handleSearchQueryChange
}: PropTypes) => {
	return (
		<Box>
            <Box sx={{
				padding: '20px 20px 20px 40px',
				borderBottom: '1px solid #AFBDFA',
            }}>
				<Typography
					variant='h6'
					sx={{
						marginBottom: '5px'
					}}
				>
					Search
				</Typography>
				<Box sx={{
					// padding: '6px 20px 6px 20px',
					// backgroundColor: '#F4F5FE',
					// borderRadius: '8px',
					borderBottom: '1px solid #707070',
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					'@media (max-width: 600px)': {
						display: 'none',
					},
				}}>
					<IconButton
						type="button"
						aria-label="search"
						sx={{
							padding: '0',
							position: 'absolute',
							left: '0',
							top: '7px',
						}}
					>
						<SearchIcon />
					</IconButton>
					<InputBase
						sx={{
							fontSize: '16px',
							color: '#525566',
							width: '100%',
							marginLeft: '0',
							'& input': {
								padding: '5px 20px 5px 30px',
							}
						}}
						type="search"
						placeholder="Search by Site"
						inputProps={{ 'aria-label': 'Search by Site' }}
						value={searchQuery}
                		onChange={(event) => handleSearchQueryChange(event)}
					/>

				</Box>
            </Box>
            <Box sx={{
				padding: '20px 40px',
				borderBottom: '1px solid #AFBDFA',
            }}>
				<Typography
					variant='h6'
					sx={{
						marginBottom: '15px'
					}}
				>
					View
				</Typography>
				<RadioGroup
					value={favoriteFilter}
					onChange={handleFavoriteFilterChange}
					sx={{
					'& .MuiFormControlLabel-root': {
						marginBottom: '10px',
					},
					}}
				>
					<FormControlLabel value="all" control={<Radio />} label="All" />
					<FormControlLabel value="favorite" control={<Radio />} label="Favorite" />
				</RadioGroup>
            </Box>
            <Box sx={{
              padding: '20px 40px',
              borderBottom: '1px solid #AFBDFA',
            }}>
				<Typography
					variant='h6'
					sx={{
						marginBottom: '15px'
					}}
				>
					Sort
				</Typography>
				<RadioGroup
					value={sortFilter}
					onChange={handleSortFilterChange}
					sx={{
					'& .MuiFormControlLabel-root': {
						marginBottom: '10px',
					},
					}}
				>
					<FormControlLabel value="aToZ" control={<Radio />} label="A to Z" />
					<FormControlLabel value="zToA" control={<Radio />} label="Z to A" />
					<FormControlLabel value="newestFirst" control={<Radio />} label="Newest first" />
					<FormControlLabel value="oldestFirst" control={<Radio />} label="Oldest first" />
				</RadioGroup>
            </Box>
        </Box>
	)
}

export default ReportsFilter