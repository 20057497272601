import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Checkbox, Grid, IconButton, InputBase, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
import './style.css'
import Plusicon from '../../Assets/Images/plus-icon.svg'
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import GeneralSettingsTab from '../../Components/UI/GeneralSettingsTab';
import PaymentSettingsTab from '../../Components/UI/PaymentSettingsTab';
import AccountSettingsTab from '../../Components/UI/AccountSettingsTab';
import { defaultInitialData, useGetSettingsQuery, ISettingsData } from '../../Features/settingSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Setting = () => {
  const [value1, setValue1] = React.useState(0);

  const { data: settingsData = defaultInitialData } = useGetSettingsQuery();

  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };

  return (
    <Box>
      <Box sx={{
        padding: '20px 40px',
        borderBottom: '1px solid #AFBDFA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#FAFBFF',
        position: 'sticky',
        top: '0',
        zIndex: '2',
        '@media (max-width: 1400px)': {
          padding: '20px 20px',
        },
        '@media (max-width: 1199px)': {
          position: 'relative',
        },
        '@media (max-width: 767px)': {
          display: 'block'
        },
      }}>
        <Typography
          variant="h1"
          sx={{
            '@media (max-width: 767px)': {
              marginBottom: '15px',
            },
          }}
        >Settings</Typography>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          '@media (max-width: 400px)': {
            display: 'block',
          },
        }}>
          <Box sx={{
            marginLeft: '0',
            '@media (max-width: 400px)': {
              marginLeft: '0',
              marginTop: '15px',
            },
          }}>
            <Button  sx={{
              backgroundColor: '#325AE7',
              borderRadius: '7px',
              border: '1px solid #325AE7',
              boxShadow: 'none',
              padding: '7px 19px',
              fontSize: '15px',
              textTransform: 'inherit',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#325AE7',
              },
              '& img': {
                marginRight: '10px',
              },
              '& a': {
                color: '#ffffff',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
              }
            }}>
              <Link to="/add/">
                <img src={Plusicon} />
                Add a website
              </Link>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        '@media (max-width: 1199px)': {
            display: 'block'
          },
      }}>
        <Box sx={{
          minWidth: '240px',
          minHeight: 'calc(100vh - 185px)',
          borderRight: '1px solid #AFBDFA',
          paddingLeft: '40px',
          paddingTop: '20px',
        //   position: 'sticky',
        //   top: '0px',
          '@media (max-width: 1199px)': {
            minHeight: 'auto',
            paddingLeft: '20px',
            paddingBottom: '20px',
            borderBottom: '1px solid #AFBDFA',
          },
        }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value1}
            onChange={handleChange1}
            aria-label="Vertical tabs example"
            sx={{
              position: 'sticky',
              top: '105px',
              '& .MuiTabs-indicator': {
                right: 'auto',
                left: 0,
              },
              '& button': {
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'capitalize',
                minHeight: 'auto',
                textAlign: 'left !important',
                minWidth: 'auto',
                justifyContent: 'flex-end !important',
                alignItems: 'flex-start !important',
                borderLeft: '2px solid #fff',
                padding: '6px 0 6px 20px',
              },
            }}
          >
            {/* <Tab label="General" {...a11yProps(0)} /> */}
            <Tab label="General" {...a11yProps(0)} />
            <Tab label="Billing" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box sx={{
          width: '100%',
        }}>
          {/* <TabPanel value={value1} index={0}>
            <GeneralSettingsTab />
          </TabPanel> */}
          <TabPanel value={value1} index={0}>
            <AccountSettingsTab accountSettings={settingsData?.account} settingsData={settingsData}/>
          </TabPanel>
          <TabPanel value={value1} index={1}>
          <PaymentSettingsTab settingsData={settingsData}/>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default Setting;
