import React from 'react';
import { usePagination, DOTS } from './usePagination';

import './Pagination.css';

interface PaginationProps {
	handlePageChange: (pageNumber: number) => void;
	totalCount: number;
	siblingCount?: number;
	currentPage: number;
	pageSize?: number;
}

const Pagination: React.FC<PaginationProps> = ({
	handlePageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize = 10,
}) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	// If there are less than 2 pages in pagination range, we shall not render the component
	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		handlePageChange(currentPage + 1);
	};

	const onPrevious = () => {
		handlePageChange(currentPage - 1);
	};

	let lastPage = paginationRange[paginationRange.length - 1];
	return (
		<ul className='pagination-container'>
			{/* Left navigation arrow */}
			<li
				className={`pagination-item ${currentPage === 1 ? 'disabled' : ''}`}
				onClick={onPrevious}
			>
				<div className='arrow left' />
			</li>
			{paginationRange.map((pageNumber) => {
				// If the pageItem is a DOT, render the DOTS unicode character
				if (pageNumber === DOTS) {
				return (
					<li key={pageNumber} className='pagination-item dots'>
						&#8230;
					</li>
				);
				}

				// Render our Page Pills
				return (
				<li
					key={pageNumber}
					className={`pagination-item ${
						pageNumber === currentPage ? 'active disabled' : ''
					}`}
					onClick={() => handlePageChange(pageNumber as number)}
				>
					{pageNumber}
				</li>
				);
			})}
			{/* Right Navigation arrow */}
			<li
				className={`pagination-item ${
					currentPage === lastPage ? 'disabled' : ''
				}`}
				onClick={onNext}
			>
				<div className='arrow right' />
			</li>
		</ul>
	);
};

export default Pagination;