/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Checkbox, Grid, IconButton, InputBase, Typography } from '@mui/material';
import * as React from 'react';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDeleteWhitelistedIpsMutation } from '../../../Features/securitySlice';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export interface WhitelistedIPs {
    address: string;
    date: string;
}

export type HandleWhitelistIpHandler = (ip_address: string) => Promise<void>;

interface PropTypes {
    whitelisted_ips: WhitelistedIPs[];
    handleWhitelistIp: HandleWhitelistIpHandler;
}

const BlockIpsTab = ({ whitelisted_ips, handleWhitelistIp }: PropTypes) => {
    const [ipAddress, setIpAddress] = React.useState<string>('');
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    const [currentIp, setCurrentIp] = React.useState<string>('');

    const [deleteWhitelistedIps] = useDeleteWhitelistedIpsMutation();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIpAddress(event.target.value);
    };

    const getCurrentIP = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setCurrentIp(res.data.ip);
    };

    React.useEffect(() => {
        getCurrentIP();
    }, []);

    function isIPWhitelisted(currentIp: string): boolean {
        return whitelisted_ips.some((ip) => ip.address === currentIp);
    }

    const filteredIPs = React.useMemo(() => {
        if (!whitelisted_ips) return [];
    
        let allWhitelistedIps = [...whitelisted_ips];
    
        // Adding a null check for 'searchQuery' before calling 'toLowerCase'
        const lowerCaseSearchQuery = searchQuery ? searchQuery.toLowerCase() : '';
        allWhitelistedIps = allWhitelistedIps.filter((ip) =>
            // Adding a null check for 'ip.address' before calling 'toLowerCase' and 'includes'
            ip.address && ip.address.toLowerCase().includes(lowerCaseSearchQuery)
        );
        
        return allWhitelistedIps;
    }, [whitelisted_ips, searchQuery]);

    const handleDeleteWhitelistIp = (ipAddress: string) => {
        Swal.fire({
            title: `Are you sure you want to delete Ip Address ${ipAddress}?`,
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!',
            confirmButtonColor: 'red',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    await deleteWhitelistedIps(ipAddress);
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: `Failed to Delete`,
                        text: `Deleting IP Address ${ipAddress} could not be completed. Please try again later.`
                    })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async(result) => {
            if (result.isConfirmed) {
              Swal.fire({
                icon: 'success',
                confirmButtonColor: '#325AE7',
                title: `IP Address Deleted Successfully!`,
                text: `IP Address ${ipAddress} was removed from your whitelist successfully!`
              })
            }
        })
    };

    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Grid
                //  sx={{marginTop: '60px'}} 
                container
                spacing={2}
            >
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                    <Typography variant='h6' sx={{
                        fontSize: '18px',
                        marginBottom: '30px',
                    }}>
                        Whitelist IPs
                    </Typography>
                    <Box sx={{
                        position: 'relative',
                    }}>
                        <InputBase
                            sx={{
                                width: '100%',
                                '& input': {
                                    padding: '0 165px 0 18px',
                                    fontSize: '16px',
                                    color: '#525566',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '7px',
                                    border: '1px solid #AFBDFA',
                                    minHeight: '44px',
                                }
                            }}
                            placeholder="Enter your IP address"
                            type='text'
                            inputProps={{ 'aria-label': 'Search IP' }}
                            value={ipAddress}
                            onChange={handleInputChange}
                        />
                        <Button
                            type="button"
                            aria-label="search"
                            sx={{
                                position: 'absolute',
                                right: '0',
                                top: '0',
                                height: '100%',
                                backgroundColor: '#325AE7',
                                borderRadius: '7px',
                                border: '1px solid #325AE7',
                                boxShadow: 'none',
                                padding: '7px 19px',
                                textTransform: 'inherit',
                                '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: '#325AE7',
                                },
                            }}
                            onClick={() => handleWhitelistIp(ipAddress)}
                        >
                            Add to whitelist
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '24px',
                        }}
                    >
                        <Typography>Your current IP is: {currentIp}</Typography>
                    </Box>
                    <Button
                        disabled={isIPWhitelisted(currentIp)}
                        sx={{
                            backgroundColor: '#ffffff',
                            marginTop: '20px',
                            borderRadius: '7px',
                            border: '1px solid #AFBDFA',
                            boxShadow: 'none',
                            padding: '7px 19px',
                            marginRight: '15px',
                            color: '#1B1D29',
                            textTransform: 'inherit',
                            '&:hover': {
                                boxShadow: 'none',
                                color: '#ffffff',
                                backgroundColor: '#325AE7',
                            },
                            '& img': {
                                marginRight: '10px',
                            }
                        }}
                        onClick={() => handleWhitelistIp(currentIp)}
                    >
                        { isIPWhitelisted(currentIp) ? 'Your IP is Whitelisted' : 'Whitelist My IP address' }
                        
                    </Button>
                </Grid>
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginBottom: '15px',
                    }}>
                        <Box sx={{
                            padding: '0',
                            backgroundColor: '#ffffff',
                            borderRadius: '5px',
                            border: '1px solid #AFBDFA',
                            overflow: 'hidden',
                            display: 'flex',
                            marginRight: '15px',
                            alignItems: 'center',
                            '@media (max-width: 600px)': {
                                display: 'none',
                            },
                        }}>
                            <IconButton
                                type="button"
                                aria-label="search"
                                sx={{
                                    padding: '9px 10px',
                                    color: '#325AE7',
                                    borderRadius: ' 0',
                                    borderRight: '1px solid #AFBDFA',
                                    backgroundColor: '#ffffff',
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                sx={{
                                    fontSize: '16px',
                                    color: '#525566',
                                    minWidth: '280px',
                                    '& input': {
                                        padding: '0 15px',
                                    }
                                }}
                                placeholder="Search IP"
                                type='search'
                                inputProps={{ 'aria-label': 'Search IP' }}
                                value={searchQuery}
                                onChange={(event) => setSearchQuery(event.target.value)}
                            />

                        </Box>
                        <Button sx={{
                            backgroundColor: '#325AE7',
                            borderRadius: '7px',
                            border: '1px solid #325AE7',
                            boxShadow: 'none',
                            padding: '7px 19px',
                            textTransform: 'inherit',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#325AE7',
                            },
                            '& img': {
                                marginRight: '10px',
                            }
                        }}>
                            Remove all
                        </Button>
                    </Box>
                    <Box sx={{
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        background: '#fff',
                        '& > div + div': {
                            borderTop: '1px solid #AFBDFA',
                        }
                    }}>
                        {
                            filteredIPs?.length > 0
                                ? filteredIPs.map((whitelisted_ip: WhitelistedIPs, index: number) => (
                                    <Box key={index} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '17px 18px 16px 18px',
                                    }}>
                                        <Typography variant='h6' sx={{minWidth: '200px',maxWidth: '200px',wordBreak: 'break-all'}}><Checkbox {...label} sx={{ padding: 0, marginRight: '3px' }} />{whitelisted_ip.address}</Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: '10px',
                                            '& p + p': {
                                                marginLeft: '15px',
                                            }
                                        }}>
                                            <Typography>
                                                {moment(whitelisted_ip.date).format('MMMM Do YYYY, h:mm:ss a')}
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: 'auto',
                                            paddingLeft: '15px',
                                        }}>
                                            <Button 
                                                sx={{
                                                    backgroundColor: '#325AE7',
                                                    borderRadius: '7px',
                                                    border: '1px solid #325AE7',
                                                    boxShadow: 'none',
                                                    padding: '3px 14px',
                                                    marginLeft: '12px',
                                                    fontSize: '14px',
                                                    textTransform: 'inherit',
                                                    minHeight: 'inherit',
                                                    '&:hover': {
                                                        boxShadow: 'none',
                                                        backgroundColor: '#325AE7',
                                                    },
                                                }}
                                                onClick={() => handleDeleteWhitelistIp(whitelisted_ip.address)}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    </Box>
                                ))
                                : <Box>
                                    <Typography variant="h6">No whitelisted IPs</Typography>
                                </Box>
                        }
                    </Box>
                </Grid>
            </Grid>

        </Box>
    );
};

export default BlockIpsTab;
