/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import Coreicon from '../../../Assets/Images/core-icon.svg'
import CheckIcons from '../../../Assets/Images/check-Icons.svg'
import CloseIcons from '../../../Assets/Images/CloseIcons.svg'
import Vulnerable from '../../../Assets/Images/Vulnerable.svg'
import Themesicon from '../../../Assets/Images/themes-icon.svg'
import Pluginsicon from '../../../Assets/Images/plugins-icon.svg'
import ChecksumFailedicon from '../../../Assets/Images/Checksum-Failed-icon.svg'
import UnrecognizedFileicon from '../../../Assets/Images/Unrecognized-File-icon.svg'
import Abandonedicon from '../../../Assets/Images/abandoned-icon.svg'
import { useRunMalwareScanMutation } from '../../../Features/siteSlice';
import Swal from 'sweetalert2';
import moment from 'moment';

export interface ScanResult {
    abandoned: boolean;
    checksum_fail: boolean;
    core: boolean;
    path: string;
    plugin: boolean;
    scan_date: string;
    site_base_url: string;
    theme: boolean;
    unrecognized: boolean;
    vulnerable: boolean;
}

export interface LatestScan {
    scan_results: ScanResult[];
    text: null;
    scan_date: string;
    malware_found: boolean;
    unrecognized_files: boolean;
    checksum_fail: boolean;
    vulnerability_found: boolean;
    abandoned_plugin: boolean;
    site_base_url: string;
}

interface PropTypes {
    base_url: string;
    latest_scan: LatestScan;
}

const LatestScanTab = ({ base_url, latest_scan }: PropTypes) => {

    const [runMalwareScan, { isLoading }] = useRunMalwareScanMutation();

    const handleRunMalwareScan = async (base_url: string) => {
        Swal.fire({
            title: `Run malware scan for ${base_url}?`,
            showCancelButton: true,
            confirmButtonText: 'Scan Now!',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    await runMalwareScan(base_url);
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: `Malware Scan Failed`,
                        text: `Failed to scan malware on ${base_url}. Please try again later.`
                    })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    confirmButtonColor: '#325AE7',
                    title: `Malware Scan Scheduled`,
                    text: `The url ${base_url} was successfully scheduled for a malware scan! Come back in about 15-20 minutes to see the result.`
                })
            }
        })
    }

    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Typography variant='h6' sx={{
                fontSize: '18px',
                marginBottom: '20px',
            }}>
                Latest Scan
            </Typography>
            <Grid container spacing={2}>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        height: '100%',
                    }}>
                        <Typography variant='h6' sx={{ marginBottom: '10px' }}>Last report</Typography>
                        <Typography>
                            {latest_scan?.scan_date ? `Last scan on ${moment(latest_scan?.scan_date).format('MMMM Do YYYY, h:mm:ss a')}` : 'No scan date available'}
                        </Typography>
                        <Typography sx={{
                            display: 'flex',
                        }}>
                            <b>Result:</b>
                            <Typography sx={{
                                color: (latest_scan?.scan_results?.length ?? 0) > 0 ? '#E23A45' : '#2D9D78',
                                marginLeft: '5px',
                            }}>
                                {(latest_scan?.scan_results?.length ?? 0) > 0
                                    ? `${latest_scan.scan_results.length} issues had been detected.`
                                    : "No issues found"
                                }
                            </Typography>

                        </Typography>
                        <Button
                            sx={{
                                backgroundColor: '#325AE7',
                                borderRadius: '7px',
                                border: '1px solid #325AE7',
                                boxShadow: 'none',
                                padding: '3px 14px',
                                marginTop: '35px',
                                fontSize: '14px',
                                textTransform: 'inherit',
                                minHeight: 'inherit',
                                '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: '#325AE7',
                                },
                            }}
                            onClick={() => handleRunMalwareScan(base_url)}
                        >
                            Scan this website now
                        </Button>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        height: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            paddingRight: '24px',
                            alignItems: 'center',
                            position: 'relative',
                            '& > img': {
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }
                        }}>
                            <Box sx={{
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                border: '1px solid #AFBDFA',
                                borderRadius: '5px',
                            }}>
                                <img src={Coreicon} />
                            </Box>
                            <Box>
                                <Typography variant='h6'>Core</Typography>
                                <Typography>
                                    {latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.scan_date ? moment(latest_scan.scan_results[0]?.scan_date).format('MMMM Do YYYY') + ' | ' + moment(latest_scan.scan_results[0]?.scan_date).format('LTS') : ''}
                                </Typography>
                            </Box>
                            {latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.core ? <img src={CloseIcons} /> : <img src={CheckIcons} />}
                        </Box>
                        <Box sx={{
                            maxHeight: '84px',
                            minHeight: '84px',
                            overflowY: 'scroll',
                            padding: '12px',
                            borderRadius: '5px',
                            marginTop: '18px',
                            background: '#FAFBFF',
                            marginBottom: '15px',
                        }}>
                            {
                                latest_scan?.scan_results?.some(scan => scan.core)
                                    ? latest_scan.scan_results.filter(scan => scan.core).map((scan, index) => (
                                        <Typography key={index}>{scan.path}</Typography>
                                    ))
                                    : <Typography>No Issues Found</Typography>
                            }

                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        height: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            paddingRight: '24px',
                            alignItems: 'center',
                            position: 'relative',
                            '& > img': {
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }
                        }}>
                            <Box sx={{
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                border: '1px solid #AFBDFA',
                                borderRadius: '5px',
                            }}>
                                <img src={Vulnerable} />
                            </Box>
                            <Box>
                                <Typography variant='h6'>Vulnerable</Typography>
                                {latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.scan_date ? moment(latest_scan.scan_results[0]?.scan_date).format('MMMM Do YYYY') + ' | ' + moment(latest_scan.scan_results[0]?.scan_date).format('LTS') : ''}
                            </Box>
                            {latest_scan?.scan_results[0]?.vulnerable ? <img src={CloseIcons} /> : <img src={CheckIcons} />}
                        </Box>
                        <Box sx={{
                            maxHeight: '84px',
                            minHeight: '84px',
                            overflowY: 'scroll',
                            padding: '12px',
                            borderRadius: '5px',
                            marginTop: '18px',
                            background: '#FAFBFF',
                            marginBottom: '15px',
                        }}>
                            {
                                latest_scan?.scan_results?.some(scan => scan.vulnerable)
                                    ? latest_scan.scan_results.filter(scan => scan.vulnerable).map((scan, index) => (
                                        <Typography key={index}>{scan.path}</Typography>
                                    ))
                                    : <Typography>No Issues Found</Typography>
                            }

                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        height: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            paddingRight: '24px',
                            alignItems: 'center',
                            position: 'relative',
                            '& > img': {
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }
                        }}>
                            <Box sx={{
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                border: '1px solid #AFBDFA',
                                borderRadius: '5px',
                            }}>
                                <img src={Themesicon} />
                            </Box>
                            <Box>
                                <Typography variant='h6'>Themes</Typography>
                                <Typography>
                                    {latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.scan_date ? moment(latest_scan.scan_results[0]?.scan_date).format('MMMM Do YYYY') + ' | ' + moment().format('LTS') : ''}
                                </Typography>
                            </Box>
                            {latest_scan?.scan_results[0]?.theme ? <img src={CloseIcons} /> : <img src={CheckIcons} />}
                        </Box>
                        <Box sx={{
                            maxHeight: '84px',
                            minHeight: '84px',
                            overflowY: 'scroll',
                            padding: '12px',
                            borderRadius: '5px',
                            marginTop: '18px',
                            background: '#FAFBFF',
                            marginBottom: '15px',
                        }}>
                            {
                                latest_scan?.scan_results?.some(scan => scan.theme)
                                    ? latest_scan.scan_results.filter(scan => scan.theme).map((scan, index) => (
                                        <Typography key={index}>{scan.path}</Typography>
                                    ))
                                    : <Typography>No Issues Found</Typography>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        height: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            paddingRight: '24px',
                            alignItems: 'center',
                            position: 'relative',
                            '& > img': {
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }
                        }}>
                            <Box sx={{
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                border: '1px solid #AFBDFA',
                                borderRadius: '5px',
                            }}>
                                <img src={Pluginsicon} />
                            </Box>
                            <Box>
                                <Typography variant='h6'>Plugins</Typography>
                                <Typography>
                                    {latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.scan_date ? moment(latest_scan.scan_results[0]?.scan_date).format('MMMM Do YYYY') + ' | ' + moment().format('LTS') : ''}
                                </Typography>                            </Box>
                            {latest_scan?.scan_results[0]?.plugin ? <img src={CloseIcons} /> : <img src={CheckIcons} />}
                        </Box>
                        <Box sx={{
                            maxHeight: '84px',
                            minHeight: '84px',
                            overflowY: 'scroll',
                            padding: '12px',
                            borderRadius: '5px',
                            marginTop: '18px',
                            background: '#FAFBFF',
                            marginBottom: '15px',
                        }}>
                            {
                                latest_scan?.scan_results?.some(scan => scan.plugin)
                                    ? latest_scan.scan_results.filter(scan => scan.plugin).map((scan, index) => (
                                        <Typography key={index}>{scan.path}</Typography>
                                    ))
                                    : <Typography>No Issues Found</Typography>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        height: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            paddingRight: '24px',
                            alignItems: 'center',
                            position: 'relative',
                            '& > img': {
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }
                        }}>
                            <Box sx={{
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                border: '1px solid #AFBDFA',
                                borderRadius: '5px',
                            }}>
                                <img src={ChecksumFailedicon} />
                            </Box>
                            <Box>
                                <Typography variant='h6'>Checksum Failed</Typography>
                                <Typography>
                                    {latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.scan_date ? moment(latest_scan.scan_results[0]?.scan_date).format('MMMM Do YYYY') + ' | ' + moment().format('LTS') : ''}
                                </Typography>                            </Box>
                            {latest_scan?.scan_results[0]?.checksum_fail ? <img src={CloseIcons} /> : <img src={CheckIcons} />}
                        </Box>
                        <Box sx={{
                            maxHeight: '84px',
                            minHeight: '84px',
                            overflowY: 'scroll',
                            padding: '12px',
                            borderRadius: '5px',
                            marginTop: '18px',
                            background: '#FAFBFF',
                            marginBottom: '15px',
                        }}>
                            {
                                latest_scan?.scan_results?.some(scan => scan.checksum_fail)
                                    ? latest_scan.scan_results.filter(scan => scan.checksum_fail).map((scan, index) => (
                                        <Typography key={index}>{scan.path}</Typography>
                                    ))
                                    : <Typography>No Issues Found</Typography>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        height: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            paddingRight: '24px',
                            alignItems: 'center',
                            position: 'relative',
                            '& > img': {
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }
                        }}>
                            <Box sx={{
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                border: '1px solid #AFBDFA',
                                borderRadius: '5px',
                            }}>
                                <img src={UnrecognizedFileicon} />
                            </Box>
                            <Box>
                                <Typography variant='h6'>Unrecognized File</Typography>
                                <Typography>
                                    {latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.scan_date ? moment(latest_scan.scan_results[0]?.scan_date).format('MMMM Do YYYY') + ' | ' + moment().format('LTS') : ''}
                                </Typography>                            </Box>
                            {latest_scan?.scan_results[0]?.unrecognized ? <img src={CloseIcons} /> : <img src={CheckIcons} />}
                        </Box>
                        <Box sx={{
                            maxHeight: '84px',
                            minHeight: '84px',
                            overflowY: 'scroll',
                            padding: '12px',
                            borderRadius: '5px',
                            marginTop: '18px',
                            background: '#FAFBFF',
                            marginBottom: '15px',
                        }}>
                            {
                                latest_scan?.scan_results?.some(scan => scan.unrecognized)
                                    ? latest_scan.scan_results.filter(scan => scan.unrecognized).map((scan, index) => (
                                        <Typography key={index}>{scan.path}</Typography>
                                    ))
                                    : <Typography>No Issues Found</Typography>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                    <Box sx={{
                        padding: '18px',
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        height: '100%',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            paddingRight: '24px',
                            alignItems: 'center',
                            position: 'relative',
                            '& > img': {
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }
                        }}>
                            <Box sx={{
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '12px',
                                border: '1px solid #AFBDFA',
                                borderRadius: '5px',
                            }}>
                                <img src={Abandonedicon} />
                            </Box>
                            <Box>
                                <Typography variant='h6'>Abandoned</Typography>
                                <Typography>
                                    {latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.scan_date ? moment(latest_scan.scan_results[0]?.scan_date).format('MMMM Do YYYY') + ' | ' + moment().format('LTS') : ''}
                                </Typography>                            </Box>
                            {latest_scan?.scan_results[0]?.abandoned ? <img src={CloseIcons} /> : <img src={CheckIcons} />}
                        </Box>
                        <Box sx={{
                            maxHeight: '84px',
                            minHeight: '84px',
                            overflowY: 'scroll',
                            padding: '12px',
                            borderRadius: '5px',
                            marginTop: '18px',
                            background: '#FAFBFF',
                            marginBottom: '15px',
                        }}>
                            {
                                latest_scan?.scan_results?.some(scan => scan.abandoned)
                                    ? latest_scan.scan_results.filter(scan => scan.abandoned).map((scan, index) => (
                                        <Typography key={index}>{scan.path}</Typography>
                                    ))
                                    : <Typography>No Issues Found</Typography>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default LatestScanTab;
