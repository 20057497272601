import * as React from "react";
import { Button, Checkbox, Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import controlSlider from "../../../Assets/Images/control-slider.svg";
import { IUpdateData, SiteData } from "../../../Views/Dashboard";
import OpenUpdatesPlugin from "../OpenUpdatesPlugin";
import OpenUpdatesThemes from "../OpenUpdatesThemes";
import OpenUpdatesWordpress from "../OpenUpdatesWordpress";
import OpenFailedUpdates from "../OpenFailedUpdates";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type UpdatesTypeCount = (type: string, updates: IUpdateData[]) => number;
type FilteredUpdates = (type: string, updates: IUpdateData[]) => IUpdateData[];
type PerformOpenUpdates = (
  type: string,
  selectedUpdates: SiteData[]
) => Promise<void>;

interface PropTypes {
  updates: IUpdateData[];
  updatesTypeCount: UpdatesTypeCount;
  filteredUpdates: FilteredUpdates;
  performOpenUpdates: PerformOpenUpdates;
  updateType: string;
}

type CheckboxStates = Record<string, boolean[]>;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const getUpdateTypeFromTabIndex = (index: number): string => {
  switch (index) {
    case 0:
      return "Plugin";
    case 1:
      return "Theme";
    case 2:
      return "WP";
    default:
      return "Plugin"; // Default to 'Plugin' in case of any unexpected index
  }
};

const DashboardUpdateSection = ({
  updates,
  updatesTypeCount,
  filteredUpdates,
  performOpenUpdates,
  updateType,
}: PropTypes) => {
  const [value, setValue] = React.useState(0);
  const [selectedUpdates, setSelectedUpdates] = React.useState<SiteData[]>([]);

  console.log("Selected Updates >>>>>>>>>>>>>>>> ", selectedUpdates);

  // const [selectAll, setSelectAll] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState<{
    [tabValue: number]: boolean;
  }>({
    0: false,
    1: false,
    2: false,
  });
  const [parentCheckboxState, setParentCheckboxState] = React.useState<
    Record<string, boolean>
  >({});
  const [accordionCheckboxStates, setAccordionCheckboxStates] = React.useState<
    Record<string, boolean[]>
  >(() => {
    if (!updates) return {};

    const initialState: Record<string, boolean[]> = {};
    updates.forEach((_, index) => {
      const accordionId = `accordion${index}`;
      initialState[accordionId] = [];
    });
    return initialState;
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleMainCheckboxChange = (isChecked: boolean) => {
    setSelectAll((prevState) => ({
      ...prevState,
      [value]: isChecked,
    }));

    const activeUpdates = filteredUpdates(
      getUpdateTypeFromTabIndex(value),
      updates
    ); // Filter updates based on active tab

    // Update the state of all parent checkboxes
    const parentCheckboxState: Record<string, boolean> = {};
    activeUpdates.forEach((_, index) => {
      const accordionId = `accordion${index}`;
      parentCheckboxState[accordionId] = isChecked;
    });
    setParentCheckboxState(parentCheckboxState);

    // Update the state of all children checkboxes and selectedUpdates
    const childCheckboxState: Record<string, boolean[]> = {};
    const selectedUpdatesState: SiteData[] = [];
    activeUpdates?.forEach((update, index) => {
      const accordionId = `accordion${index}`;
      const sites = update.sites || [];

      const accordionState: boolean[] = [];
      sites.forEach((_, siteIndex) => {
        accordionState[siteIndex] = isChecked;

        // Update selectedUpdates state
        const selectedUpdate = sites[siteIndex];
        if (
          isChecked &&
          !selectedUpdates.some(
            (item) =>
              item.site_base_url === selectedUpdate.site_base_url &&
              item.name === selectedUpdate.name
          )
        ) {
          selectedUpdatesState.push(selectedUpdate);
        } else if (!isChecked) {
          selectedUpdatesState.filter(
            (item) =>
              item.site_base_url !== selectedUpdate.site_base_url ||
              item.name !== selectedUpdate.name
          );
        }
      });

      childCheckboxState[accordionId] = accordionState;
    });

    setAccordionCheckboxStates(childCheckboxState);
    setSelectedUpdates(selectedUpdatesState);
  };

  const handleParentCheckboxChange = (
    accordionId: string,
    isChecked: boolean
  ) => {
    setAccordionCheckboxStates((prevState) => {
      const accordionState = { ...(prevState[accordionId] || {}) };

      const index = parseInt(accordionId.substring(accordionId.length - 1));
      const sites = updates[index].sites || [];

      sites.forEach((site, siteIndex) => {
        accordionState[siteIndex] = isChecked;

        // Update selectedUpdates state
        const selectedUpdate = site;
        if (
          isChecked &&
          !selectedUpdates.some(
            (item) =>
              item.site_base_url === site.site_base_url &&
              item.name === site.name
          )
        ) {
          setSelectedUpdates((prevState) => [...prevState, selectedUpdate]);
        } else if (!isChecked) {
          setSelectedUpdates((prevState) => {
            return prevState.filter(
              (item) =>
                item.site_base_url !== site.site_base_url ||
                item.name !== site.name
            );
          });
        }
      });

      return { ...prevState, [accordionId]: accordionState };
    });

    setParentCheckboxState((prevState) => ({
      ...prevState,
      [accordionId]: isChecked,
    }));
  };

  const handleChildCheckboxChange = (
    accordionId: string,
    checkboxIndex: number,
    isChecked: boolean
  ) => {
    setAccordionCheckboxStates((prevState) => {
      const accordionState = { ...(prevState[accordionId] || {}) };
      accordionState[checkboxIndex] = isChecked;

      const allChecked = Object.values(accordionState).every(
        (checkbox) => checkbox
      );
      const anyChecked = Object.values(accordionState).some(
        (checkbox) => checkbox
      );

      if (allChecked) {
        setParentCheckboxState((prevState) => ({
          ...prevState,
          [accordionId]: true,
        }));
      } else {
        setParentCheckboxState((prevState) => {
          const newState = { ...prevState };
          delete newState[accordionId];
          return newState;
        });
      }

      // Update selectedUpdates state
      const index = parseInt(accordionId.substring(accordionId.length - 1));
      const sites = updates[index].sites || [];

      if (isChecked) {
        const selectedUpdate = sites[checkboxIndex];
        if (
          !selectedUpdates.some(
            (item) =>
              item.site_base_url === selectedUpdate.site_base_url &&
              item.name === selectedUpdate.name
          )
        ) {
          setSelectedUpdates((prevState) => [...prevState, selectedUpdate]);
        }
      } else {
        setSelectedUpdates((prevState) => {
          return prevState.filter(
            (item) =>
              item.site_base_url !== sites[checkboxIndex].site_base_url ||
              item.name !== sites[checkboxIndex].name
          );
        });
      }

      return { ...prevState, [accordionId]: accordionState };
    });
  };

  return (
    <Grid item lg={5} xs={12}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            marginRight: "17px",
          }}
        >
          {updateType === "openUpdates" ? "Open Updates" : "Recent Updates"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              borderRadius: " 7px",
              border: "1px solid #AFBDFA",
              background: "#fff",
              display: "flex",
              alignItems: "center",
              margin: "5px 10px 5px 0",
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid #AFBDFA",
                // width: '43px',
                height: "43px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "9px 6px 9px 8px",
              }}
            >
              {updateType !== "openUpdates" && <img src={controlSlider} />}
              {updateType === "openUpdates" && (
                <Checkbox
                  checked={selectAll[value]}
                  onChange={(event) =>
                    handleMainCheckboxChange(event.target.checked)
                  }
                />
              )}
            </Box>
            <Tabs
              value={value}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              onChange={handleChange}
              sx={{
                minHeight: "auto",
                padding: "0 20px",
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& button": {
                  padding: "0",
                  textTransform: "capitalize",
                  minHeight: "auto",
                  minWidth: "auto",
                },
                "& button + button": {
                  borderLeft: "1px solid #AFBDFA  ",
                  marginLeft: "10px",
                  paddingLeft: "10px",
                },
              }}
            >
              <Tab
                label={`All (${
                  updatesTypeCount("Plugin", updates) +
                  updatesTypeCount("THEME", updates) +
                  updatesTypeCount("WP", updates)
                })`} // Sum of all types for "All" label
                {...a11yProps(0)}
              />
              <Tab
                label={`Plugins (${updatesTypeCount("Plugin", updates)})`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Themes (${updatesTypeCount("THEME", updates)})`}
                {...a11yProps(2)}
              />
              <Tab
                label={`WordPress (${updatesTypeCount("WP", updates)})`}
                {...a11yProps(3)}
              />
              <Tab
                label={`Failed (${updatesTypeCount("Failed", updates)})`}
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              "&>*": {
                marginTop: "5px",
                marginBottom: "5px",
              },
            }}
          >
            {updateType === "openUpdates" && (
              <Button
                sx={{
                  backgroundColor: "#325AE7",
                  borderRadius: "7px",
                  border: "1px solid #325AE7",
                  boxShadow: "none",
                  padding: "8px 19px",
                  minHeight: "45px",
                  fontSize: "15px",
                  textTransform: "inherit",
                  "&.Mui-disabled": {
                    border: "1px solid #707070",
                    color: "#ffffff",
                    background: "#707070",
                  },
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#325AE7",
                  },
                  "& img": {
                    marginRight: "10px",
                  },
                }}
                onClick={() =>
                  performOpenUpdates(
                    getUpdateTypeFromTabIndex(value),
                    selectedUpdates
                  )
                }
                disabled={selectedUpdates.length === 0} // Disable the button if there are no selectedUpdates
              >
                Update now
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <OpenUpdatesPlugin
          updates={filteredUpdates("All", updates)}
          accordionCheckboxStates={accordionCheckboxStates}
          parentCheckboxState={parentCheckboxState}
          handleParentCheckboxChange={handleParentCheckboxChange}
          handleChildCheckboxChange={handleChildCheckboxChange}
          updateType={updateType}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OpenUpdatesPlugin
          updates={filteredUpdates("Plugin", updates)}
          accordionCheckboxStates={accordionCheckboxStates}
          parentCheckboxState={parentCheckboxState}
          handleParentCheckboxChange={handleParentCheckboxChange}
          handleChildCheckboxChange={handleChildCheckboxChange}
          updateType={updateType}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OpenUpdatesThemes
          updates={filteredUpdates("Theme", updates)}
          accordionCheckboxStates={accordionCheckboxStates}
          parentCheckboxState={parentCheckboxState}
          handleParentCheckboxChange={handleParentCheckboxChange}
          handleChildCheckboxChange={handleChildCheckboxChange}
          updateType={updateType}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OpenUpdatesWordpress updates={filteredUpdates("WP", updates)} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <OpenFailedUpdates
          updates={filteredUpdates("Failed", updates)}
          accordionCheckboxStates={accordionCheckboxStates}
          parentCheckboxState={parentCheckboxState}
          handleParentCheckboxChange={handleParentCheckboxChange}
          handleChildCheckboxChange={handleChildCheckboxChange}
          updateType={updateType}
        />
      </TabPanel>
    </Grid>
  );
};

export default DashboardUpdateSection;
