import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

import './style.css'
import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { Button, Grid, InputBase, Typography } from '@mui/material';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import type { ITimezone } from "react-timezone-select";
import { useAddSiteMutation } from '../../Features/siteSlice';
import Swal from 'sweetalert2';
import { useGetPackagesQuery } from '../../Features/settingSlice';


const StyledButton = styled('button')(
  ({ theme }) => `
box-sizing: border-box;
width: 100%;
padding: 7px 12px;
border-radius: 7px;
text-align: left;
min-height: 47px;
line-height: 1.5;
background: #ffffff;
font-size: 16px;
border: 1px solid #AFBDFA;
color: #1B1D29;
font-family: Titillium Web;
font-weight: 600;
transition-property: all;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 120ms;


&.${selectUnstyledClasses.expanded} {
  &::after {
    float: right;
    transform: rotate(180deg);
  }
}

&::after {
  content: '▾';
  float: right;
}
`,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
box-sizing: border-box;
padding: 6px;
min-width: 200px;
border-radius: 12px;
font-family: Titillium Web;
font-weight: 600;
overflow: auto;
outline: 0px;
background: #ffffff;
font-size: 14px;
border: 1px solid #AFBDFA;
color: #1B1D29;
width: 100%;
`,
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
list-style: none;
padding: 8px;
border-radius: 8px;
cursor: default;

&:last-of-type {
  border-bottom: none;
}

&.${optionUnstyledClasses.selected} {
  background-color: #325AE7;
  color: #ffffff;
}

&.${optionUnstyledClasses.highlighted} {
  background-color: #325AE7;
  color: #ffffff;
}

&.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
  background-color: #325AE7;
  color: #ffffff;
}
`,
);

const StyledPopper = styled(PopperUnstyled)`
z-index: 1;
`;

const styles = {
  control: (provided: any, state: any) => ({
    ...provided,
    paddingTop: '8px',
    paddingBottom: '8px'
  })
};

const AddWebsite = () => {
  const [urls, setUrls] = React.useState(['']); // Aray of website URLs
  const [selectedTimezone, setSelectedTimezone] = React.useState<ITimezone>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [packageType, setPackageType] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const { data: packages, isError } = useGetPackagesQuery();


  const [addSite, { isLoading }] = useAddSiteMutation();

  const handleUrlChange = (index: number, value: string) => {
    const updatedUrls = [...urls];
    updatedUrls[index] = value;
    setUrls(updatedUrls);
  };

  const addUrlField = () => {
    setUrls([...urls, '']);
  };

  const removeUrlField = (index: number) => {
    const updatedUrls = [...urls];
    updatedUrls.splice(index, 1);
    setUrls(updatedUrls);
  };

  const handleSubmit = async () => {
    // Call your backend API with the form data (websiteUrl, timezone, packageType)
    setErrors([]);
    let errorCount = 0;
    if (urls.every(url => url === '')) {
      const newError = 'You should provide at least one website';
      setErrors((oldArray) => [...oldArray, newError]);
      errorCount++;
    }
    if (packageType === '') {
      const newError = 'Please select a package.';
      setErrors((oldArray) => [...oldArray, newError]);
      errorCount++;
    }
    if (selectedTimezone === '') {
      const newError = 'Please select a timezone.';
      setErrors((oldArray) => [...oldArray, newError]);
      errorCount++;
    }

    if (errorCount === 0) {
      const addSiteData = {
        timezone: selectedTimezone,
        package: packageType,
        urls: urls
      }

      try {
        await addSite(addSiteData).unwrap();
        Swal.fire({
          icon: 'success',
          confirmButtonColor: '#325AE7',
          text: 'Website(s) added successfully!'
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'Error adding Website(s). Please try again later.'
        })
      }
    } else {
      Swal.fire({
        icon: 'warning',
        confirmButtonColor: '#325AE7',
        text: 'Please provide all the information to proceed.'
      })
    }
  };

  return (
    <Box>
      <Box sx={{
        padding: '20px 40px',
        borderBottom: '1px solid #AFBDFA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#FAFBFF',
        position: 'sticky',
        top: '0',
        zIndex: '2',
        '@media (max-width: 1400px)': {
          padding: '20px 20px',
        },
        '@media (max-width: 1199px)': {
          position: 'relative',
        },
        '@media (max-width: 767px)': {
          display: 'block'
        },
      }}>
        <Typography
          variant="h1"
        >
          Add Website
        </Typography>
      </Box>
      <Box sx={{
        padding: '30px 40px',
        '@media (max-width: 1400px)': {
          padding: '25px 20px',
        },
      }}>
        <Grid container spacing={2}>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Box sx={{
              padding: '30px',
              background: '#ffffff',
              borderRadius: '10px',
              border: '1px solid #AFBDFA',
              '@media (max-width: 899px)': {
                padding: '15px',
              }
            }}>
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  {urls.map((url, index) => (
                    <Grid item xs={12} key={index}>
                      <Box sx={{
                        marginBottom: '20px',
                        '& label': {
                          flex: '1',
                          width: '100%',
                          display: 'block',
                          fontWeight: '600',
                          marginBottom: '8px',
                        },
                        '& input': {
                          display: 'inline-block',
                          fontSize: '16px',
                          color: '#525566',
                          border: '1px solid #AFBDFA',
                          padding: '11px 15px',
                          background: '#ffffff',
                          borderRadius: '7px',
                        }
                      }}>
                        <label>Website URL</label>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                          <InputBase
                            sx={{
                              display: 'inline-block',
                              // width: 'calc(100% - 120px)', // Adjust the width as per your preference
                              width: '75%',
                              marginRight: '10px', // Adjust the spacing between the input field and the remove button
                            }}
                            type='text'
                            value={url}
                            onChange={(e) => handleUrlChange(index, e.target.value)}
                          />
                          {index > 0 && (
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => removeUrlField(index)}
                              sx={{ marginLeft: '10px' }}
                            >
                              Remove
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      sx={{
                        marginTop: '15px',
                        backgroundColor: "#325AE7",
                        borderRadius: "7px",
                        border: "1px solid #325AE7",
                        boxShadow: "none",
                        padding: "7px 19px",
                        textTransform: "inherit",
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "#325AE7",
                        },
                        "& img": {
                          marginRight: "10px",
                        },
                      }}
                      onClick={addUrlField}
                    >
                      Add another site
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{
                    position: 'relative',
                    '& label': {
                      width: '100%',
                      display: 'inline-block',
                      marginBottom: '8px',
                      fontWeight: '600',
                    },
                    '& input': {
                      fontSize: '16px',
                      color: '#525566',
                      width: '100%',
                      padding: '11px 15px',
                      background: '#ffffff',
                      border: '1px solid #AFBDFA',
                      borderRadius: '7px',
                    }
                  }}>
                    <label>Select your time zone</label>
                    <TimezoneSelect
                      value={selectedTimezone}
                      onChange={setSelectedTimezone}
                      timezones={{
                        ...allTimezones,
                        "America/Lima": "Pittsburgh",
                        "Europe/Berlin": "Frankfurt"
                      }}
                    />
                    {/* <CustomSelect 
                      defaultValue={'Amsterdam'}
                      value={timezone}
                      // onChange={handleTimezoneChange}
                    >
                      <StyledOption value={'Amsterdam'}>Amsterdam/Netherlands (UTC+02:00)</StyledOption>
                      <StyledOption value={'US'}>Amsterdam/Netherlands (UTC+02:00)</StyledOption>
                      <StyledOption value={'UK'}>Amsterdam/Netherlands (UTC+02:00)</StyledOption>
                    </CustomSelect> */}
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '25px',
                marginTop: '25px',
              }}>
                <Typography variant='h6' sx={{
                  fontSize: '18px',
                  marginRight: '17px',
                }}>
                  Select package
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {packages?.map(pkg => (
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12} key={pkg.name}>
                    <Box
                      className={`pakage-box ${packageType === pkg.name.toLowerCase() ? 'pakage-box-selected' : ''}`}
                      sx={{
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                      }}
                    >
                      <Box className="pakage-title" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px',
                        borderBottom: '1px solid #AFBDFA',
                      }}>
                        <Typography variant='h5'
                          sx={{
                            fontSize: '24px',
                            "@media (max-width: 767px)": {
                              fontSize: '18px',
                            }
                          }}
                        >
                          {`${pkg.name} package`}
                        </Typography>
                        <Typography variant='h4' sx={{
                          display: 'flex',
                          fontSize: '24px',
                          alignItems: 'flex-end',
                          fontWeight: '600',
                          "@media (max-width: 767px)": {
                            fontSize: '18px',
                          }
                        }}>
                          €{(pkg.price / 100).toFixed(2)} <Typography sx={{ fontSize: '15px', fontWeight: '500' }}>/month</Typography>
                        </Typography>
                      </Box>
                      <Box sx={{
                        padding: '20px',
                      }}>
                        <Box sx={{
                          marginBottom: '15px',
                          '& p': {
                            fontSize: '18px',
                          }
                        }}>
                          <Typography>▸ {pkg.backup_count} backups per week</Typography>
                          <Typography>▸ {pkg.update_count} updates per week</Typography>
                          <Typography>▸ {pkg.security ? 'Real-time Security' : 'No real-time security'}</Typography>
                        </Box>
                        <Button
                          sx={{
                            backgroundColor: packageType === pkg.name ? "#BDBDBD" : "#325AE7",
                            borderRadius: "7px",
                            border: packageType === pkg.name ? "1px solid #BDBDBD" : "1px solid #325AE7",
                            boxShadow: "none",
                            padding: "7px 19px",
                            textTransform: "inherit",
                            "&:hover": {
                              boxShadow: packageType === pkg.name ? "none" : "none",
                              backgroundColor: packageType === pkg.name ? "#BDBDBD" : "#325AE7",
                            },
                            "& img": {
                              marginRight: "10px",
                            },
                          }}
                          onClick={() => setPackageType(pkg.name)}
                        >
                          {packageType === pkg.name ? 'Selected' : 'Select'}
                        </Button>

                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Button
                disabled={isLoading}
                sx={{
                  backgroundColor: "#325AE7",
                  borderRadius: "7px",
                  border: "1px solid #325AE7",
                  boxShadow: "none",
                  padding: "7px 19px",
                  textTransform: "inherit",
                  marginTop: '20px',
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#325AE7",
                  },
                  "& img": {
                    marginRight: "10px",
                  },
                }}
                onClick={handleSubmit}
              >
                {isLoading ? "Adding Website(s)..." : "Add Website(s)"}
              </Button>
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <Box sx={{
              borderRadius: '10px',
              border: '1px solid #AFBDFA',
              background: '#ffffff',
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '20px',
                borderBottom: '1px solid #AFBDFA',
              }}>
                <Typography variant='h5' sx={{ fontSize: '18px' }}>Install WP Monitoring Plugin</Typography>
              </Box>
              <Box sx={{
                padding: '20px',
              }}>
                <Typography>You need to download this plugin to work. Using the link below you can download the latest plugin zip.</Typography>
                <a href="https://cdn.wpmonitoring.com/plugin/releases/wpmonitoring.zip" target="_blank" rel="noopener noreferrer">
                  <Button
                    sx={{
                      marginTop: '15px',
                      backgroundColor: "#325AE7",
                      borderRadius: "7px",
                      border: "1px solid #325AE7",
                      boxShadow: "none",
                      padding: "7px 19px",
                      textTransform: "inherit",
                      "&:hover": {
                        boxShadow: "none",
                        backgroundColor: "#325AE7",
                      },
                      "& img": {
                        marginRight: "10px",
                      },
                    }}
                  >
                    Download Plugin
                  </Button>
                </a>

              </Box>
            </Box>
          </Grid>
        </Grid >
      </Box >
    </Box >
  );
};

export default AddWebsite;
