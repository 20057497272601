/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, MenuItem, Select, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { OpenUpdate, UpdatePluginsHandler, SchedulePluginsUpdateHandler } from "../OverviewTab";
import OpenUpdatesCheckboxItem from '../OpenUpdatesCheckboxItem';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const StyledButton = styled('button')(
    ({ theme }) => `
  box-sizing: border-box;
  min-width: 144px;
  padding: 5px 12px;
  border-radius: 5px;
  text-align: left;
  line-height: 1.5;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  font-family: Titillium Web;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;


  &.${selectUnstyledClasses.expanded} {
    &::after {
      float: right;
      transform: rotate(180deg);
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
  box-sizing: border-box;
  padding: 6px;
  min-width: 200px;
  border-radius: 12px;
  font-family: Titillium Web;
  font-weight: 600;
  overflow: auto;
  outline: 0px;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  `,
);

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: #325AE7;
    color: #ffffff;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;
const CustomSelect = React.forwardRef(function CustomSelect<
    TValue extends {},
    Multiple extends boolean,
>(
    props: SelectUnstyledProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const slots: SelectUnstyledProps<TValue, Multiple>['slots'] = {
        root: StyledButton,
        listbox: StyledListbox,
        popper: StyledPopper,
        ...props.slots,
    };

    return <SelectUnstyled {...props} ref={ref} slots={slots} />;
}) as <TValue extends {}, Multiple extends boolean>(
    props: SelectUnstyledProps<TValue, Multiple> &
        React.RefAttributes<HTMLButtonElement>,
) => JSX.Element;

interface PropTypes {
    open_updates: OpenUpdate[];
    handleUpdatePlugins: UpdatePluginsHandler;
    handleSchedulePluginsUpdate: SchedulePluginsUpdateHandler;
}

const OpenUpdatesCheckbox = ({open_updates, handleUpdatePlugins, handleSchedulePluginsUpdate}: PropTypes) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{
                    borderRadius: '10px',
                    border: '1px solid #AFBDFA',
                    background: '#fff',
                    overflow: 'hidden',
                    maxHeight: '355px',
                    minHeight: '355px',
                    overflowY: 'scroll',
                    '& > div + div': {
                        borderTop: '1px solid #AFBDFA',
                    }
                }}>
                    {
                        open_updates?.length > 0
                        ? open_updates.map((update, index) => 
                                <OpenUpdatesCheckboxItem
                                    key={index}
                                    update={update} 
                                    handleUpdatePlugins={handleUpdatePlugins} 
                                    handleSchedulePluginsUpdate={handleSchedulePluginsUpdate}
                                />
                            )
                        : <Box sx={{
                          display: 'flex',
                          height: '100%',
                          alignItems: 'center',
                          maxHeight: '342px',
                          minHeight: '342px',
                          justifyContent: 'center',
                      }}>
                          <Typography>Your Site is up-to-date</Typography>
                      </Box>
                    }
                </Box>
            </Grid>
        </Grid>

    );
};

export default OpenUpdatesCheckbox;
