/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import * as React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import ThemeBox from '../ThemeBox';
import Slider from '@mui/material/Slider';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const ImageOptimizationTab = () => {
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: '40px',
        height: '20px',
        padding: 0,
        display: 'flex',
        borderRadius: '100px',
        background: '#BECBFF',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            color: '#14161F',
            '&.Mui-checked': {
                transform: 'translateX(20px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#325AE7',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '',
            width: '16px',
            height: '16px',
            borderRadius: '100px',
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));
    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Typography variant='h6' sx={{
                fontSize: '18px',
                marginBottom: '20px',
            }}>
                Image Optimization
            </Typography>
            <Grid container spacing={2}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        '& > div + div': {
                            borderTop: '1px solid #AFBDFA',
                        }
                    }}>
                        <Box sx={{
                            padding: '20px 18px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                            <Typography
                                variant='h6'
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: '10px',
                                    '& > span': {
                                        marginRight: '5px',
                                    }
                                }}
                            >
                                <Checkbox {...label} sx={{ padding: 0, marginRight: '3px' }} />
                                Automatically compress newly-added images
                            </Typography>
                            <Box sx={{
                                marginRight: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                '@media (max-width: 767px)': {
                                    margin: '15px 0px',
                                },
                            }}>
                                <Typography variant='h6' sx={{ marginRight: '10px',whiteSpace: 'nowrap', }}>Status ⓘ</Typography>
                                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                            </Box>
                        </Box>
                        <Box sx={{
                            padding: '20px 18px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                            <Typography
                                variant='h6'
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: '10px',
                                    '& > span': {
                                        marginRight: '5px',
                                    }
                                }}
                            >
                                <Checkbox {...label} sx={{ padding: 0, marginRight: '3px' }} />
                                Show compression meta-box on an image's dashboard media page
                            </Typography>
                            <Box sx={{
                                marginRight: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                '@media (max-width: 767px)': {
                                    margin: '15px 0px',
                                },
                            }}>
                                <Typography variant='h6' sx={{ marginRight: '10px',whiteSpace: 'nowrap', }}>Status ⓘ</Typography>
                                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: '20px 18px 20px 18px',
                        }}>
                            <Typography
                                variant='h6'
                                sx={{
                                    whiteSpace: 'nowrap',
                                    marginRight: '20px',
                                }}
                            >
                                Compression options:
                            </Typography>
                            <Box sx={{
                                // display: 'flex',
                                // alignItems: 'center',
                            }}>
                                <Box sx={{
                                    '& label': {
                                        marginRight: '30px',
                                        marginBottom: '10px',
                                        marginLeft: '0px',
                                    },
                                    '& .MuiCheckbox-root': {
                                        marginRight: '6px',
                                    }
                                }}>
                                    <FormControlLabel control={<Checkbox sx={{ padding: 0, }} defaultChecked />} label="Prioritize maximum compression " />
                                    <FormControlLabel control={<Checkbox sx={{ padding: 0, }} defaultChecked />} label="Prioritize retention of detail " />
                                    <FormControlLabel control={<Checkbox sx={{ padding: 0, }} defaultChecked />} label="Other " />
                                </Box>
                                <Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: '10px',
                                        marginTop: '10px',
                                    }}>
                                        <Typography>Maximum compression</Typography>
                                        <Typography>Image quality</Typography>
                                    </Box>
                                    <Slider
                                        size="small"
                                        defaultValue={70}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        sx={{
                                            '& .MuiSlider-rail': {
                                                background: '#FAFBFF',
                                                opacity: 1,
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box sx={{
                        background: '#ffffff',
                        borderRadius: '10px',
                        border: '1px solid #AFBDFA',
                        '& > div + div': {
                            borderTop: '1px solid #AFBDFA',
                        }
                    }}>
                        <Box sx={{
                            padding: '20px 18px 20px 18px',
                        }}>
                            <Typography
                                variant='h6'
                                sx={{
                                    whiteSpace: 'nowrap',
                                    marginBottom: '16px',
                                }}
                            >
                                Compression options:
                            </Typography>
                            <Box sx={{
                                // display: 'flex',
                                // alignItems: 'center',
                            }}>
                                <Box sx={{
                                    '& label': {
                                        marginRight: '30px',
                                        marginLeft: '0px',
                                    },
                                    '& .MuiCheckbox-root': {
                                        marginRight: '6px',
                                    }
                                }}>
                                    <FormControlLabel control={<Checkbox sx={{ padding: 0, }} defaultChecked />} label=" Create WebP version of image " />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ImageOptimizationTab;
