/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Typography } from '@mui/material';
import * as React from 'react';
import Deletetheme from '../../../Assets/Images/delete-theme.svg'
import Themeimg from '../../../Assets/Images/theme-image.png'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export type Theme = {
    name: string;
    status: string;
    version: string;
    exclude: boolean;
    last_check: string;
    last_update: string;
    site_base_url: string;
    image_url: string;
};

export type ChangeThemeHandler = (theme_name: string) => Promise<void>;

type PropTypes = {
    theme: Theme;
    handleChangeTheme: ChangeThemeHandler;
    changingTheme: boolean;
    checkedThemes: Theme[];
    onCheckboxChange: (theme: Theme) => void;
}

const ThemeBox = ({ theme, handleChangeTheme, changingTheme, checkedThemes, onCheckboxChange }: PropTypes) => {

    return (
        <Box>
            <Box sx={{
                padding: '18px',
                background: '#ffffff',
                borderRadius: '10px',
                position: 'relative',
                border: '1px solid #AFBDFA',
            }}>
                <Box sx={{
                    position: 'absolute',
                    width: '30px',
                    zIndex: '1',
                    height: '30px',
                    background: '#fff',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: '10px',
                    right: '10px',
                    '& svg': {
                        width: '20px',
                        color: '#325AE7',
                    }
                }}>
                    <Checkbox
                        checked={checkedThemes.some((t) => t.name === theme.name)}
                        onChange={() => onCheckboxChange(theme)}
                        {...label} 
                        sx={{ padding: 0, marginRight: '3px' }}
                    />
                </Box>
                <Box sx={{
                    '& img': {
                        maxHeight: '170px',
                        borderRadius: '5px',
                        objectFit: 'cover',
                        width: '100%',
                    }
                }}>
                    <img src={theme?.image_url || Themeimg} />
                </Box>
                <Typography variant='h6' sx={{
                    borderBottom: '1px solid #AFBDFA',
                    padding: '10px 0',
                }}>
                    {theme?.name}
                </Typography>
                <Box className='' sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                    '&.active-theme button:first-child img': {
                        filter: 'brightness(0)',
                        opacity: '0.5'
                    },
                    '&.active-theme button:first-child': {
                        pointerEvents: 'none',
                    },
                    '&.active-theme button:last-child': {
                        pointerEvents: 'none',
                        background: '#918F97',
                        borderColor: '#918F97',
                    },
                }}>
                    <Button sx={{
                        background: '#ffffff',
                        border: '1px solid #F4F5FE',
                        padding: '7px 8px',
                        minWidth: 'auto',
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: '#ffffff',
                        },
                    }}>
                        {/* <img src={Deletetheme} /> */}
                    </Button>
                    <Button
                        disabled={theme.status === 'active' || changingTheme}
                        sx={{
                            backgroundColor: '#325AE7',
                            borderRadius: '7px',
                            border: '1px solid #325AE7',
                            boxShadow: 'none',
                            padding: '3px 14px',
                            marginLeft: '12px',
                            fontSize: '14px',
                            textTransform: 'inherit',
                            whiteSpace: 'nowrap',
                            minWidth: '98px',
                            minHeight: 'inherit',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#325AE7',
                            },
                        }}
                        onClick={() => handleChangeTheme(theme.name)}
                    >
                        {theme.status === 'active' ? 'Active' : 'Activate Theme'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ThemeBox;
