/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import CheckIcons from '../../../Assets/Images/check-Icons.svg'
import { LatestScan } from '../LatestScanTab';
import moment from 'moment';
import Swal from 'sweetalert2';

import { useRunMalwareScanMutation } from '../../../Features/siteSlice';

interface PropTypes {
  base_url: string;
  latest_scan: LatestScan;
  handleButtonClick: (tabIndex: number) => void;
}

const SecurityCenter = ({ base_url, latest_scan, handleButtonClick }: PropTypes) => {
  const [runMalwareScan, { isLoading }] = useRunMalwareScanMutation();

  const handleRunMalwareScan = async (base_url: string) => {
    Swal.fire({
      title: `Run malware scan for ${base_url}?`,
      showCancelButton: true,
      confirmButtonText: 'Scan Now!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await runMalwareScan(base_url);
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: `Malware Scan Failed`,
            text: `Failed to scan malware on ${base_url}. Please try again later.`
          })
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          confirmButtonColor: '#325AE7',
          title: `Malware Scan Successful`,
          text: `The url ${base_url} was successfully scheduled for a malware scan! Come back in about 15-20 minutes to see the result.`
        })
      }
    })
  }

  return (
    <>
      <Box sx={{
        borderRadius: '10px',
        border: '1px solid #AFBDFA',
        backgroundColor: '#ffffff',
        overflow: 'hidden',
        display: 'flex',
      }}>
        <Box sx={{
          minWidth: '60%',
          flex: '0 0 60%',
          padding: '18px',
          borderRight: '1px solid #AFBDFA',
        }}>
          <Typography sx={{
            display: 'flex',
            alignItems: 'baseline',
            marginBottom: '10px',
          }}>
            Latest security check:
            <Typography variant='h6' sx={{ marginLeft: '5px', }}>{moment(latest_scan?.scan_date, "YYYYMMDD").fromNow()}</Typography>
          </Typography>
          <Typography sx={{
            display: 'flex',
            alignItems: 'baseline',
            marginBottom: '10px',
          }}>
            Latest security check status:
            {latest_scan && !latest_scan.malware_found && !latest_scan.unrecognized_files && !latest_scan.checksum_fail && !latest_scan.vulnerability_found && !latest_scan.abandoned_plugin
              ? <Typography variant='h6' sx={{ color: '#2D9D78', marginLeft: '5px', }}>No problems found</Typography>
              : <Typography variant='h6' sx={{ color: '#E23A45', marginLeft: '5px', }}>Some problems found</Typography>
            }
          </Typography>

          <Box sx={{
            marginTop: '40px',
          }}>
            <Button sx={{
              backgroundColor: '#325AE7',
              borderRadius: '7px',
              border: '1px solid #325AE7',
              boxShadow: 'none',
              padding: '3px 14px',
              marginRight: '12px',
              fontSize: '14px',
              textTransform: 'inherit',
              minHeight: 'inherit',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#325AE7',
              },
            }}
              onClick={() => handleRunMalwareScan(base_url)}
            >
              Scan now
            </Button>
            <Button
              sx={{
                backgroundColor: '#ffffff',
                borderRadius: '7px',
                border: '1px solid #F4F5FE',
                boxShadow: 'none',
                padding: '3px 14px',
                fontSize: '14px',
                textTransform: 'inherit',
                whiteSpace: 'nowrap',
                minWidth: 'auto',
                color: '#1B1D29',
                minHeight: 'inherit',
                '&:hover': {
                  boxShadow: 'none',
                  borderColor: '#325AE7',
                  color: '#fff',
                  backgroundColor: '#325AE7',
                },
              }}
              onClick={() => handleButtonClick(2)}
            >
              View Results
            </Button>
          </Box>
        </Box>


        <Box sx={{
          minWidth: '40%',
          flex: '0 0 40%',
          '& p + p': {
            borderTop: '1px solid #AFBDFA',
          }
        }}>
          <Typography sx={{
            display: 'flex',
            padding: '18px',
          }}>
            Vulnerabilities:
            {
              latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.vulnerable
                ? <Typography variant='h6' sx={{ color: '#E23A45', marginLeft: '5px', }}>Detected</Typography>
                : <Typography variant='h6' sx={{ color: '#2D9D78', marginLeft: '5px', }}>Clean</Typography>
            }
          </Typography>
          <Typography sx={{
            display: 'flex',
            padding: '18px',
          }}>
            Core:
            {
              latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.core
                ? <Typography variant='h6' sx={{ color: '#E23A45', marginLeft: '5px', }}>Detected</Typography>
                : <Typography variant='h6' sx={{ color: '#2D9D78', marginLeft: '5px', }}>Clean</Typography>
            }
          </Typography>
          <Typography sx={{
            display: 'flex',
            padding: '18px',
          }}>
            Plugins:
            {
              latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.plugin
                ? <Typography variant='h6' sx={{ color: '#E23A45', marginLeft: '5px', }}>Detected</Typography>
                : <Typography variant='h6' sx={{ color: '#2D9D78', marginLeft: '5px', }}>Clean</Typography>
            }
          </Typography>
          <Typography sx={{
            display: 'flex',
            padding: '18px',
          }}>
            Themes:
            {
              latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.theme
                ? <Typography variant='h6' sx={{ color: '#E23A45', marginLeft: '5px', }}>Detected</Typography>
                : <Typography variant='h6' sx={{ color: '#2D9D78', marginLeft: '5px', }}>Clean</Typography>
            }
          </Typography>
          <Typography sx={{
            display: 'flex',
            padding: '18px',
          }}>
            Checksum Failed:
            {
              latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.checksum_fail
                ? <Typography variant='h6' sx={{ color: '#E23A45', marginLeft: '5px', }}>Detected</Typography>
                : <Typography variant='h6' sx={{ color: '#2D9D78', marginLeft: '5px', }}>Clean</Typography>
            }
          </Typography>
          <Typography sx={{
            display: 'flex',
            padding: '18px',
          }}>
            Abandoned Items:
            {
              latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.abandoned
                ? <Typography variant='h6' sx={{ color: '#E23A45', marginLeft: '5px', }}>Detected</Typography>
                : <Typography variant='h6' sx={{ color: '#2D9D78', marginLeft: '5px', }}>Clean</Typography>
            }
          </Typography>
          <Typography sx={{
            display: 'flex',
            padding: '18px',
          }}>
            Unrecognized Files:
            {
              latest_scan && latest_scan.scan_results && latest_scan.scan_results[0]?.unrecognized
                ? <Typography variant='h6' sx={{ color: '#E23A45', marginLeft: '5px', }}>Detected</Typography>
                : <Typography variant='h6' sx={{ color: '#2D9D78', marginLeft: '5px', }}>Clean</Typography>
            }
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SecurityCenter;
