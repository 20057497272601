import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "./apiSlice";

const SLICE_URL = "dashboard";

export interface IStatistics {
  update_count: number;
  backup_count: number;
  plugin_updates_count: number;
  downtime_count: number;
}

export interface ISiteUpdate {
  name: string;
  site_base_url: string;
  new_version: string;
  current_version?: string;
  old_version?: string;
  error?: boolean;
  error_message?: string | null;
  type: string;
  date?: string;
}

export interface IOpenUpdate {
  name: string;
  update_type: string;
  sites: ISiteUpdate[];
}

export interface IRecentUpdate {
  name: string;
  update_type: string;
  sites: ISiteUpdate[];
}

export interface IData {
  statistics: IStatistics;
  changelog: any[]; // Can be replaced with a proper interface when the structure is known
  attention: any[]; // Can be replaced with a proper interface when the structure is known
  open_updates: IOpenUpdate[];
  recent_updates: IRecentUpdate[];
  attentions: any[]; // Can be replaced with a proper interface when the structure is known
  recent_security_issues: any[]; // Can be replaced with a proper interface when the structure is known
  backups: any[];
  updates: any[];
  downtimes: any[];
  optimizations: any[];
}

export const initialDashboardData: IData = {
  statistics: {
    update_count: 0,
    backup_count: 0,
    plugin_updates_count: 0,
    downtime_count: 0,
  },
  backups: [],
  updates: [],
  downtimes: [],
  changelog: [],
  attention: [],
  open_updates: [],
  recent_updates: [],
  attentions: [],
  recent_security_issues: [],
  optimizations: [],
};

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardStats: builder.query<IData, void>({
      query: () => `${SLICE_URL}/`,
      // providesTags: (result, error, id) => [{ type: 'Dashboard', id }],
      providesTags: (
        result: IData | undefined,
        error: FetchBaseQueryError | undefined,
        id: void
      ) => {
        return ["Dashboard"];
      },
    }),
  }),
});

export const { useGetDashboardStatsQuery } = dashboardApiSlice;
