import React from 'react';
import { useFormik, FormikConfig } from 'formik';
import Swal from 'sweetalert2';
// Yup validators
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import QRCode from 'react-qr-code';
import { Dialog, DialogTitle, DialogContent, 
    Box,
    Button,
    TextField,
    Typography,
	Divider, 
} from '@mui/material';
import { IAccount, useGetTwoFASecretQuery, useSetupTwoFactorMutation } from '../../../Features/settingSlice';
YupPassword(Yup); // extend yup

interface PropTypes {
    openDialog: boolean;
    handleCloseDialog: () => void;
    accountSettings: IAccount;
}

const TwoFASetupDialog = ({ openDialog, handleCloseDialog, accountSettings }: PropTypes) => {
    const [setupTwoFactor, { isLoading }] = useSetupTwoFactorMutation();
    const { data: twofa_setup_token } = useGetTwoFASecretQuery();

    const formik = useFormik({
        initialValues: {
            action: accountSettings.two_factor ? 'disable' : 'enable',
            current_password: ''
        },
        validationSchema: Yup.object({
            action: Yup.string().required(),
            current_password: Yup.string().required(
                'Your current password is required.'
            )
        }),
        onSubmit: async(data, { resetForm, setErrors }) => {
            try {
                const setupResults = await setupTwoFactor(data).unwrap();
                resetForm();
                handleCloseDialog();
                Swal.fire({
                    title: '2FA Setup Successful!',
                    text: '2 Factor Authentication Setup has been saved successfully!',
                    icon: 'success'
                });
            } catch (error: any) {
                setErrors({ current_password: error.data.message });
            }
        }
    });

    console.log(formik.errors);

    return (
        <Dialog
            className="dialog-accept" 
            open={openDialog}
            fullWidth={false} 
            maxWidth="md"
            PaperProps={{
                style: {
                    background: '#FFFFFF',
                    boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
                    borderRadius: '12px'
                },
            }}
        >
            <Box>
                <DialogTitle>
                    <Typography variant="h2">
                        Setup 2 Factor Authentication
                    </Typography>
                </DialogTitle>

                <Divider />

                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        { !accountSettings.two_factor &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant="body1"  sx={{ my: 2, mx: 'auto' }}>
                                    Scan the QR Code below using your Authenticator App to generate your secure 2FA code.
                                </Typography>

                                <Box sx={{ my: 2, mx: 'auto' }}>
                                    {twofa_setup_token?.mfa_setup && <QRCode value={`otpauth://totp/WPMonitoring?secret=${twofa_setup_token?.mfa_setup}&issuer=WPMonitoring`} />}
                                </Box>

                                <Box sx={{ my: 2, mx: 'auto', textAlign: 'center' }}>
                                    <Typography sx={{ mb: 2 }}>
                                        Alternatively, you can copy this code to your Authenticator App to generate the 2FA Code
                                    </Typography>
                                    <Typography>
                                        { twofa_setup_token?.mfa_setup }
                                    </Typography>
                                </Box>
                            </Box>
                        }

                        <Box sx={{ my: 2, mx: 'auto' }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '1rem',
                                        paddingTop: '0.5rem',
                                        gap: '1rem'
                                    }}
                                >
                                    <TextField
                                        error={Boolean(formik.touched.action && formik.errors.action)}
                                        fullWidth
                                        helperText={formik.touched.action && formik.errors.action}
                                        label="Action"
                                        placeholder="Action"
                                        margin="normal"
                                        type="text"
                                        {...formik.getFieldProps('action')}
                                        variant="outlined"
                                        inputProps={{
                                            style: { backgroundColor: 'white' },
                                        }}
                                        sx={{ display: 'none' }}
                                    />
                                    <TextField
                                        error={Boolean(formik.touched.current_password && formik.errors.current_password)}
                                        fullWidth
                                        helperText={formik.touched.current_password && formik.errors.current_password}
                                        label="Current Password"
                                        placeholder="Current Password"
                                        margin="normal"
                                        type="password"
                                        {...formik.getFieldProps('current_password')}
                                        variant="outlined"
                                        inputProps={{
                                            style: { backgroundColor: 'white', width: '100%' },
                                        }}
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                                        <Button
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            disabled={isLoading}
                                        >
                                            {accountSettings.two_factor ? "Disable 2FA" : "Setup 2FA"}
                                        </Button>

                                        <Button
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            // disabled={isLoading}
                                            onClick={() => handleCloseDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

export default TwoFASetupDialog