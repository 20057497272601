import React from 'react';
import { BackupInfo } from '../BackupTab';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import desktopDownload from '../../../Assets/Images/desktop_download.svg';
import wordpressIcon from '../../../Assets/Images/wordpress-icon2.svg';
import pluginIcon from '../../../Assets/Images/plugin-icon.svg';
import themeIcon from '../../../Assets/Images/theme-icon.svg';
import { useDownloadBackupLinkMutation } from '../../../Features/siteSlice';
import Swal from 'sweetalert2';

interface PropTypes {
    backup: BackupInfo;
	index: number;
	website_url: string;
}

const BackupTabFilter = ({ backup, index, website_url }: PropTypes) => {
	const [downloadBackupLink, { isLoading }] = useDownloadBackupLinkMutation();

	const handleDownloadBackup = async (path: string) => {
		try {
			const backupDownloadLink = await downloadBackupLink({website_url, path}).unwrap();
			if (backupDownloadLink) {
				window.open(backupDownloadLink.link, '_blank')
			}
		} catch (error) {
			Swal.fire({
				icon: 'error',
				title: `Unable to Download Backup`,
				text: `Failed to download backup. Please try again later.`
			})
		}
	}

	return (
		<Box key={index} sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '16px 18px',
			'@media (max-width: 767px)': {
				display: 'block',
			}
		}}>
			<Box sx={{
				display: 'flex',
				alignItems: 'center',
				'& img': {
					width: '40px',
				}
			}}>

				<Typography variant='h6' sx={{maxWidth: '180px',minWidth: '180px',}}>
					{moment(backup.creation_date).format('MMMM Do YYYY, h:mm:ss a')}
				</Typography>
			</Box>
			<Box sx={{
				'@media (max-width: 767px)': {
					padding: '15px 0',
					justifyContent: 'flex-start'
				},
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				padding: '0 20px',
				justifyContent: 'center',
			}}>
				<Typography sx={{
					position: 'relative',
					padding: '0 20px 0 20px',
					display: 'flex',
					alignItems: 'center',
					'& img': {
						marginRight: '5px',
					}
				}}>
					<img src={desktopDownload} />
					{`${backup.type.charAt(0).toUpperCase()}${backup.type.slice(1).toLowerCase()} Backup`}
				</Typography>
				<Typography sx={{
					position: 'relative',
					padding: '0 20px 0 20px',
					display: 'flex',
					alignItems: 'center',
					'& img': {
						marginRight: '5px',
					}
				}}>
					<img src={wordpressIcon} />
					{backup.wp_version}
				</Typography>
				<Typography sx={{
					position: 'relative',
					padding: '0 20px 0 20px',
					display: 'flex',
					alignItems: 'center',
					'& img': {
						marginRight: '5px',
					}
				}}>
					<img src={pluginIcon} />
					{backup.plugin_count} plugins
				</Typography>
				<Typography sx={{
					position: 'relative',
					padding: '0 20px 0 20px',
					display: 'flex',
					alignItems: 'center',
					'& img': {
						marginRight: '5px',
					}
				}}>
					<img src={themeIcon} />
					{backup.current_theme}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Button
					disabled={isLoading}
					sx={{
						backgroundColor: '#325AE7',
						borderRadius: '7px',
						border: '1px solid #325AE7',
						boxShadow: 'none',
						padding: '7px 19px',
						maxWidth: '100%',
						textTransform: 'inherit',
						width: '100%',
						'&:hover': {
							boxShadow: 'none',
							backgroundColor: '#325AE7',
						},
						'& img': {
							marginRight: '10px',
						}
					}}
					onClick={() => handleDownloadBackup(backup.path)}
				>
					{ isLoading ? 'Downloading...' : 'Download' }
				</Button>
			</Box>
		</Box>
	)
}

export default BackupTabFilter