import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Logo from "../../Assets/Images/logo.svg";
import SidePanelComponent from "../SidePanelComponent";
import { Link } from "react-router-dom";
import Setting from "../../Assets/Images/Settings.svg";
import Synchronize from "../../Assets/Images/synchronize.svg";
import Readmessage from "../../Assets/Images/read_message.svg";
import Multiply from "../../Assets/Images/multiply_1.svg";
import Notificationheader from "../../Assets/Images/notification-header.svg";
import Notificationsenvelope from "../../Assets/Images/notifications-envelope.svg";
import Notification from "../../Assets/Images/Notification.svg";
import Help from "../../Assets/Images/Help.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useGetSitesQuery } from "../../Features/siteSlice";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
// the width of drawer

export const drawerWidth = 100;

const HeaderComponent = () => {
  /*-------------Initailizations-------------*/

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [notificationClose, setNotificationClose] = React.useState(false);
  /*-------------Initailizations-------------*/

  /*----------------Handlers------------------*/

  // handles drawer toogle in mobile devices
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  /*----------------Handlers------------------*/

  const { data: sitesData } = useGetSitesQuery();

  const filteredSitesData = React.useMemo(() => {
    if (!sitesData?.results) return [];

    // Adding a null check for 'searchQuery' before calling 'toLowerCase'
    const lowerCaseSearchQuery = searchQuery ? searchQuery.toLowerCase() : "";
    return sitesData.results.filter(
      (site) =>
        // Adding null checks for 'site.title' and 'site.url' before calling 'toLowerCase' and 'includes'
        (site.title &&
          site.title.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (site.url && site.url.toLowerCase().includes(lowerCaseSearchQuery))
    );
  }, [sitesData, searchQuery]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "none !important",
          backgroundColor: "#ffffff",
          minHeight: "100px",
          justifyContent: "center",
          ml: { sm: `${drawerWidth}px` },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 40px 0 30px !important",
            "@media (max-width: 1199px)": {
              padding: "0 20px 0 20px !important",
            },
          }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { lg: "none" },
                background: "#325AE7",
                borderRadius: 0,
                margin: "0px 15px 0 0px",
                color: "#ffffff",
                "&:hover": {
                  background: "#325AE7",
                  color: "#ffffff",
                },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ lineHeight: 0 }}>
              <a href="/">
                <img alt="" src={Logo} />
              </a>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              type="button"
              sx={{
                padding: "0",
                marginRight: "25px",
                lineHeight: 0,
              }}
            >
              <Link to="/">
                <img src={Synchronize} />
              </Link>
            </IconButton>
            {(['right'] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  onClick={toggleDrawer(anchor, true)}
                  type="button"
                  sx={{
                    padding: "0",
                    marginRight: "25px",
                  }}
                >
                  <img src={Notificationheader} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  sx={{
                    zIndex: '1201',
                    '& .MuiDrawer-paper': {
                      minWidth: '500px',
                      maxWidth: '500px',
                    }
                    // '& .MuiBackdrop-root': {
                    //   display: 'none',
                    // }
                  }}
                >
                  <Box>
                    <Box sx={{
                      padding: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      background: '#ffffff',
                      borderBottom: '1px solid #AFBDFA',
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                    }}>
                      <Typography
                        component="h4"
                        sx={{
                          fontSize: '26px',
                          fontWeight: '600',
                          lineHeight: 'normal'
                        }}
                      >
                        Notifications
                      </Typography>
                      <Box>
                        <IconButton
                          type="button"
                          sx={{
                            padding: "0",
                            lineHeight: 0,
                          }}
                        >
                          <img src={Readmessage} />
                        </IconButton>
                        <IconButton
                          type="button"
                          onClick={toggleDrawer(anchor, false)}
                          sx={{
                            padding: "0",
                            marginLeft: '25px',
                            lineHeight: 0,
                          }}
                        >
                          <img src={Multiply} />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box sx={{
                      '& > div + div' :{
                        borderTop: '1px solid #AFBDFA',
                      }
                    }}>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{
                        padding: '15px',
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                          <Typography component="h6" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            '& img': {
                              marginRight: '5px',
                            }
                          }}>
                            <img src={Notificationsenvelope} />
                            Boekentop60
                          </Typography>
                          <Typography sx={{
                            fontSize: '14px',
                          }}>
                            March 26, 2023
                          </Typography>
                        </Box>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '10px',
                        }}>
                          <Typography sx={{
                            width: '80%'
                          }}>
                            Scheduled incremental backup with Clone add-onand free cloud storage.
                          </Typography>
                          <Typography sx={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#325AE7',
                          }}>
                            Action
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Drawer>
              </React.Fragment>
            ))}
            <IconButton
              type="button"
              sx={{
                padding: "0",
                marginRight: "25px",
              }}
            >
              <Link to="/settings/">
                <img src={Setting} />
              </Link>
            </IconButton>
            {/* <IconButton
              type="button"
              sx={{
                padding: '0',
                marginRight: '25px',
              }}
            >
              <img src={Notification} />
            </IconButton> */}
            <IconButton
              sx={{
                padding: "0",
                marginRight: "40px",
                "@media (max-width: 600px)": {
                  marginRight: "0",
                },
              }}
            >
              <a target="_blank" href="https://wpmonitoring.crunch.help/en">
                <img src={Help} />
              </a>
            </IconButton>
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
                "@media (max-width: 600px)": {
                  display: "none",
                },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  padding: "6px 20px 6px 20px",
                  backgroundColor: "#FAFBFF",
                  borderRadius: "8px",
                  border: "1px solid #AFBDFA",
                  "@media (max-width: 600px)": {
                    display: "none",
                  },
                }}
              >
                <IconButton
                  type="button"
                  aria-label="search"
                  sx={{
                    padding: "0",
                    backgroundColor: "#FAFBFF",
                    borderRadius: "8px",
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{
                    fontSize: "16px",
                    color: "#525566",
                    minWidth: "216px",
                    marginLeft: "20px",
                  }}
                  placeholder="Search Here"
                  type="search"
                  inputProps={{ "aria-label": "Search Here" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {/* Render the dropdown suggestion */}
                {searchQuery && (
                  <List
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      backgroundColor: "#FAFBFF",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      marginTop: "6px",
                      padding: "8px",
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  >
                    {filteredSitesData.map((site, index) => (
                      <ListItem key={index}>
                        <a
                          href={`/sites/${site.base_url}/`}
                          style={{
                            cursor: "pointer", // Add cursor pointer to indicate it's clickable
                            textDecoration: "none",
                            color: "#000000",
                          }}
                        >
                          {site.title}
                        </a>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          "& ::-webkit-scrollbar": {
            width: 0,
            height: "2px",
          },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <SidePanelComponent handleClick={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <SidePanelComponent />
        </Drawer>
      </Box>
    </Box>
  );
};
export default HeaderComponent;
