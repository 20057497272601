import * as React from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import "./style.css";
import OverviewTab, {
  OpenUpdate,
  SiteSettingsForm,
} from "../../Components/UI/OverviewTab";
import WordpressTab from "../../Components/UI/WordpressTab";
import SecurityAndActivityTab from "../../Components/UI/SecurityAndActivityTab";
import PerformanceTab from "../../Components/UI/PerformanceTab";
import BackupsTab from "../../Components/UI/BackupsTab";
import SettingsTab from "../../Components/UI/SettingsTab";
import {
  useGetSiteQuery,
  useSiteDirectLoginMutation,
  useSetAutopilotMutation,
  useSetSiteSettingsMutation,
  useAddWhitelistIpMutation,
  useMakeBackupMutation,
  useSetTitleAdminPackageMutation,
  useChangeThemeMutation,
  useUpdatePluginsMutation,
  useTogglePluginMutation,
  useDeletePluginMutation,
  useCheckBrokenLinksMutation,
  useSchedulePluginUpdateMutation,
} from "../../Features/siteSlice";
import Loader from "../../Components/UI/Loader";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import Info from "../../Assets/Images/info-icon.svg";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export interface ISite {
  active: boolean;
  auto_pilot: boolean;
  base_url: string;
  date_added: string;
  favorite: boolean;
  image_file: string;
  labels: string;
  last_backup: string;
  last_check: string;
  last_downtime: string | null;
  last_plugin_check: string;
  last_rollback: string;
  last_update: string | null;
  open_update_count: number;
  package_name: string;
  package_security: boolean;
  pagespeed_score: number;
  php: string | null;
  security_issue: string;
  status: string;
  timezone: string;
  title: string;
  url: string;
  version: string;
}

const WebsiteOverview = () => {
  const { website_url } = useParams();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleButtonClick = (tabIndex: number) => {
    setValue(tabIndex);
  };

  const { data: siteData, isLoading, refetch } = useGetSiteQuery(website_url);
  const [siteDirectLogin] = useSiteDirectLoginMutation();
  const [setAutopilot] = useSetAutopilotMutation();
  const [setSiteSettings, { isLoading: isUpdatingSiteSettings }] =
    useSetSiteSettingsMutation();
  const [addWhitelistIp] = useAddWhitelistIpMutation();
  const [makeBackup] = useMakeBackupMutation();
  const [setTitleAdminPackage] = useSetTitleAdminPackageMutation();
  const [changeTheme, { isLoading: changingTheme }] = useChangeThemeMutation();
  const [updatePlugins] = useUpdatePluginsMutation();
  const [schedulePluginUpdate] = useSchedulePluginUpdateMutation();
  const [togglePlugin] = useTogglePluginMutation();
  const [deletePlugin] = useDeletePluginMutation();
  const [checkBrokenLinks] = useCheckBrokenLinksMutation();

  React.useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 300000); // 300000ms is equivalent to 5 minutes

    return () => clearInterval(interval);
  }, [refetch]);

  const handleSiteDirectLogin = async (website_url: string) => {
    try {
      const siteLoginData = await siteDirectLogin(website_url).unwrap();
      if (siteLoginData) {
        window.open(siteLoginData.link, "_blank");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `Failed to Login`,
        text: `Failed to login to ${website_url}. Please try again later.`,
      });
    }
  };

  const handleSetAutopilot = async (website_url: string) => {
    try {
      await setAutopilot({
        website_url,
        autopilot: !siteData?.site?.auto_pilot,
      }).unwrap();
      Swal.fire({
        icon: "success",
        confirmButtonColor: "#325AE7",
        title: `Autopilot change successful`,
        text: "Website autopilot changes was successful!",
      });
      refetch();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Autopilot change failed",
        text: "Website autopilot changes was not successful.",
      });
    }
  };

  const handleUpdatePlugins = async (plugins: string) => {
    Swal.fire({
      title: "Update plugin to the latest version?",
      showCancelButton: true,
      confirmButtonText: "Update!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await updatePlugins({
            website_url: siteData?.site?.base_url,
            plugins: plugins,
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: `Plugin Update Failed`,
            text: `Failed to update the plugin. Please try again later.`,
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#325AE7",
          title: `Plugin Update Requested`,
          text: `Your plugin update has been requested. This might take 5-10 minutes before it executes.`,
        });
      }
    });
  };

  const handleSchedulePluginsUpdate = async (
    plugins: string,
    delay: number
  ) => {
    const schedule_info = {
      plugins,
      delay,
    };
    Swal.fire({
      title: `Schedule Plugin Update for ${delay} days?`,
      showCancelButton: true,
      confirmButtonText: "Schedule!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await schedulePluginUpdate({
            website_url: siteData?.site?.base_url,
            schedule_info,
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: `Plugin Scheduled Update Failed`,
            text: `Failed to schedule an update for the plugin. Please try again later.`,
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#325AE7",
          title: `Plugin Update Scheduled`,
          text: `Your plugin update has been scheduled successfully!`,
        });
      }
    });
  };

  const handleSubmitQuickAction = async (site_settings: SiteSettingsForm) => {
    try {
      await setSiteSettings({
        base_url: siteData?.site?.base_url,
        site_settings,
      }).unwrap();
      Swal.fire({
        icon: "success",
        confirmButtonColor: "#325AE7",
        title: "Update Successful",
        text: "Quick actions update was successful!",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Update Failed",
        text: "Failed to update quick actions. Please try again later.",
      });
    }
  };

  const handleWhitelistIp = async (ip_address: string) => {
    const whitelist = { ip_address, all: false };
    try {
      const whitelistedIp = await addWhitelistIp({
        website_url: siteData?.site?.base_url,
        whitelist,
      });
      Swal.fire({
        icon: "success",
        confirmButtonColor: "#325AE7",
        title: `IP Whitelist Successful`,
        text: `Your IP Address ${ip_address} has been whitelisted successfully!`,
      });
      refetch();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `IP Whitelisting Failed`,
        text: `Failed to whitelist IP ${ip_address}. Please try again later.`,
      });
    }
  };

  const handleMakeBackup = async (type: string) => {
    Swal.fire({
      title: `Do you want to run a ${type} backup?`,
      showCancelButton: true,
      confirmButtonText: "Yes, Backup!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await makeBackup({ website_url: siteData?.site?.base_url, type });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: `Backup Failed`,
            text: `Running backup for ${type} failed. Please try again later.`,
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#325AE7",
          title: `Running Backup Successful`,
          text: `${type} have been backed up successfully!`,
        });
      }
    });
  };

  const handleChangeTheme = async (theme_name: string) => {
    Swal.fire({
      title: `Activate ${theme_name} theme?`,
      showCancelButton: true,
      confirmButtonText: "Yes, Activate!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await changeTheme({
            website_url: siteData?.site?.base_url,
            theme_name,
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Theme Change Failed",
            text: "Failed to change the theme. Please try again later.",
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#325AE7",
          title: `Theme Change Successful`,
          text: `${theme_name} has been actiavated successfully!`,
        });
      }
    });
  };

  const handleTogglePlugin = async (
    plugin_name: string,
    activation: boolean
  ) => {
    const pluginToggleData = {
      plugin_name,
      activation,
    };
    console.log("Sending togglePlugin", pluginToggleData);

    try {
      await togglePlugin({
        website_url: siteData?.site?.base_url,
        pluginToggleData,
      });
      Swal.fire({
        icon: "success",
        confirmButtonColor: "#325AE7",
        title: `Plugin ${
          activation ? "Activation" : "Deactivation"
        } Successful`,
        text: `Your plugin has been ${
          activation ? "activated" : "deactivated"
        } successfully!`,
      });
      refetch();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `Plugin ${activation ? "Activation" : "Deactivation"} Failed`,
        text: `Failed to ${
          activation ? "activate" : "deactivate"
        } the plugin. Please try again later.`,
      });
    }
  };

  const handleDeletePlugin = async (
    plugin_path: string,
    plugin_name: string
  ) => {
    Swal.fire({
      title: `Delete ${plugin_name} plugin?`,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await deletePlugin({
            website_url: siteData?.site?.base_url,
            plugin_name,
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: `Plugin Delete Failed`,
            text: `Failed to delete ${plugin_name} plugin. Please try again later.`,
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#325AE7",
          title: `Plugin Deleted Successful`,
          text: `${plugin_name} has been deleted successfully!`,
        });
      }
    });
  };

  const handleCheckBrokenLinks = async () => {
    Swal.fire({
      title: `Scan for broken links on this website?`,
      showCancelButton: true,
      confirmButtonText: "Yes, Scan Now!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await checkBrokenLinks(siteData?.site?.base_url);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: `Broken Links Scan Failed`,
            text: `Failed to scan for broken links. Please try again later.`,
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#325AE7",
          title: `Broken Links Scan Successful`,
          text: `Scan for broken links completed successfully!`,
        });
      }
    });
  };

  const handleSaveGeneralSettings = async (type: string, value: string) => {
    let data;
    let url_link;

    if (type === "title") {
      url_link = "set_title";
      data = {
        title: value,
      };
    }

    if (type === "package") {
      url_link = "set_package";
      data = {
        package_name: value,
      };
    }

    if (type === "username") {
      url_link = "set_admin";
      data = {
        username: value,
      };
    }

    try {
      if (url_link && data) {
        await setTitleAdminPackage({
          website_url: siteData?.site?.base_url,
          url_link,
          data,
        });
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#325AE7",
          title: `${
            type === "title"
              ? "Website Title"
              : type === "username"
              ? "Admin Username"
              : "Package"
          } set successfully!`,
          text: `Your changes have been saved and will reflect shortly!`,
        });
        refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const wordpressUpdateAvailable = () => {
    const update = siteData?.open_updates?.find(
      (update: OpenUpdate) => update.type === "core"
    );
    return !!update; // Returns true if update is found, false otherwise
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: "40px",
    height: "20px",
    padding: 0,
    display: "flex",
    borderRadius: "100px",
    background: "#BECBFF",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      color: "#14161F",
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#325AE7",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "",
      width: "16px",
      height: "16px",
      borderRadius: "100px",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  {
    if (isLoading) {
      return <Loader />;
    }
  }

  return (
    <Box>
      <Box
        sx={{
          padding: "20px 40px",
          borderBottom: "1px solid #AFBDFA",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "#FAFBFF",
          position: "sticky",
          top: "0",
          zIndex: "2",
          "@media (max-width: 1400px)": {
            padding: "20px 20px",
          },
          "@media (max-width: 1199px)": {
            position: "relative",
          },
          "@media (max-width: 767px)": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            "@media (max-width: 767px)": {
              marginBottom: "15px",
            },
          }}
        >
          <Typography variant="h1">{siteData?.site?.title}</Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              "@media (max-width: 767px)": {
                marginBottom: "15px",
              },
            }}
          >
            {siteData?.site?.url}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "@media (max-width: 400px)": {
              display: "block",
            },
          }}
        >
          <Box
            sx={{
              border: "1px solid #AFBDFA",
              borderRadius: "5px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "9px 20px",
            }}
          >
            <Typography
              sx={{
                marginRight: "11px",
                fontWeight: "600",
              }}
            >
              <div>
                Autopilot
                <Tooltip title="See the Latest Security scan results here.">
                  <span style={{ marginLeft: "5px" }}>ⓘ</span>
                </Tooltip>
              </div>
            </Typography>

            <AntSwitch
              defaultChecked={siteData?.site?.auto_pilot}
              inputProps={{ "aria-label": "ant design" }}
              onClick={() => handleSetAutopilot(siteData.site.base_url)}
            />
          </Box>
          <Box
            sx={{
              marginLeft: "12px",
              "@media (max-width: 400px)": {
                marginLeft: "0",
                marginTop: "15px",
              },
            }}
          >
            <Button
              sx={{
                backgroundColor: "#325AE7",
                borderRadius: "7px",
                border: "1px solid #325AE7",
                boxShadow: "none",
                padding: "7px 19px",
                textTransform: "inherit",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#325AE7",
                },
                "& img": {
                  marginRight: "10px",
                },
              }}
              onClick={() => handleSiteDirectLogin(siteData?.site?.base_url)}
            >
              Direct website login
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            paddingLeft: "40px",
            borderBottom: "1px solid #AFBDFA",
            position: "sticky",
            top: "100px",
            background: "#FAFBFF",
            zIndex: "9",
            "@media (max-width: 1199px)": {
              top: "0",
              position: "relative",
              paddingLeft: "20px",
              paddingRight: "20px",
            },
          }}
        >
          <Tabs
            value={value}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& button": {
                padding: "20px 0",
                fontSize: "16px",
                fontWeight: "600",
                textTransform: "capitalize",
                minHeight: "auto",
                minWidth: "auto",
              },
              "& button + button": {
                marginLeft: "30px",
              },
            }}
          >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Wordpress" {...a11yProps(1)} />
            <Tab label="Security and activity" {...a11yProps(2)} />
            <Tab label="Performance" {...a11yProps(3)} />
            <Tab label="Backups" {...a11yProps(4)} />
            <Tab label="Settings" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OverviewTab
            base_url={siteData?.site?.base_url}
            site_settings={siteData?.site_settings}
            recent_updates={siteData?.recent_updates}
            open_updates={siteData?.open_updates}
            isUpdatingSiteSettings={isUpdatingSiteSettings}
            blocked_ips_count={siteData?.blocked_ips?.length}
            vulnerability_found={siteData?.latest_scan?.vulnerability_found}
            broken_link_count={siteData?.broken_links?.broken_link_count}
            avg_load_time={siteData?.uptime?.avg_load_time}
            backups_count={siteData?.backups?.length}
            handleUpdatePlugins={handleUpdatePlugins}
            handleSchedulePluginsUpdate={handleSchedulePluginsUpdate}
            handleSubmitQuickAction={handleSubmitQuickAction}
            latest_scan={siteData?.latest_scan}
            handleButtonClick={handleButtonClick}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WordpressTab
            open_updates={siteData?.open_updates}
            themes={siteData?.themes}
            handleChangeTheme={handleChangeTheme}
            changingTheme={changingTheme}
            plugins={siteData?.plugins}
            handleTogglePlugin={handleTogglePlugin}
            handleUpdatePlugins={handleUpdatePlugins}
            handleDeletePlugin={handleDeletePlugin}
            wordpressVersion={siteData?.site.version}
            wordpressUpdateAvailable={wordpressUpdateAvailable}
            website_url={siteData?.site?.base_url}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SecurityAndActivityTab
            base_url={siteData?.site?.base_url}
            whitelisted_ips={siteData?.whitelisted_ips}
            handleWhitelistIp={handleWhitelistIp}
            latest_scan={siteData?.latest_scan}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PerformanceTab
            pagespeed={siteData?.pagespeed}
            uptime={siteData?.uptime}
            pagespeed_score={siteData?.site?.pagespeed_score}
            broken_links={siteData?.broken_links}
            handleCheckBrokenLinks={handleCheckBrokenLinks}
            optimizations={siteData?.site_optimization}
            siteBaseUrl={siteData?.site?.base_url}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <BackupsTab
            backups={siteData?.backups}
            handleMakeBackup={handleMakeBackup}
            website_url={siteData?.site?.base_url}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <SettingsTab
            handleSaveGeneralSettings={handleSaveGeneralSettings}
            site={siteData?.site}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default WebsiteOverview;
