/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Tab, Tabs, Typography } from '@mui/material';
import * as React from 'react';
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
    optionUnstyledClasses,
  } from "@mui/base/OptionUnstyled";
import controlSlider from "../../../Assets/Images/control-slider.svg";
import { styled } from '@mui/material/styles';
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { ILog } from '../../../Features/securitySlice';
import AuditSecurityTabPanel from '../AuditSecurityTabPanel';
import { IPaginationState } from '../../../Views/Security';

const StyledButton = styled("button")(
    ({ theme }) => `
    box-sizing: border-box;
    min-width: 184px;
    padding: 7px 12px;
    border-radius: 7px;
    text-align: left;
    line-height: 1.5;
    background: #325AE7;
    font-size: 16px;
    border: 1px solid #AFBDFA;
    color: #ffffff;
    font-family: Titillium Web;
    font-weight: 600;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    
    
    &.${selectUnstyledClasses.expanded} {
      &::after {
        float: right;
        transform: rotate(180deg);
      }
    }
    
    &::after {
      content: '▾';
      float: right;
    }
    `
  );
  const StyledListbox = styled("ul")(
    ({ theme }) => `
    box-sizing: border-box;
    padding: 6px;
    min-width: 200px;
    border-radius: 12px;
    font-family: Titillium Web;
    font-weight: 600;
    overflow: auto;
    outline: 0px;
    background: #ffffff;
    font-size: 14px;
    border: 1px solid #AFBDFA;
    color: #1B1D29;
    `
  );
  
  const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    
    &:last-of-type {
      border-bottom: none;
    }
    
    &.${optionUnstyledClasses.selected} {
      background-color: #325AE7;
      color: #ffffff;
    }
    
    &.${optionUnstyledClasses.highlighted} {
      background-color: #325AE7;
      color: #ffffff;
    }
    
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: #325AE7;
      color: #ffffff;
    }
    `
  ); 
  const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`; 
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export type PageChangeHandler = (page: number) => void;
export type EventChangeHandler = (event: string) => void;

interface PropTypes {
    logs: ILog[];
    handlePageChange: PageChangeHandler;
    handleEventChange: EventChangeHandler;
    loadingLogs: boolean;
    isFetching: boolean;
    pagination: IPaginationState;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
const CustomSelect = React.forwardRef(function CustomSelect<
    TValue extends {},
    Multiple extends boolean
  >(
    props: SelectUnstyledProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) {
    const slots: SelectUnstyledProps<TValue, Multiple>["slots"] = {
      root: StyledButton,
      listbox: StyledListbox,
      popper: StyledPopper,
      ...props.slots,
    };
  
    return <SelectUnstyled {...props} ref={ref} slots={slots} />;
  }) as <TValue extends {}, Multiple extends boolean>(
    props: SelectUnstyledProps<TValue, Multiple> &
      React.RefAttributes<HTMLButtonElement>
  ) => JSX.Element;

const AuditSecurityTab = ({ logs, handlePageChange, handleEventChange, loadingLogs, isFetching, pagination }: PropTypes) => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Box sx={{
                width: '100%',
            }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                        justifyContent: "space-between",
                        "@media (max-width: 767px)": {
                            display: "block",
                        },
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: "18px",
                            marginBottom: "0",
                            "@media (max-width: 767px)": {
                                marginBottom: "10px",
                            },
                        }}
                    >
                        Audit Trails
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: " 7px",
                                border: "1px solid #AFBDFA",
                                background: "#fff",
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                            }}
                        >
                            <Box
                                sx={{
                                    borderRight: "1px solid #AFBDFA",
                                    width: "43px",
                                    height: "43px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img src={controlSlider} />
                            </Box>
                            <Tabs
                                value={value}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                onChange={handleChange}
                                sx={{
                                    minHeight: "auto",
                                    padding: "0 20px",
                                    "& .MuiTabs-indicator": {
                                        display: "none",
                                    },
                                    "& button": {
                                        padding: "0",
                                        textTransform: "capitalize",
                                        minHeight: "auto",
                                        minWidth: "auto",
                                    },
                                    "& button + button": {
                                        borderLeft: "1px solid #AFBDFA  ",
                                        marginLeft: "10px",
                                        paddingLeft: "10px",
                                    },
                                }}
                            >
                                <Tab label="All" {...a11yProps(0)} onClick={() => handleEventChange('') } />
                                <Tab label="Login Block" {...a11yProps(1)} onClick={() => handleEventChange('login_block') } />
                                <Tab label="IP Block" {...a11yProps(2)} onClick={() => handleEventChange('ip_block_auto') } />
                            </Tabs>
                        </Box>
                        <Box
                            sx={{
                                "& > button": {
                                    padding: "10px 16px",
                                },
                            }}
                        >
                            <CustomSelect defaultValue={10}>
                                <StyledOption value={10}>Sort</StyledOption>
                                <StyledOption value={20}>Name ascending</StyledOption>
                                <StyledOption value={30}>Name descending</StyledOption>
                                <StyledOption value={40}>Date ascending</StyledOption>
                                <StyledOption value={50}>Date descending</StyledOption>
                            </CustomSelect>
                        </Box>
                    </Box>
                </Box>
                <TabPanel value={value} index={0}>
                    <AuditSecurityTabPanel 
                        logs={logs}
                        handlePageChange={handlePageChange}
                        loadingLogs={loadingLogs}
                        isFetching={isFetching}
                        pagination={pagination}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AuditSecurityTabPanel 
                        logs={logs}
                        handlePageChange={handlePageChange}
                        loadingLogs={loadingLogs}
                        isFetching={isFetching}
                        pagination={pagination}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <AuditSecurityTabPanel 
                        logs={logs}
                        handlePageChange={handlePageChange}
                        loadingLogs={loadingLogs}
                        isFetching={isFetching}
                        pagination={pagination}
                    />
                </TabPanel>
            </Box>
        </Box>
    );
};

export default AuditSecurityTab;
