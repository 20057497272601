import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from './apiSlice';

const SLICE_URL = 'settings'

export const accountApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: (loginData) => ({
                url: `/login/`,
                method: 'POST',
                body: {
                    ...loginData
                }
            }),
            invalidatesTags: [
                { type: 'Account', id: "LIST" }
            ]
        }),
    })
});

export const {
    useLoginMutation,
} = accountApiSlice;