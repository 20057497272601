import React from "react";
import {
  pdf,
  Page,
  View,
  Text,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import moment from "moment";

import Report1Img from "../../Assets/Images/report-1.jpg";
import Report2Img from "../../Assets/Images/report-2.jpg";
import Report3Img from "../../Assets/Images/report-3.jpg";
import Report4Img from "../../Assets/Images/report-4.png";
import { IReportData } from "./ReportPreview";
import { rgba } from "polished";

interface PropTypes {
  reportData: IReportData;
}

const getUptime = (uptime: string | undefined): string => {
  if (uptime) {
    const value = parseFloat(uptime);
    if (!isNaN(value)) {
      return value.toFixed(1);
    }
  }
  return "100.0"; // default value in case of undefined or invalid string
};

const ReportPDF = ({ reportData }: PropTypes) => {
  const primaryColor =
    reportData.brand_color && reportData.brand_color.trim() !== ""
      ? reportData.brand_color
      : "#000000"; // default to black if undefined
  const contrastColor =
    reportData.contrast_color && reportData.contrast_color.trim() !== ""
      ? reportData.contrast_color
      : "#FFFFFF"; // default to white if undefined

  const styles = StyleSheet.create({
    bottomSlantingDivs: {
      content: "",
      position: "absolute",
      bottom: 0,
      height: "100mm",
      width: "210mm",
      backgroundColor: "white",
    },
    reportRibbonStyles: {
      position: "absolute",
      height: "30mm",
      width: "210mm",
      bottom: "100mm",
      backgroundColor: primaryColor + "80",
    },
    reportSummary: {
      position: "absolute",
      right: 0,
      bottom: 0,
      width: "100%",
      backgroundColor: "white",
      textAlign: "right",
      paddingRight: "32px",
      paddingBottom: "32px",
    },
    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
    tableHeader: {
      flexDirection: "row",
      alignItems: "center",
      fontSize: 9,
      paddingVertical: "8px",
      textAlign: "center",
      backgroundColor: primaryColor + "80",
      color: "rgb(255, 255, 255)",
    },
    tableRowOdd: {
      flexDirection: "row",
      alignItems: "center",
      fontSize: 9,
      paddingVertical: "8px",
      textAlign: "center",
      backgroundColor: primaryColor + "80",
      color: "rgb(255, 255, 255)",
    },
    tableRowEven: {
      flexDirection: "row",
      alignItems: "center",
      fontSize: 9,
      paddingVertical: "8px",
      textAlign: "center",
      backgroundColor: primaryColor + "33",
      color: primaryColor,
    },
    descriptionColumn: {
      width: "50%",
    },
    dataColumn: {
      width: "50%",
    },
    firstThirdColumn: {
      width: "25%",
    },
    secondThirdColumn: {
      width: "25%",
    },
    lastThirdColumn: {
      width: "50%",
    },
  });

  return (
    <Document>
      {/* Cover */}
      <Page size="A4">
        <View style={{ position: "relative" }}>
          <Image style={{}} src={Report1Img} />
          <View
            style={{
              position: "absolute",
              top: "64px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              {reportData?.company_details?.logo_url && (
                <Image
                  src={reportData?.company_details?.logo_url}
                  style={{
                    maxWidth: "150px",
                    height: "auto",
                    marginLeft: "20px",
                  }}
                />
              )}
            </View>
            <View
              style={{
                textAlign: "right",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 6,
                backgroundColor: rgba(primaryColor, 0.7),
                color: contrastColor,
              }}
            >
              <Text style={{ fontSize: "14px", color: "#ffff" }}>
                {reportData?.company_details?.url}
              </Text>
            </View>
          </View>
          <View style={styles.bottomSlantingDivs}></View>
          <View style={styles.reportRibbonStyles}></View>
          <View style={styles.reportSummary}>
            <Text style={{ fontSize: "14px" }}>{reportData?.site_url}</Text>
            <View
              style={{
                borderBottomWidth: 5,
                backgroundColor: "#000133",
                marginLeft: "55%",
                marginVertical: "10px",
              }}
            />
            <Text
              style={{
                fontSize: "32px",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#000133",
              }}
            >
              {reportData?.month} {reportData?.year}
            </Text>
            <View
              style={{
                borderBottomWidth: 5,
                backgroundColor: "#000133",
                marginLeft: "55%",
                marginVertical: "10px",
              }}
            />
            <Text
              style={{
                fontSize: "42px",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: primaryColor,
              }}
            >
              Monthly Report
            </Text>
          </View>
        </View>
      </Page>

      <Page size="A4" style={{ padding: "42px" }}>
        {/* Website Image */}
        <View style={{ maxWidth: "100%" }} wrap={false}>
          <Image src={reportData?.image_url} />
        </View>

        {/* Intro Text Section */}
        <View
          style={{
            backgroundColor: primaryColor,
            color: contrastColor,
            fontSize: "9px",
            paddingHorizontal: "24px",
            paddingVertical: "12px",
          }}
          wrap={false}
        >
          <Text>Beste klant,</Text>
          <Text>{reportData?.intro_text}</Text>
        </View>

        {/* Backups and Updates Overview */}
        <View style={{ marginTop: "24px" }} wrap={false}>
          <View style={styles.tableContainer}>
            <View style={styles.tableHeader}>
              <Text style={styles.firstThirdColumn}>Updates</Text>
              <Text style={styles.secondThirdColumn}>
                {reportData?.data?.update_count}
              </Text>
              <Text style={styles.lastThirdColumn}>
                Aantal updates de afgelopen 30 dagen.
              </Text>
            </View>
            <View style={styles.tableRowEven}>
              <Text style={styles.firstThirdColumn}>Backups</Text>
              <Text style={styles.secondThirdColumn}>
                {reportData?.data?.backup_count}
              </Text>
              <Text style={styles.lastThirdColumn}>
                Backups gemaakt de laatste 30 dagen.
              </Text>
            </View>
          </View>
        </View>

        {/* Updates Section */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "24px",
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <View style={{ flex: 1, maxWidth: "50%" }}>
            <View style={styles.tableContainer}>
              <View style={styles.tableHeader}>
                <Text style={styles.dataColumn}>Software Name</Text>
                <Text style={styles.descriptionColumn}>Version</Text>
              </View>
              {reportData?.data?.updates?.map((update, key) => (
                <View
                  style={
                    key % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd
                  }
                  key={key}
                >
                  <Text style={styles.dataColumn}>{update?.name}</Text>
                  <Text style={styles.descriptionColumn}>
                    {update?.new_version}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          <View style={{ flex: 1, maxHeight: "100%" }}>
            <View
              style={{
                backgroundColor: primaryColor,
                paddingLeft: "42px",
                paddingVertical: "12px",
              }}
            >
              <Text
                style={{
                  textTransform: "uppercase",
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Updates
              </Text>
            </View>
            <View style={{ position: "relative" }}>
              <Image src={Report2Img} style={{ maxHeight: "75%" }} />
              <View
                style={{
                  position: "absolute",
                  backgroundColor: primaryColor + "80",
                  color: "white",
                  maxWidth: "90%",
                  padding: "24px",
                  top: "32px",
                  left: "35%",
                  transform: "translateX(-70%)",
                }}
              >
                <Text style={{ fontSize: "9px" }}>
                  {reportData?.update_text}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* Next page */}

        {/* Uptime Section */}
        <View style={{ display: "flex", flexDirection: "row" }} wrap={false}>
          <View style={{ flex: 1, flexGrow: 1 }}>
            <View
              style={{
                backgroundColor: primaryColor,
                paddingLeft: "42px",
                paddingVertical: "12px",
                marginLeft: "-42px",
              }}
            >
              <Text
                style={{
                  textTransform: "uppercase",
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Uptime
              </Text>
            </View>
            <View style={{ paddingTop: "24px", marginRight: "-120px" }}>
              <Text style={{ fontSize: "9px" }}>{reportData?.uptime_text}</Text>
            </View>
          </View>
          <View style={{ flex: 1, width: "100%" }}>
            <View
              style={{
                marginLeft: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "125px",
                height: "125px",
                backgroundColor: primaryColor + "80",
                borderRadius: "50%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "115px",
                  height: "115px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "105px",
                    height: "105px",
                    backgroundColor: primaryColor,
                    borderRadius: "50%",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "65px",
                      height: "65px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "55px",
                        height: "55px",
                        backgroundColor: primaryColor + "80",
                        borderRadius: "50%",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "45px",
                          height: "45px",
                          backgroundColor: "white",
                          borderRadius: "50%",
                        }}
                      >
                        <Text
                          style={{
                            color: primaryColor,
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {getUptime(reportData?.data?.uptime)}%
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* Backups Section */}
        <View
          style={{ marginTop: "24px", display: "flex", flexDirection: "row" }}
          wrap={false}
        >
          <View style={{ flex: 1 }}>
            <Image src={Report3Img} />
          </View>
          <View style={{ flex: 1 }}>
            <View
              style={{
                backgroundColor: primaryColor,
                marginRight: "-42px",
                paddingLeft: "42px",
                paddingVertical: "12px",
              }}
            >
              <Text
                style={{
                  textTransform: "uppercase",
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Backups
              </Text>
            </View>
            <View style={{ paddingLeft: "12px", paddingVertical: "12px" }}>
              <Text style={{ fontSize: "9px" }}>{reportData?.backup_text}</Text>
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.tableHeader}>
                <Text style={styles.descriptionColumn}>Datum</Text>
                <Text style={styles.dataColumn}>Grootte</Text>
              </View>
              {reportData?.data?.backups?.map((backup, key) => (
                <View
                  style={
                    key % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd
                  }
                  key={key}
                >
                  <Text style={styles.descriptionColumn}>
                    {moment(backup?.creation_date).format("DD/MM/YYYY")}
                  </Text>
                  <Text style={styles.dataColumn}>{backup?.size}MB</Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        {/* Security Section */}
        <View
          style={{ marginTop: "32px", display: "flex", flexDirection: "row" }}
          wrap={false}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                backgroundColor: primaryColor,
                marginLeft: "-42px",
                paddingLeft: "42px",
                paddingVertical: "12px",
              }}
            >
              <Text
                style={{
                  textTransform: "uppercase",
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Security
              </Text>
            </View>
            <View style={{ paddingTop: "24px" }}>
              <View style={styles.tableContainer}>
                <View style={styles.tableRowOdd}>
                  <Text style={styles.descriptionColumn}>Website security</Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.website_security}
                  </Text>
                </View>
                <View style={styles.tableRowEven}>
                  <Text style={styles.descriptionColumn}>Plugin security</Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.plugin_security}
                  </Text>
                </View>
                <View style={styles.tableRowOdd}>
                  <Text style={styles.descriptionColumn}>Theme security</Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.theme_security}
                  </Text>
                </View>
                <View style={styles.tableRowEven}>
                  <Text style={styles.descriptionColumn}>IP Blocked</Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.ip_blocked}
                  </Text>
                </View>
                <View style={styles.tableRowOdd}>
                  <Text style={styles.descriptionColumn}>Antibot fail</Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.antibot_fail}
                  </Text>
                </View>
                <View style={styles.tableRowEven}>
                  <Text style={styles.descriptionColumn}>Login failed</Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.login_failed}
                  </Text>
                </View>
                <View style={styles.tableRowOdd}>
                  <Text style={styles.descriptionColumn}>Spam prevented</Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.spam_prevented}
                  </Text>
                </View>
                <View style={styles.tableRowEven}>
                  <Text style={styles.descriptionColumn}>Firewall blocks</Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.firewall_blocks}
                  </Text>
                </View>
                <View style={styles.tableRowOdd}>
                  <Text style={styles.descriptionColumn}>
                    Offense Triggered
                  </Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.offense_triggered}
                  </Text>
                </View>
                <View style={styles.tableRowEven}>
                  <Text style={styles.descriptionColumn}>
                    Bot detection 404
                  </Text>
                  <Text style={styles.dataColumn}>
                    {reportData?.data?.bot_404}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ flex: 1, backgroundColor: primaryColor }}>
            <Image src={Report4Img} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ReportPDF;
