/**
 *
 * This is main layout container of the app.
 * which will gives you a view with header,sidebar and responsiveness
 *
 * ====only made changes in this component if it's required.====
 *
 */

import { Box, Toolbar } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderComponent } from '../../Components';
import { drawerWidth } from '../../Components/HeaderComponent';
import withAuth from '../../HOC/withAuth';

interface ILayoutProps {}

const LayoutContainer = (props: ILayoutProps) => {
  return (
    <>
      <HeaderComponent />
      <Box
        component="main"
        sx={{
          maxHeight: 'calc(100vh - 100px)',
          overflowY: 'scroll',
          minHeight: 'calc(100vh - 100px)',
          flexGrow: 1,
          p: {
            sm: 0,
            xs: 0,
          },
          width: {
            lg: `calc(100vw - 99px)`,
          },
          height: 'auto',
          marginLeft: { lg: `99px` },
          '& > div': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none',
            borderRadius: 0,
            border: 'none',
            minHeight: 'auto'
          }
        }}
      >
        {/* <Toolbar /> */}
        <Outlet />
      </Box>
    </>
  );
};

export default withAuth(LayoutContainer);
