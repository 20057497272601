// PluginModal.tsx
import React from 'react';
import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import he from 'he';

// Import other necessary components or libraries
import { Box, Button, Fade, Grid, IconButton, InputBase, Modal, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useInstallPluginMutation } from '../../../Features/siteSlice';
import Swal from 'sweetalert2';

interface PluginModalProps {
    open: boolean;
    handleClose: () => void;
    website_url: string;
    // define other props you might need
}

type Plugin = {
    name: string;
    slug: string;
    version: string;
    author: string;
    requires: string;
    tested: string;
    rating: number;
    num_ratings: number;
    active_installs: number;
    downloaded: number;
    last_updated: string;
    icons: {
        '1x': string;
        '2x': string;
        svg: string;
    };
    short_description: string;
}


const InstallPlugin: React.FC<PluginModalProps> = ({ open, handleClose, website_url }) => {
    const [searchResults, setSearchResults] = useState<Plugin[]>([]);
    const [searchInput, setSearchInput] = useState("");

    const [installPlugin, { isLoading: installingPlugin }] = useInstallPluginMutation();

    const fetchPlugins = debounce((searchQuery) => {
        fetch(
            'https://api.wordpress.org/plugins/info/1.2/?action=query_plugins&request[per_page]=20&request[search]=' +
            searchQuery
        )
            .then((response) => response.json())
            .then((response) => {
                setSearchResults(response.plugins);
            });
    }, 500);

    useEffect(() => {
        fetchPlugins(searchInput);
    }, [searchInput]);

    const handleInstallPlugin = (name: string, slug: string) => {
        Swal.fire({
            title: `Install Plugin ${name}?`,
            showCancelButton: true,
            confirmButtonText: 'Install!',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              try {
                await installPlugin({ website_url, slug });
              } catch (error) {
                Swal.fire({
                  icon: 'error',
                  title: `Plugin Install Failed`,
                  text: `Failed to install plugin ${name}. Please try again later.`
                })
              }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async(result) => {
            if (result.isConfirmed) {
              Swal.fire({
                icon: 'success',
                title: `Plugin Install Successful`,
                text: `The plugin ${name} was installed successfully!`
              })
            }
        })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1500,
                    height: 800, // Added a fixed height
                    minWidth: '800px', // Added a min-width
                    minHeight: '600px', // Added a min-height
                    maxWidth: 'calc(100vw - 20px)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '10px',
                    padding: '30px 30px 30px 30px'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                    }}>
                        <Typography variant='h4'>Install new plugin</Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#ffffff',
                                borderRadius: '8px',
                                marginLeft: '10px',
                                marginRight: '10px',
                                border: '1px solid #AFBDFA',
                                '@media (max-width: 600px)': {
                                    display: 'none',
                                },
                            }}>
                                <IconButton
                                    type="button"
                                    aria-label="search"
                                    sx={{
                                        padding: '8px 10px',
                                        backgroundColor: 'transparent',
                                        borderRadius: '0px',
                                        color: '#325AE7',
                                        borderRight: '1px solid #AFBDFA'
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    sx={{
                                        fontSize: '16px',
                                        color: '#525566',
                                        minWidth: '216px',
                                        marginLeft: '20px',
                                    }}
                                    placeholder="Search"
                                    type='search'
                                    inputProps={{ 'aria-label': 'Search' }}
                                    onChange={(event) => setSearchInput(event.target.value)}
                                    value={searchInput}
                                />
                            </Box>
                            <Button onClick={handleClose} sx={{
                                backgroundColor: 'transparent',
                                borderRadius: '7px',
                                border: '1px solid #AFBDFA',
                                boxShadow: 'none',
                                padding: '7px 9px',
                                color: '#325AE7',
                                fontSize: '15px',
                                textTransform: 'inherit',
                                minWidth: 'auto',
                                '&:hover': {
                                    boxShadow: 'none',
                                    color: '#ffffff',
                                    backgroundColor: '#325AE7',
                                },
                            }}>
                                <CloseIcon />
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{
                        maxHeight: 'calc(100vh - 260px)',
                        overflowY: 'scroll',
                        paddingRight: '20px',
                    }}>
                        <Grid container spacing={2}>
                            {searchResults.map((plugin, index) => (

                                <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={index}>
                                    <Box sx={{
                                        borderRadius: '10px',
                                        border: '1px solid #AFBDFA',
                                        padding: '15px',
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <Box sx={{
                                                lineHeight: '0',
                                                width: '100px',
                                                flex: '0 0 100px',
                                                marginRight: '15px',
                                                '& img': {
                                                    width: '100%',
                                                }
                                            }}>
                                                <img src={plugin.icons['1x'] || plugin.icons['2x'] || plugin.icons.svg} />
                                            </Box>
                                            <Box>
                                                <Typography variant='h5' sx={{ marginBottom: '5px' }}>{he.decode(plugin.name)}</Typography>
                                                <Typography sx={{ fontSize: '14px' }}>{plugin.short_description} </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            marginTop: '15px',
                                            borderTop: '1px solid #AFBDFA',
                                            paddingTop: '15px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}>
                                            <Typography variant='h5' sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                '& img': {
                                                    marginLeft: '5px',
                                                    width: '18px',
                                                }
                                            }}>
                                                Version: {plugin.version}
                                            </Typography>

                                            <Button
                                                sx={{
                                                    backgroundColor: '#325AE7',
                                                    borderRadius: '7px',
                                                    border: '1px solid #325AE7',
                                                    boxShadow: 'none',
                                                    padding: '3px 14px',
                                                    marginLeft: '12px',
                                                    fontSize: '14px',
                                                    textTransform: 'inherit',
                                                    whiteSpace: 'nowrap',
                                                    minWidth: '98px',
                                                    minHeight: 'inherit',
                                                    '&:hover': {
                                                        boxShadow: 'none',
                                                        backgroundColor: '#325AE7',
                                                    },
                                                }}
                                                onClick={() => handleInstallPlugin(plugin.name, plugin.slug)}
                                            >
                                                Install plugin
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}

                        </Grid>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default InstallPlugin;
