/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputBase,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import Charticon from "../../../Assets/Images/chart-icon.svg";
import Switch from "@mui/material/Switch";
import Storageicon from "../../../Assets/Images/storage-icon.svg";
import Wordpressicon from "../../../Assets/Images/wordpress-icon.svg";
import Secureicon from "../../../Assets/Images/secure-icon.svg";
import { styled } from "@mui/material/styles";
import OpenUpdatesCheckbox from "../OpenUpdatesCheckbox";
import SecurityCenter from "../SecurityCenter";
import RecentUpdates from "../RecentUpdates";
import { LatestScan } from "../LatestScanTab";
import Info from "../../../Assets/Images/info-icon.svg";

export interface SiteSettings {
  debug: boolean;
  hide_plugin: boolean;
  pause_wordpress_update: boolean;
  maintenance: boolean;
  wp_login_uri: null | string;
  speed: string;
}

export interface SiteSettingsForm {
  debug: boolean;
  hide_plugin: boolean;
  maintenance: boolean;
  wp_login_uri: null | string;
}

export interface RecentUpdate {
  name: string;
  type: string;
  date: string;
  old_version: string;
  new_version: string;
}

export interface OpenUpdate {
  name: string;
  site_base_url: string;
  current_version: string;
  new_version: string;
  error: boolean;
  error_message: string;
  type: string;
}

export type UpdatePluginsHandler = (plugins: string) => Promise<void>;
export type SchedulePluginsUpdateHandler = (
  plugins: string,
  delay: number
) => Promise<void>;
export type SubmitSiteSettingsHandler = (
  site_settings: SiteSettingsForm
) => Promise<void>;

interface PropTypes {
  base_url: string;
  site_settings: SiteSettings;
  recent_updates: RecentUpdate[];
  open_updates: OpenUpdate[];
  isUpdatingSiteSettings: boolean;
  blocked_ips_count: number;
  vulnerability_found: boolean;
  broken_link_count: number;
  avg_load_time: string;
  backups_count: number;
  handleUpdatePlugins: UpdatePluginsHandler;
  handleSchedulePluginsUpdate: SchedulePluginsUpdateHandler;
  handleSubmitQuickAction: SubmitSiteSettingsHandler;
  latest_scan: LatestScan;
  handleButtonClick: (tabIndex: number) => void;
}

const OverviewTab = ({
  base_url,
  site_settings,
  recent_updates,
  open_updates,
  isUpdatingSiteSettings,
  blocked_ips_count,
  vulnerability_found,
  broken_link_count,
  avg_load_time,
  backups_count,
  handleUpdatePlugins,
  handleSchedulePluginsUpdate,
  handleSubmitQuickAction,
  latest_scan,
  handleButtonClick,
}: PropTypes) => {
  const [siteSettingsData, setSiteSettingsData] =
    React.useState<SiteSettingsForm>({
      debug: false,
      hide_plugin: false,
      maintenance: false,
      wp_login_uri: null,
    });

  React.useEffect(() => {
    // Map the relevant properties from site_settings to siteSettingsForm
    const siteSettingsForm: SiteSettingsForm = {
      debug: site_settings.debug,
      hide_plugin: site_settings.hide_plugin,
      maintenance: site_settings.maintenance,
      wp_login_uri: site_settings.wp_login_uri,
    };

    // Set the siteSettingsForm state
    setSiteSettingsData(siteSettingsForm);
  }, [site_settings]);

  const handleFormFieldChange =
    (fieldName: keyof SiteSettingsForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      setSiteSettingsData((prevSettings) => ({
        ...prevSettings,
        [fieldName]: newValue as (typeof prevSettings)[keyof SiteSettingsForm],
      }));
    };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: "40px",
    height: "20px",
    padding: 0,
    display: "flex",
    borderRadius: "100px",
    background: "#BECBFF",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      color: "#14161F",
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#325AE7",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "",
      width: "16px",
      height: "16px",
      borderRadius: "100px",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const checkTypeExists = (targetType: string) => {
    for (const item of open_updates) {
      if (item.type === targetType) {
        return true;
      }
    }

    return false;
  };

  return (
    <Box
      sx={{
        padding: "25px 40px",
        "@media (max-width: 1400px)": {
          padding: "25px 20px",
        },
      }}
    >
      <Grid container spacing={3}>
        <Grid item lg={7} xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "18px",
              marginBottom: "31px",
            }}
          >
            Quick Actions
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={6} xs={12}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #AFBDFA",
                  backgroundColor: "#ffffff",
                  "& div + div": {
                    borderTop: "1px solid #AFBDFA",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "22px 18px",
                  }}
                >
                  <Typography
                    sx={{
                      paddingRight: "15px",
                    }}
                  >
                    Debug
                    <Tooltip title="Enable or Disable WP Debug">
                      <img
                        src={Info}
                        alt="Info"
                        style={{ marginLeft: "5px" }}
                      />
                    </Tooltip>
                  </Typography>
                  <AntSwitch
                    defaultChecked={siteSettingsData?.debug}
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={handleFormFieldChange("debug")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "22px 18px",
                  }}
                >
                  <Typography
                    sx={{
                      paddingRight: "15px",
                    }}
                  >
                    Hide WP Monitoring plugin
                    <Tooltip title="Show or hide our plugin on the website.">
                      <img
                        src={Info}
                        alt="Info"
                        style={{ marginLeft: "5px" }}
                      />
                    </Tooltip>
                  </Typography>
                  <AntSwitch
                    defaultChecked={siteSettingsData?.hide_plugin}
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={handleFormFieldChange("hide_plugin")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "22px 18px",
                  }}
                >
                  <Typography
                    sx={{
                      paddingRight: "15px",
                    }}
                  >
                    Maintenance
                    <Tooltip title="Turn on/off Maintenance mode">
                      <img
                        src={Info}
                        alt="Info"
                        style={{ marginLeft: "5px" }}
                      />
                    </Tooltip>
                  </Typography>
                  <AntSwitch
                    defaultChecked={siteSettingsData?.maintenance}
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={handleFormFieldChange("maintenance")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "22px 18px",
                  }}
                >
                  <Typography
                    sx={{
                      paddingRight: "15px",
                    }}
                  >
                    Change WP login URL
                    <Tooltip title="Change the default WP Login URL">
                      <img
                        src={Info}
                        alt="Info"
                        style={{ marginLeft: "5px" }}
                      />
                    </Tooltip>
                  </Typography>
                  <InputBase
                    sx={{
                      fontSize: "16px",
                      width: "70px",
                      marginLeft: "0",
                      "& input": {
                        padding: "0",
                        color: "#525566",
                        borderBottom: "1px solid #AFBDFA",
                      },
                    }}
                    type="text"
                    value={siteSettingsData?.wp_login_uri}
                    placeholder="Enter Here"
                    inputProps={{ "aria-label": "Search Here" }}
                    onChange={handleFormFieldChange("wp_login_uri")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "22px 18px",
                  }}
                >
                  <Typography
                    sx={{
                      paddingRight: "15px",
                    }}
                  >
                    Do you want to save changes?
                  </Typography>
                  <Button
                    disabled={isUpdatingSiteSettings}
                    sx={{
                      backgroundColor: "#325AE7",
                      borderRadius: "7px",
                      border: "1px solid #325AE7",
                      boxShadow: "none",
                      padding: "3px 14px",
                      marginLeft: "12px",
                      fontSize: "14px",
                      textTransform: "inherit",
                      minHeight: "inherit",
                      "&:hover": {
                        boxShadow: "none",
                        backgroundColor: "#325AE7",
                      },
                    }}
                    onClick={() => handleSubmitQuickAction(siteSettingsData)}
                  >
                    {isUpdatingSiteSettings ? "Loading..." : "Yes, I do"}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #AFBDFA",
                      padding: "18px",
                      minHeight: "169px",
                      maxHeight: "169px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                        "& img": {
                          marginRight: "10px",
                          width: "24px",
                        },
                      }}
                    >
                      <img src={Wordpressicon} />
                      <Typography variant="h6">WordPress</Typography>
                    </Box>
                    <Box>
                      <Typography>
                        ▸ Core{" "}
                        {checkTypeExists("Core")
                          ? "has an update"
                          : "is up-to-date"}{" "}
                      </Typography>
                      <Typography>
                        ▸ Plugins{" "}
                        {checkTypeExists("PLUGIN")
                          ? "have an update"
                          : "are up-to-date"}{" "}
                      </Typography>
                      <Typography>
                        ▸ Theme{" "}
                        {checkTypeExists("Theme")
                          ? "has an update"
                          : "is up-to-date"}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #AFBDFA",
                      padding: "18px",
                      minHeight: "169px",
                      maxHeight: "169px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                        "& img": {
                          marginRight: "10px",
                          width: "24px",
                        },
                      }}
                    >
                      <img src={Secureicon} />
                      <Typography variant="h6">Security</Typography>
                    </Box>
                    <Box>
                      <Typography>
                        ▸{" "}
                        {blocked_ips_count === undefined
                          ? "No recent IPs added to blocklist"
                          : blocked_ips_count === 0
                          ? "No recent IPs added to blocklist"
                          : `${blocked_ips_count} IPs added to blocklist`}
                      </Typography>
                      <Typography>
                        ▸ Scan Completed:{" "}
                        {vulnerability_found
                          ? "Vulnerability found."
                          : "No vulnerability found."}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #AFBDFA",
                      padding: "18px",
                      minHeight: "169px",
                      maxHeight: "169px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                        "& img": {
                          marginRight: "10px",
                          width: "24px",
                        },
                      }}
                    >
                      <img src={Charticon} />
                      <Typography variant="h6">Performance</Typography>
                    </Box>
                    <Box>
                      <Typography>
                        ▸{" "}
                        {Number(avg_load_time) <= 1000
                          ? "Response time looks good."
                          : "Response time can be improved."}
                      </Typography>
                      <Typography>
                        ▸{" "}
                        {broken_link_count === undefined ||
                        broken_link_count === 0
                          ? "No broken URLs found"
                          : `${broken_link_count} bad URLs found`}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #AFBDFA",
                      minHeight: "169px",
                      maxHeight: "169px",
                      padding: "18px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                        "& img": {
                          marginRight: "10px",
                          width: "24px",
                        },
                      }}
                    >
                      <img src={Storageicon} />
                      <Typography variant="h6">Backups</Typography>
                    </Box>
                    <Box>
                      <Typography>
                        ▸ {backups_count} Automatic backups added
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                marginRight: "17px",
              }}
            >
              Open Updates
            </Typography>
          </Box>
          <OpenUpdatesCheckbox
            open_updates={open_updates}
            handleUpdatePlugins={handleUpdatePlugins}
            handleSchedulePluginsUpdate={handleSchedulePluginsUpdate}
          />
        </Grid>
        <Grid item lg={7} xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "18px",
              marginBottom: "31px",
            }}
          >
            Security Center
          </Typography>
          <SecurityCenter
            base_url={base_url}
            latest_scan={latest_scan}
            handleButtonClick={handleButtonClick}
          />
        </Grid>
        <Grid item lg={5} xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                marginRight: "17px",
              }}
            >
              Recent Updates
            </Typography>
          </Box>
          <RecentUpdates recent_updates={recent_updates} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewTab;
