/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";

import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { IUpdateData, SiteData } from "../../../Views/Dashboard";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const StyledButton = styled("button")(
  ({ theme }) => `
  box-sizing: border-box;
  min-width: 144px;
  padding: 5px 12px;
  border-radius: 5px;
  text-align: left;
  line-height: 1.5;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  font-family: Titillium Web;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;


  &.${selectUnstyledClasses.expanded} {
    &::after {
      float: right;
      transform: rotate(180deg);
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  box-sizing: border-box;
  padding: 6px;
  min-width: 200px;
  border-radius: 12px;
  font-family: Titillium Web;
  font-weight: 600;
  overflow: auto;
  outline: 0px;
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #AFBDFA;
  color: #1B1D29;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: #325AE7;
    color: #ffffff;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #325AE7;
    color: #ffffff;
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;
const CustomSelect = React.forwardRef(function CustomSelect<
  TValue extends {},
  Multiple extends boolean
>(
  props: SelectUnstyledProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const slots: SelectUnstyledProps<TValue, Multiple>["slots"] = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled {...props} ref={ref} slots={slots} />;
}) as <TValue extends {}, Multiple extends boolean>(
  props: SelectUnstyledProps<TValue, Multiple> &
    React.RefAttributes<HTMLButtonElement>
) => JSX.Element;
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(90deg)",
    color: "#1B1D29",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-content": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

type HandleParentCheckboxChange = (
  accordionId: string,
  isChecked: boolean
) => void;

type HandleChildCheckboxChange = (
  accordionId: string,
  checkboxIndex: number,
  isChecked: boolean
) => void;

interface PropTypes {
  updates: IUpdateData[];
  accordionCheckboxStates: Record<string, boolean[]>;
  parentCheckboxState: Record<string, boolean>;
  handleParentCheckboxChange: HandleParentCheckboxChange;
  handleChildCheckboxChange: HandleChildCheckboxChange;
  updateType: string;
}

const OpenFailedUpdates = ({
  updates,
  accordionCheckboxStates,
  parentCheckboxState,
  handleParentCheckboxChange,
  handleChildCheckboxChange,
  updateType,
}: PropTypes) => {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{}}>
        <Box
          sx={{
            borderRadius: "10px",
            border: "1px solid #AFBDFA",
            background: "#fff",
            overflow: "hidden",
            maxHeight: "364px",
            minHeight: "364px",
            overflowY: "scroll",
            "& > div + div": {
              borderTop: "1px solid #AFBDFA",
            },
          }}
        >
          {updates?.length > 0 ? (
            updates.map((update, index) => {
              const accordionId = `accordion${index}`;
              const accordionState = accordionCheckboxStates[accordionId] || {};

              const isAllChecked = Object.values(accordionState).every(
                (checkbox) => checkbox
              );
              const isIndeterminate =
                !isAllChecked &&
                Object.values(accordionState).some((checkbox) => checkbox);

              return (
                <Box key={accordionId}>
                  <Accordion
                    expanded={expanded === accordionId}
                    onChange={handleChange(accordionId)}
                    sx={{
                      background: "transparent",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "17px 18px 16px 18px",
                        minHeight: "60px",
                        "@media (max-width: 1540px)": {
                          flexWrap: "wrap",
                        },
                      }}
                    >
                      <Typography variant="h6">
                        {updateType === "openUpdates" && (
                          <Checkbox
                            {...label}
                            sx={{ padding: 0, marginRight: "3px" }}
                            checked={parentCheckboxState[accordionId] || false}
                            onChange={(event) =>
                              handleParentCheckboxChange(
                                accordionId,
                                event.target.checked
                              )
                            }
                            ref={(el) => {
                              if (el) {
                                (el as any).indeterminate = isIndeterminate; // Type assertion
                              }
                            }}
                          />
                        )}
                        {update.name} {`(${update?.sites?.length})`}
                      </Typography>
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        sx={{
                          position: "absolute",
                          right: "18px",
                          padding: "0",
                          top: "24px",
                          minHeight: "auto",
                          "@media (max-width: 1540px)": {
                            top: "23px",
                          },
                        }}
                      ></AccordionSummary>
                    </Box>
                    <AccordionDetails
                      sx={{
                        padding: "0",
                        borderTop: "1px solid #AFBDFA",
                        background: "#F0F1FC",
                      }}
                    >
                      <Box
                        sx={{
                          "& > div + div": {
                            borderTop: "1px solid #AFBDFA",
                          },
                        }}
                      >
                        {update?.sites &&
                          update.sites.map((openUpdate, openUpdateIndex) => (
                            <Box
                              key={openUpdateIndex}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "17px 18px 16px 40px",
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="h6">
                                  {updateType === "openUpdates" && (
                                    <Checkbox
                                      {...label}
                                      sx={{ padding: 0, marginRight: "3px" }}
                                      checked={
                                        accordionState[openUpdateIndex] || false
                                      }
                                      onChange={(event) =>
                                        handleChildCheckboxChange(
                                          accordionId,
                                          openUpdateIndex,
                                          event.target.checked
                                        )
                                      }
                                    />
                                  )}
                                  {openUpdate.name}
                                </Typography>
                                {openUpdate.error &&
                                  openUpdate.error_message && (
                                    <Box sx={{ ml: 1 }}>
                                      <Tooltip title={openUpdate.error_message}>
                                        <ErrorIcon color="error" />
                                      </Tooltip>
                                    </Box>
                                  )}
                              </Box>
                              <Typography>
                                {openUpdate.site_base_url}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "10px",
                                  "& p + p": {
                                    marginLeft: "15px",
                                  },
                                }}
                              >
                                <Typography>
                                  From:{" "}
                                  {openUpdate?.current_version ??
                                    openUpdate?.old_version}
                                </Typography>
                                <Typography>
                                  To: {openUpdate.new_version}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                maxHeight: "362px",
                minHeight: "362px",
                justifyContent: "center",
              }}
            >
              <Typography>No Open Updates Found</Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OpenFailedUpdates;
