// DashboardContentSections.tsx
import React from "react";
import {
  Button,
  Checkbox,
  Grid,
  InputBase,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
// Types for each section's props, replace 'any' with actual data types you expect
export interface AttentionsProps {
  data: any[]; // Define a more specific type based on your actual data structure
}

export interface DowntimeProps {
  data: any[]; // Define a more specific type based on your actual data structure
}

export interface UpdatesProps {
  data: any[]; // Define a more specific type based on your actual data structure
}

export interface BackupsProps {
  data: any[]; // Define a more specific type based on your actual data structure
}

interface BasicLayoutProps {
  messages: any[];
}

const BasicLayout: React.FC<BasicLayoutProps> = ({ messages }) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: "10px",
          border: "1px solid #AFBDFA",
          background: "#fff",
          overflow: "hidden",
          maxHeight: "283px",
          minHeight: "283px",
          overflowY: "scroll",
          "&>div+div": {
            borderTop: "1px solid #AFBDFA",
          },
        }}
      >
        {messages.map((message: any, index: number) => (
          <div key={index}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  width: "60%",
                  padding: "15px",
                }}
              >
                {message.site}
              </Typography>
              <Typography
                sx={{
                  width: "60%",
                  padding: "15px",
                }}
              >
                {message.content}
              </Typography>
              <Typography
                sx={{
                  width: "60%",
                  padding: "15px",
                }}
              >
                {new Date(message.datetime).toLocaleString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Typography>
            </Box>
          </div>
        ))}
      </Box>
    </>
  );
};

// Attentions Content Component
export const AttentionsContent: React.FC<AttentionsProps> = ({ data }) => (
  <BasicLayout
    messages={data.map((item) => ({
      title: "Attention Needed",
      content: item.message,
      site: item.site_base_url,
      datetime: item.date,
    }))}
  />
);

// Downtime Content Component
export const DowntimeContent: React.FC<DowntimeProps> = ({ data }) => (
  <BasicLayout
    messages={data.map((downtime) => ({
      title: "Downtime History",
      content: "Site has been down for longer than 5 minutes",
      site: downtime.site_base_url,
      datetime: downtime.date,
    }))}
  />
);

// Updates Content Component
export const UpdatesContent: React.FC<UpdatesProps> = ({ data }) => (
  <BasicLayout
    messages={data.map((update) => ({
      title: "Updates",
      content: `${update.name} has been updated`,
      site: update.site_base_url,
      datetime: update.date,
    }))}
  />
);

// Backups Content Component
export const BackupsContent: React.FC<BackupsProps> = ({ data }) => (
  <BasicLayout
    messages={data.map((backup) => ({
      title: "Backups",
      content: `Backup made containing ${backup.size}MB`,
      site: backup.site_base_url,
      datetime: backup.creation_date,
    }))}
  />
);

// You can export all the components together as well
export default {
  AttentionsContent,
  DowntimeContent,
  UpdatesContent,
  BackupsContent,
};
