import axios from "axios";
export const baseUrl = process.env.REACT_APP_BASE_URL;

// export const baseUrlDev = 'http://localhost:8000/api/';
// export const baseUrlPro = 'http://localhost:8000/api/';
export const baseUrlDev =
  process.env.REACT_APP_BASE_URL || "https://backend.wpmonitoring.com/api/";
export const baseUrlPro =
  process.env.REACT_APP_BASE_URL || "https://backend.wpmonitoring.com/api/";

export const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/xml",
    "Access-Control-Allow-Origin": "*",
  },
});
