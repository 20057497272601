import React from 'react';
import { BackupInfo, MakeBackupHandler } from '../BackupTab';
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import BackupTabFilter from '../BackupTabFilter';

interface PropTypes {
    backups: BackupInfo[];
	handleMakeBackup: MakeBackupHandler;
	tabType: string;
	website_url: string;
}

const BackupsTabFilter = ({ backups, handleMakeBackup, tabType, website_url }: PropTypes) => {
	const [value1, setValue1] = React.useState('');

    const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue1((event.target as HTMLInputElement).value);
    };

	const filterBackupsForTabs = () => {
		let filteredBackups = [];
		if (tabType === 'all') {
			filteredBackups = backups;
		} else if (tabType === 'manual') {
			filteredBackups = backups.filter(backup => backup.type !== 'NORMAL')
		} else {
			filteredBackups = backups.filter(backup => backup.type === 'NORMAL')
		}

		return filteredBackups;
	}

	const filteredBackups = filterBackupsForTabs();

	return (
		<Grid container spacing={2}>
			<Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
				<Box sx={{
					borderRadius: '10px',
					height: '100%',
					border: '1px solid #AFBDFA',
					background: '#fff',
				}}>
					<Typography
						variant='h6'
						sx={{
							padding: '20px 20px 10px 20px',
						}}
					>
						Create a new backup
					</Typography>
					<FormControl sx={{
						width: '100%',
						'& .MuiFormControlLabel-root': {
							margin: 0,
							padding: '14px 20px',
						},
						'& .MuiFormControlLabel-root + .MuiFormControlLabel-root': {
							borderTop: '1px solid #AFBDFA',
						},
						'& .MuiRadio-root': {
							padding: 0,
							marginRight: '10px',
						},
						'& svg': {
							width: '18px',
							height: '18px',
						},

					}}>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={value1}
							onChange={handleChange1}
						>
							<FormControlLabel value="files" control={<Radio />} label="Files Backup" />
							<FormControlLabel value="database" control={<Radio />} label="Database Backup" />
							{/* <FormControlLabel value="full" control={<Radio />} label="Full Backup" /> */}
						</RadioGroup>
					</FormControl>
					<Box sx={{
						padding: '20px'
					}}>
						<Button 
							sx={{
								backgroundColor: '#325AE7',
								borderRadius: '7px',
								border: '1px solid #325AE7',
								boxShadow: 'none',
								padding: '7px 19px',
								maxWidth: '100%',
								textTransform: 'inherit',
								width: '100%',
								'&:hover': {
									boxShadow: 'none',
									backgroundColor: '#325AE7',
								},
								'& img': {
									marginRight: '10px',
								}
							}}
							onClick={() => handleMakeBackup(value1)}
						>
							Make Backup
						</Button>
					</Box>
				</Box>
			</Grid>
			<Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
				<Box sx={{
					borderRadius: '10px',
					border: '1px solid #AFBDFA',
					background: '#fff',
					'& > div + div': {
						borderTop: '1px solid #AFBDFA',
					}
				}}>
					{
						filteredBackups?.length > 0
						? filteredBackups.map((backup, index) => (
							<BackupTabFilter backup={backup} index={index} website_url={website_url} />
						))
						: <Box sx={{ padding: '16px 18px' }}>
							<Typography variant="h6">No backups to show</Typography>
						</Box>
					}
				</Box>
			</Grid>
		</Grid>
	)
}

export default BackupsTabFilter;