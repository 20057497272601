/**
 *
 * This loader will rotate the image
 * passed here.
 *
 * you can change it as you needed.
 *
 */

import { Box, Button, Grid, InputBase, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import { ISite } from '../../../Views/WebsiteOverview';
import { useGetPackagesQuery } from '../../../Features/settingSlice';
import { useTestSiteConnectionMutation } from '../../../Features/siteSlice';
import Swal from 'sweetalert2';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { ButtonUnstyled } from '@mui/base';


interface PropTypes {
    site: ISite;
}






const SiteConnection = ({ site }: PropTypes) => {





    return (
        <Box sx={{
            padding: '30px 40px',
            '@media (max-width: 1400px)': {
                padding: '25px 20px',
            },
        }}>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '20px',
                        justifyContent: 'space-between',
                        '@media (max-width: 767px)': {
                            display: 'block',
                        },
                    }}>
                        <Typography variant='h6' sx={{
                            fontSize: '18px',
                            marginBottom: '0',
                            '@media (max-width: 767px)': {
                                marginBottom: '10px',
                            },
                        }}>
                            Check your site
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Button
                                disabled
                                sx={{
                                    backgroundColor: '#ffffff',
                                    borderRadius: '7px',
                                    border: '1px solid #AFBDFA',
                                    boxShadow: 'none',
                                    marginRight: '12px',
                                    padding: '7px 19px',
                                    color: '#1B1D29',
                                    textTransform: 'inherit',
                                    '&:hover': {
                                        boxShadow: 'none',
                                        backgroundColor: '#E23A45',
                                    },
                                    '&.Mui-disabled': {
                                        border: '1px solid #707070',
                                        color: '#ffffff',
                                        background: '#707070',
                                    }
                                }}
                            >
                                Reconnect
                            </Button>
                            <Button sx={{
                                backgroundColor: '#325AE7',
                                borderRadius: '7px',
                                border: '1px solid #325AE7',
                                boxShadow: 'none',
                                padding: '7px 19px',
                                textTransform: 'inherit',
                                '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: '#325AE7',
                                },

                            }}
                            >
                                Check connection
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{
                        background: '#ffffff',
                        border: '1px solid #AFBDFA',
                        borderRadius: '15px',
                    }}>
                        <Typography sx={{
                            borderBottom: '1px solid #AFBDFA',
                            padding: '20px',
                        }}>
                            You can check your website either your site is connected with wp monitoring or not. You can run a scan by clicking the button "check connection". It will showcase your website status and if it is not connected please connect your site by clicking the button Reconnect.
                        </Typography>
                        <Typography sx={{
                            padding: '20px',
                            position: 'relative',
                            '&::before':{
                                content: "''",
                                width: '10px',
                                height: '10px',
                                display: 'inline-block',
                                background: '#2680EB',
                                borderRadius: '100px',
                                marginRight: '10px',
                            }
                        }}>
                            You site is connected.
                        </Typography>
                    </Box>
                    <Box sx={{
                        background: '#ffffff',
                        border: '1px solid #AFBDFA',
                        borderRadius: '15px',
                    }}>
                        <Typography sx={{
                            borderBottom: '1px solid #AFBDFA',
                            padding: '20px',
                        }}>
                            You can check your website either your site is connected with wp monitoring or not. You can run a scan by clicking the button "check connection". It will showcase your website status and if it is not connected please connect your site by clicking the button Reconnect.
                        </Typography>
                        <Typography sx={{
                            padding: '20px',
                            position: 'relative',
                            '&::before':{
                                content: "''",
                                width: '10px',
                                height: '10px',
                                display: 'inline-block',
                                background: '#E23A45',
                                borderRadius: '100px',
                                marginRight: '10px',
                            }
                        }}>
                            You site is disconnected, please reconnect your site.
                        </Typography>
                    </Box>
                    <Box sx={{
                        background: '#ffffff',
                        border: '1px solid #AFBDFA',
                        borderRadius: '15px',
                    }}>
                        <Typography sx={{
                            borderBottom: '1px solid #AFBDFA',
                            padding: '20px',
                        }}>
                            You can check your website either your site is connected with wp monitoring or not. You can run a scan by clicking the button "check connection". It will showcase your website status and if it is not connected please connect your site by clicking the button Reconnect.
                        </Typography>
                        <Typography sx={{
                            padding: '20px',
                            position: 'relative',
                            '&::before':{
                                content: "''",
                                width: '10px',
                                height: '10px',
                                display: 'inline-block',
                                background: '#2680EB',
                                borderRadius: '100px',
                                marginRight: '10px',
                            }
                        }}>
                            Please, check your website connection.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SiteConnection;
